import React, { useEffect, useState } from "react";
import Context from "../../Context";

import {
  Container,
  Typo,
  Flex,
  CouponContainer,
  Breadcrumb,
} from "../../components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

export const CouponHistory = () => {
  const { callApi, configs, profileState, go } = Context();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      await callApi("coupons-validateExpireCouponByUserId");
      const result = await callApi("coupons-getTransactionByUserId", {
        isPointRedeem: false,
      });

      setOrders(result.data);
    })();
  }, []);

  return (
    <Container>
      <Breadcrumb
        data={[
          { path: "/", name: "หน้าแรก" },
          { path: "/coupon", name: "คูปอง" },
          { name: "ประวัติการสั่งซื้อ" },
        ]}
      />
      <Typo.H6 color="navy" textAlign="center">
        ประวัติการสั่งซื้อ
      </Typo.H6>

      <div className="flex justify-between my-8">
        <Typo.Link1
          className="flex gap-2"
          color="navy"
          cursor="pointer"
          onClick={() => go("/coupon")}
        >
          <IoIosArrowBack />
          คูปอง
        </Typo.Link1>

        <Typo.Body2 color="textBlack">
          การสั่งซื้อ {orders?.length || 0} ครั้ง
        </Typo.Body2>
      </div>

      {orders
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((order) => {
          return <CouponContainer order={order} product={order.product} />;
        })}
    </Container>
  );
};
