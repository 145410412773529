/* eslint-disable import/no-anonymous-default-export */
export default {
  xxSmall: "0.5em",
  xSmall: "0.75em",
  small: "1em",
  default: "1.25em",
  medium: "1.5em",
  large: "1.75em",
  xLarge: "2em",
  xxLarge: "2.5em",
};
