import { Typo } from "../index";
import { IoIosArrowForward } from "react-icons/io";
import Context from "../../Context";

export const Breadcrumb = ({ data }) => {
  const { go } = Context();
  return (
    <div className="flex align-middle flex-wrap my-4">
      {data.map((page, index) => {
        if (index === data.length - 1) {
          return (
            <Typo.Body6 color="gray5" key={index}>
              {page.name || "-"}{" "}
            </Typo.Body6>
          );
        }
        return (
          <div className="flex" key={index}>
            <Typo.Link1
              onClick={() => go(page.path)}
              color="navy"
              cursor="pointer"
              decoration="none"
            >
              {page.name}
            </Typo.Link1>
            <Typo.Body6 color="textBlack" margin="0 7px">
              <IoIosArrowForward />
            </Typo.Body6>
          </div>
        );
      })}
    </div>
  );
};
