import React, { useRef } from "react";
import styled from "styled-components";
import themes from "../themes";
import { devices } from "../constants";

export const SquareInput = ({
  text,
  digits,
  setDigits,
  index,
  setTs,
  action,
}) => {
  const blockRef = useRef(null);
  const inputRef = useRef(null);
  const isDigit = (inp) => /^\d{1,2}$/.test(inp) && inp != "e";

  const handleInput = (e) => {
    setTs(Date.now());
    const change = e.key;
    if (change === "Enter") {
      return action(digits);
    }
    if (!digits[index] && change === "Backspace" && index > 0) {
      return blockRef.current.previousSibling.childNodes[0].focus();
    } else if (change === "Backspace" && digits[index]) {
      setDigits((old) => {
        old[index] = "";
        return old;
      });
    }
  };
  const handleChange = (e) => {
    const change = e.target.value;
    e.preventDefault();
    if (isDigit(change)) {
      setDigits((old) => {
        if (old[index]) {
          if (change[0] === old[index]) {
            old[index] = change[1];
          } else {
            old[index] = change[0];
          }
        } else {
          old[index] = change;
        }
        if (index === 5) {
          action();
        }
        return old;
      });
      if (index < digits.length - 1) {
        return blockRef.current.nextSibling.childNodes[0].focus();
      }
    } else if (change === "") {
      setDigits((old) => {
        old[index] = " ";
        return old;
      });
    }
  };

  return (
    <>
      <Block ref={blockRef}>
        <Input
          inputmode="numeric"
          type="tel"
          blockRef={blockRef}
          ref={inputRef}
          onChange={() => {}}
          value={digits[index]}
          placeholder={text}
          onKeyUp={handleInput}
          onChangeCapture={handleChange}
        />
      </Block>
    </>
  );
};
const Block = styled.div`
  position: relative;
  flex: 1;
`;
const Input = styled.input`
  font-size: ${({ blockRef }) =>
    (blockRef?.current?.clientWidth || 100) * 0.4}px;
  text-align: center;
  color: ${themes.colors.gray};

  width: 100%;
  max-width: 90px;
  height: 54px;

  border: 3px solid
    ${(props) =>
      props.value === "" ? themes.colors.lightNavy2 : themes.colors.yellow};
  border-radius: 8px;
  font-size: 24px;
  font-weight: 700;

  //ipad size
  @media only screen and (${devices.tablet}) {
    max-width: 76px;
  }
  //iphone size

  @media only screen and (${devices.mobile}) {
    max-width: 42px;
    height: 44px;
  }
`;
