import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import themes from "../themes";
import {
  Wrapper,
  Flex,
  Container,
  Typo,
  Input,
  Button,
  Div,
} from "../components";
import ReCAPTCHA from "react-google-recaptcha";

import { devices, validate } from "../constants";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";

export const Login = () => {
  const recaptchaRef = React.createRef();
  const { callApi, setProfileState, go, profileState } = Context();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { target } = useParams();
  const [token, setToken] = useState("");
  const [loginData, setLoginData] = useState({
    phone: "",
    password: "",
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("yourAction");
    setToken(recaptchaToken);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    if (profileState.userNo) {
      go("/");
    }
  }, [profileState]);
  const handleLogin = async () => {
    const result = await callApi("users-login", {
      username: loginData.phone,
      password: loginData.password,
      reCaptchaToken: token,
      userAgent: window.navigator.userAgent || "",
    });
    if (result.success) {
      localStorage.setItem("accessToken", result.data.accessToken);
      setProfileState(result.data.userData);
      target ? go(target) : go("/");
      window.location.reload();
    }
  };

  const onSubmitWithReCAPTCHA = () => {
    const token = recaptchaRef.current.getValue();
    setToken(token);

    // apply to form data
  };
  return (
    <Container>
      <Div
        className="btn-login-close"
        position="absolute"
        top="5%"
        right="5%"
        onClick={() => go("/")}
        zIndex={1}
      >
        <FiX
          style={{
            fontSize: "40px",
            color: themes.colors.white,
            cursor: "pointer",
          }}
        />
      </Div>

      <LoginWrapper>
        <Absolute>
          <img
            src="/images/bg-hong.svg"
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Absolute>
        <LogoWrapper>
          <img
            src="/images/logodotcom.svg"
            alt="logo"
            style={{ width: "100%" }}
          />
        </LogoWrapper>
        <Wrapper width="100%" maxWidth="500px">
          <Flex direction="column" align="center">
            <Typo.H6 margin="0 0 40px 0" color={"navy"}>
              เข้่าสู่ระบบ
            </Typo.H6>
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Typo.Body6 color={"textBlack"}>เบอร์โทรศัพท์</Typo.Body6>
              <Input
                type="text"
                placeholder="0xx-xxx-xxxx"
                value={loginData.phone}
                onChange={(e) => {
                  if (validate.digitOnly.test(e.target.value)) {
                    setLoginData((prevState) => ({
                      ...prevState,
                      phone: e.target.value,
                    }));
                  }
                }}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Typo.Body6 color={"textBlack"}>รหัสผ่าน</Typo.Body6>
              <Input.Password
                placeholder="กรุณากรอก"
                onChange={(e) =>
                  setLoginData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
              />
            </div>
            <form className="my-8" onSubmit={onSubmitWithReCAPTCHA}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LepvVYqAAAAAOZudU8U6HwAe1h2AFpxtQCx7R0i"
                onChange={() => onSubmitWithReCAPTCHA()}
              />
            </form>
            <Button
              disabled={!loginData.phone || !loginData.password || !token}
              width="100%"
              weight="bold"
              background={"gradient2"}
              onClick={() => handleLogin()}
            >
              เข้่าสู่ระบบ
            </Button>
            <Typo.Body2 margin="8px 0" color={"textBlack"}>
              หรือ
            </Typo.Body2>
            <Button
              width="100%"
              background={"greenLine"}
              weight="bold"
              onClick={() =>
                window.location.replace(
                  "https://www.hongthongofficial.com/api/authen/lineLogin"
                )
              }
            >
              เข้่าสู่ระบบผ่าน LINE
            </Button>
            <Typo.Body2
              color={"navy"}
              hoverColor="yellow"
              margin="20px 0"
              onClick={() => go("/register")}
              cursor="pointer"
            >
              สมัครสมาชิกหงษ์ทอง
            </Typo.Body2>
            <Typo.Body2
              color={"navy"}
              hoverColor="yellow"
              margin="0"
              onClick={() => go("/reset-password")}
              cursor="pointer"
            >
              ลืมรหัสผ่าน
            </Typo.Body2>
          </Flex>
        </Wrapper>
      </LoginWrapper>
    </Container>
  );
};

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  @media only screen and (${devices.tablet}) {
    flex-direction: column;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    flex-direction: column;
  }
`;
export const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 338px;
  height: 92px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    max-width: 130;
    height: 30;
    margin-bottom: 30px;
    &.absolute {
      height: 500px;
      max-width: 300px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  /* //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
    margin-bottom: 0;
  } */
`;
export const Absolute = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  left: 0;
  top: 100px;
  z-index: 0;
  @media only screen and (${devices.tablet}) {
    margin: 0;
    top: 0%;
    width: 100%;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  }
`;
