import React from "react";
import styled from "styled-components";
import Context from "../Context";
import style from "../constants/style";
import themes from "../themes";
export const Btn = (props) => {
  const {
    text,
    handleClick,
    isDisable,
    color,
    bgColor,
    textColor,
    icon,
    customStyle,
    border,
    margin,
    weight,
  } = props;
  return (
    <BtnColor
      {...props}
      className={`${props.className} ${isDisable && `disabled`}`}
      disabled={isDisable}
      style={{ ...customStyle }}
    >
      {icon && icon}
      <b>{text}</b>
    </BtnColor>
  );
};

const Icon = styled.img`
  width: 26px;
  margin-right: 0.5em;
`;
const BtnColor = styled.button`
  cursor: pointer;
  font-size: ${themes.fontSize.default};
  font-weight: ${(props) => props.weight};
  color: ${themes.colors.darkGray};
  padding: 3px 15px;
  border-radius: 0.375rem;
  border: 3px solid ${(props) => props.borderColor || `#ffffff`};
  width: auto;
  max-width: 375px;
  margin: ${(props) => props.margin} !important;
  /* ${style.css.smRad}
  ${style.css.shadowBottom} */
  transition-duration: 0.1s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.bgColor || "#fff"};
  &.disabled {
    background-color: #cccccc;
    color: #666666;
    border: 3px solid #cccccc;
  }

  &.button-style-primary {
    background-color: ${themes.colors.primary};
    border: 3px solid ${themes.colors.primary};
    color: ${themes.colors.black};
    font-weight: 300;
  }
  &.button-style-primary-gradient {
    background: ${themes.colors.primaryGradient};
    border: none;
    color: ${themes.colors.black};
    font-weight: 300;
  }
  &.button-style-gray {
    background: ${themes.colors.darkGray};
    border: none;
    color: ${themes.colors.white};
    font-weight: 300;
  }
  &.button-style-primary-inline {
    background-color: ${themes.colors.white};
    border: 3px solid ${themes.colors.primary};
    color: ${themes.colors.primary};
    font-weight: 300;
  }
  &.button-style-secondary {
    background: ${themes.colors.secondary};
    border: 3px solid ${themes.colors.secondary};
    color: ${themes.colors.white};
    font-weight: 500;
  }
  &.button-style-danger {
    background-color: ${themes.colors.dangerRed};
    border: 3px solid ${themes.colors.dangerRed};
    color: ${themes.colors.white};
    font-weight: 300;
  }
  &.button-style-danger-inline {
    background-color: ${themes.colors.white};
    border: 3px solid ${themes.colors.dangerRed};
    color: ${themes.colors.dangerRed};
    font-weight: 300;
  }
  &.button-style-oldGrean {
    background-color: ${themes.colors.oldGrean};
    border: 3px solid ${themes.colors.oldGrean};
    color: ${themes.colors.white};
    font-weight: 300;
  }
  &.button-style-orange {
    background-color: rgb(260, 126, 5) !important;
    border: 3px solid rgb(260, 126, 5);
    color: ${themes.colors.black};
  }
  &.button-style-orange-inline {
    background-color: white;
    border: 3px solid rgb(260, 126, 5);
    color: rgb(260, 126, 5);
  }
  &.button-style-oldGrean-inline {
    background-color: ${themes.colors.white};
    border: 3px solid ${themes.colors.oldGrean};
    color: ${themes.colors.oldGrean};
    font-weight: 300;
  }
  &.button-style-magic-number {
    background-color: ${themes.colors.primary};
    border: 3px solid ${themes.colors.primary};
    color: ${themes.colors.black};
  }
  &.button-style-magic-number-inline {
    background-color: white;
    border: 3px solid ${themes.colors.primary};
    color: ${themes.colors.primary};
  }
  &.small {
    font-size: ${themes.fontSize.small};
    padding: 5px 10px;
    width: auto;
  }

  &.active {
    color: ${themes.colors.secondary};
    background-color: ${themes.colors.white};
    border: 3px solid ${themes.colors.secondary};
  }

  :active {
    box-shadow: unset;
  }
  :hover {
    border: 3px solid ${themes.colors.primary};
    color: ${themes.colors.primary};
    &.button-style-primary {
      background-color: ${themes.colors.white};
      border: 3px solid ${themes.colors.primary};
      color: ${themes.colors.primary};
    }
    &.button-style-primary-gradient {
      color: ${themes.colors.white};
      border: none;
    }
    &.button-style-magic-number {
      background-color: ${themes.colors.white};
      border: 3px solid ${themes.colors.primary};
      color: ${themes.colors.black};
    }
    &.button-style-gray {
      color: ${themes.colors.gray};
      border: none;
    }
    &.button-style-primary-inline {
      background-color: ${themes.colors.primary};
      border: 3px solid ${themes.colors.primary};
      color: ${themes.colors.black};
    }
    &.button-style-orange {
      background-color: white !important;
      border: 3px solid rgb(260, 126, 5);
      color: rgb(260, 126, 5);
    }
    &.button-style-orange-inline {
      background-color: rgb(260, 126, 5);
      border: 3px solid rgb(260, 126, 5);
      color: black;
    }

    &.button-style-danger-inline {
      background-color: ${themes.colors.dangerRed};
      border: 3px solid ${themes.colors.dangerRed};
      color: ${themes.colors.white};
      font-weight: 300;
    }
    &.disabled {
      cursor: no-drop;
      border: 3px solid #666666;
      color: #666666;
    }
  }
  /* margin: 2vw 2vw 0 2vw; */
  /* color: ${({ textColor }) => textColor || `white`};
  ${({ color }) => (color ? `background: ${color};` : style.css.blueGradian)}
  ${({ bgColor }) => bgColor && bgColor}
  ${({ border }) => border && border} */
`;

export const LineButton = (props) => {
  const { text, userId, appId, shouldCenter = true } = props;
  const { hideModal, callApi, db, loginOldUser, showModalLoading } = Context();
  return (
    <BtnColor
      onClick={async () => {
        // if (userId) {
        //   window.open(
        //     `https://payment.vronlineinnovation.com/api/loginline/userid-${userId}/HONGDEV3`
        //   );
        //   showModalLoading();
        //   const unsubscribe = onSnapshot(doc(db, "Users", userId), (doc) => {
        //     if (doc.get("lineId")) {
        //       loginOldUser().then(() => {
        //         unsubscribe();
        //         hideModal();
        //       });
        //     }
        //   });
        // } else {
        //   const { accesstoken } = await callApi("users-createAccessToken");
        //   window.open(
        //     `https://payment.vronlineinnovation.com/api/loginline/accesstoken-${accesstoken}/HONGDEV3`
        //   );
        //   showModalLoading();
        //   const unsubscribe = onSnapshot(
        //     doc(db, "AccessTokens", accesstoken),
        //     (doc) => {
        //       if (doc.get("user") !== null) {
        //         localStorage.setItem("accessToken", accesstoken);
        //         loginOldUser().then(() => {
        //           unsubscribe();
        //           hideModal();
        //         });
        //       }
        //     }
        //   );
        // }
        window.location.replace(
          userId
            ? `https://www.hongthongofficial.com/api/authen/lineLogin/${userId}/${appId}`
            : "https://www.hongthongofficial.com/api/authen/lineLogin"
        );
      }}
      {...props}
      style={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        width: "100%",
        padding: "0",
        margin: "0",
        border: "none",
        borderRadius: "10px",
        background: "#06C755",
        color: "white",
      }}
    >
      <img
        src={"/images/line_44.png"}
        style={{
          position: "absolute",
          left: 0,
          padding: "3px",
          borderRight: "1px solid rgba(0,0,0,.1)",
        }}
        alt="line"
      />
      {/* fake div just to make it balance */}
      <img
        src={"/images/line_44.png"}
        style={{
          padding: "3px",
          borderRight: "1px solid rgba(0,0,0,.1)",
          opacity: 0,
        }}
        alt="line"
      />
      <b style={{ flex: 1 }}>{text}</b>
      {/* fake div just to make it balance */}
      {shouldCenter && (
        <img
          src={"/images/line_44.png"}
          style={{
            padding: "3px",
            borderRight: "1px solid rgba(0,0,0,.1)",
            opacity: 0,
          }}
          alt="line"
        />
      )}
    </BtnColor>
  );
};
