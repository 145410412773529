import React, { useEffect, useCallback, useState } from "react";
import Context from "../Context";
import { Collapse } from "reactstrap";
import styled from "styled-components";
import {
  Btn,
  InputBox,
  Flex,
  Button,
  Typo,
  Div,
  Container,
  Wrapper,
  Input,
} from "../components";
import style from "../constants/style";
import { FiX } from "react-icons/fi";
import { randomLetters, CountDown } from "../util";
import { LoginWrapper, Absolute, LogoWrapper } from "./Login";
import themes from "../themes";

import ReCAPTCHA from "react-google-recaptcha";

export const ResetPassword = () => {
  const { callApi, toast, go } = Context();

  const recaptchaRef = React.createRef();
  const [phone, setPhone] = useState("");
  const [expireIn, setExpireIn] = useState(0);
  const [otpRef, setOtpRef] = useState("");
  const [isShowPass, setIsShowPass] = useState(false);
  const [token, setToken] = useState();
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    const countDown = () =>
      setTimeout(() => {
        setExpireIn((x) => {
          if (x >= 1000) {
            return x - 1000;
          }
        });
        countDown();
      }, 1000);
    countDown();
    return () => clearTimeout(countDown);
  }, []);

  const submit = async () => {
    const otpCode = digits.join("");
    if (validate() && digits.length === otpCode.length) {
      await callApi("users-resetPassword", {
        otpCode,
        phone,
        otpRef,
        password,
        token,
      }).then((response) => {
        if (response.status === false) {
          toast("รหัสผิดพลาดโปรดลองใหม่อีกครั้ง");
          return;
        }
        toast("ท่านได้ทำการเปลี่ยนรหัสเรียบร้อยแล้ว", { icon: "success" });
        return go("/login");
      });
    } else {
      toast("โปรดใส่รหัสให้ครบถ้วน");
    }
  };

  const validate = () => {
    if (!phone || phone.length !== 10) {
      toast("โปรดระบุเบอร์โทรให้ถูกต้อง");
    } else if (!password) {
      toast("โปรดตั้งรหัสผ่าน");
    } else if (!confirm) {
      toast("โปรดยืนยันรหัสผ่าน");
    } else if (password !== confirm) {
      toast("โปรดตั้งรหัสให้ตรงกัน");
    } else {
      return true;
    }
    return false;
  };

  const CountDown = ({ second = 0 }) => {
    if (second <= 0) return <span>00:00</span>;
    second = parseInt(second / 1000);
    const pattern =
      String(Math.floor(second / 60)).padStart(2, "0") +
      ":" +
      String(second % 60).padStart(2, "0");
    return <span>{pattern}</span>;
  };

  const requestResetPassword = async () => {
    if (token) {
      const tempOtpRef = randomLetters(5);
      const result = await callApi("users-requestResetPassword", {
        otpRef: tempOtpRef,
        phone,
        token,
      });

      if (result.status) {
        setExpireIn(result.expireAt - new Date());
        setOtpRef(tempOtpRef);
      } else {
        toast(result.message || "โปรดลองใหม่อีกครั้งภายหลัง");
      }
    }
  };

  const onSubmitWithReCAPTCHA = () => {
    const token = recaptchaRef.current.getValue();
    setToken(token);
  };

  return (
    <Container>
      <Div
        className="btn-login-close"
        position="absolute"
        top="5%"
        right="5%"
        zIndex={1}
      >
        <FiX
          style={{
            fontSize: "40px",
            color: themes.colors.white,
            cursor: "pointer",
          }}
        />
      </Div>

      <LoginWrapper>
        <Absolute>
          <img
            src="/images/bg-hong.svg"
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Absolute>
        <LogoWrapper>
          <img
            src="/images/logodotcom.svg"
            alt="logo"
            style={{ width: "100%" }}
          />
        </LogoWrapper>
        <Wrapper width="100%" maxWidth="500px">
          <Flex direction="column" align="center">
            {!expireIn ? (
              <>
                <Typo.H6 margin="0 0 15px 0" color={"navy"}>
                  ลืมรหัสผ่าน
                </Typo.H6>
                <Typo.H6 margin="0 0 25px 0" color={"navy"}>
                  กรอกเบอร์โทรศัพท์ เพื่อกู้คืนรหัสผ่าน
                </Typo.H6>
                <Input
                  type={"text"}
                  placeholder={"เบอร์โทรศัพท์"}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <form className="my-8" onSubmit={onSubmitWithReCAPTCHA}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LepvVYqAAAAAOZudU8U6HwAe1h2AFpxtQCx7R0i"
                    onChange={() => onSubmitWithReCAPTCHA()}
                  />
                </form>
                <Button
                  disabled={phone.length < 9 || !token}
                  onClick={() => requestResetPassword()}
                  background="yellow"
                  hoverBg="transparent"
                  hoverColor="yellow"
                  hoverBorder="yellow"
                  width="100%"
                >
                  กู้คืนรหัสผ่าน
                </Button>
              </>
            ) : (
              <>
                <Typo.Body2 textAlign="center" color="navy">
                  กรุณากรอกรหัสยืนยันของท่าน
                </Typo.Body2>
                <Typo.Body2 textAlign="center" color="navy" margin="5px 0">
                  รหัสอ้างอิง {otpRef}
                </Typo.Body2>
                <Typo.Body2
                  textAlign="center"
                  color="navy"
                  margin="0 0  40px 0"
                >
                  หมดอายุภายใน{" "}
                  <CountDown second={expireIn} zeroRefresh={true} />
                </Typo.Body2>
                <InputBox
                  digits={digits}
                  setDigits={setDigits}
                  gap="15px"
                  action={() => setIsShowPass(true)}
                />
                <Collapse isOpen={isShowPass} style={{ width: "100%" }}>
                  <Input
                    type={"text"}
                    placeholder={"รหัสผ่านใหม่"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    margin="35px 0 0 0"
                  />
                  <Input
                    type={"text"}
                    placeholder={"ยืนยันรหัสผ่านใหม่"}
                    value={confirm}
                    onChange={(e) => {
                      setConfirm(e.target.value);
                    }}
                    margin="15px 0 0 0"
                  />
                  <Button
                    disabled={confirm !== password || confirm === ""}
                    onClick={() => submit()}
                    margin="25px 0 0 0"
                    background="yellow"
                    hoverBg="transparent"
                    hoverColor="yellow"
                    hoverBorder="yellow"
                    width="100%"
                  >
                    กู้คืนรหัสผ่าน
                  </Button>
                </Collapse>
              </>
            )}
          </Flex>
        </Wrapper>
      </LoginWrapper>
    </Container>
  );
};
