/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import style from "../constants/style";
import Context from "../Context";

export const AddressInputs = ({
  addressList: addressListInput,
  callBack,
  handleSave,
  initString,
  orderId,
}) => {
  const NEW_ADDRESS = "เพิ่มที่อยู่ใหม่";
  const { callApi, go } = Context();
  const [addresses, setAddresses] = useState([]);
  const [showAddress, setShowAddress] = useState("");
  const [showAddressOptions, setShowAddressOptions] = useState([NEW_ADDRESS]);
  const [currentString, setCurrentString] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressList, setAddressList] = useState();
  const [provinceList, setProvinceList] = useState([
    { key: "", ts: +new Date() },
  ]);
  const [districtList, setDistrictList] = useState(["โปรดเลือกจังหวัดก่อน"]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [isDefaultAddress, setIsDefaultAddress] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState({});
  const [currentZipcode, setCurrentZipcode] = useState("");
  const [currentProvince, setCurrentProvince] = useState({});
  const [currentDistrict, setCurrentDistrict] = useState("");
  const [currentSubDistrict, setCurrentSubDistrict] = useState("");
  const [currentNumber, setCurrentNumber] = useState("");

  const changeAddress = (addressList, key) => {
    if (key === NEW_ADDRESS) {
      setDefaultAddress({});
      setCurrentZipcode("");
      setCurrentNumber("");
      setFirstName("");
      setLastName("");
      setIsDefaultAddress(!Object.keys(addressList).length);
      setCurrentString("");
    } else {
      const tempAddress =
        (key
          ? addressList[key]
          : Object.values(addressList).filter((x) => x.isDefault)?.[0]) || {};
      const {
        number,
        firstName,
        lastName,
        zipcode,
        province,
        district,
        subDistrict,
        string,
        isDefault,
      } = tempAddress;
      setDefaultAddress({
        number,
        firstName,
        lastName,
        zipcode,
        province,
        district,
        subDistrict,
      });
      if (zipcode) {
        setCurrentZipcode(zipcode);
      } else if (province) {
        setCurrentProvince(province);
      } else if (district) {
        setCurrentDistrict(district);
      } else if (subDistrict) {
        setCurrentSubDistrict(subDistrict);
      }
      number && setCurrentNumber(number);
      firstName && setFirstName(firstName);
      lastName && setLastName(lastName);
      setIsDefaultAddress(!!isDefault);
      setCurrentString(string);
    }
  };
  const setInitState = (usingAddressList, usingKey) => {
    const addressShowOptions = [
      NEW_ADDRESS,
      ...Object.values(usingAddressList)
        .sort((a, b) => a.updateAt < b.updateAt)
        .map((x) => x.string),
    ];
    const tempShowAddress =
      Object.values(usingAddressList)
        .filter((x) => x.isDefault)
        .map((x) => x.string)[0] || addressShowOptions[0];
    setShowAddressOptions(addressShowOptions);
    setShowAddress(usingKey || tempShowAddress);
  };
  useEffect(() => {
    showAddress && changeAddress(addressList || addressListInput, showAddress);
  }, [showAddress]);
  useEffect(() => {
    setAddressList(addressListInput);
    setInitState(addressListInput, initString);
  }, []);

  useEffect(() => {
    (async () => {
      if (currentZipcode.length === 5) {
        const result = (await callApi("addresses?zipcode=" + currentZipcode))
          .data;
        setAddresses(result);
        const temp = [...new Set(result.map((x) => x.province))];
        const tempProvicneList = temp.map((x) => {
          return { key: x, ts: +new Date() };
        });

        if (tempProvicneList.length === 1) {
          setCurrentProvince(tempProvicneList[0]);
        } else {
          if (tempProvicneList.some((x) => x.key === defaultAddress.province)) {
            setCurrentProvince(defaultAddress.province);
          } else {
            setCurrentProvince("");
            setCurrentDistrict("");
            setCurrentSubDistrict("");
          }
        }
        setProvinceList([{ key: "", ts: +new Date() }, ...tempProvicneList]);
      }
      if (!currentZipcode) {
        setCurrentProvince("");
      }
    })();
  }, [currentZipcode]);
  useEffect(() => {
    if (!currentProvince.key) {
      setCurrentDistrict("");
      setDistrictList([""]);
      return;
    }
    const tempDistrictList = [
      ...new Set(
        addresses
          .filter((x) => x.province === currentProvince.key)
          .map((x) => x.district)
      ),
    ];
    setDistrictList(["", ...tempDistrictList]);
    if (tempDistrictList.length === 1) {
      setCurrentDistrict(tempDistrictList[0]);
    } else {
      if (tempDistrictList.includes(defaultAddress.district)) {
        setCurrentDistrict(defaultAddress.district);
      } else {
        setCurrentDistrict("");
        setCurrentSubDistrict("");
      }
    }
    // eslint-disable-next-line
  }, [currentProvince]);
  useEffect(() => {
    if (!currentDistrict) {
      setCurrentSubDistrict("");
      setSubDistrictList([""]);
      return;
    }
    const tempSubDistricList = [
      ...new Set(
        addresses
          .filter((x) => x.district === currentDistrict)
          .map((x) => x.subDistrict)
      ),
    ];
    setSubDistrictList(["", ...tempSubDistricList]);
    if (tempSubDistricList.length === 1) {
      setCurrentSubDistrict(tempSubDistricList[0]);
    } else {
      if (tempSubDistricList.includes(defaultAddress.subDistrict)) {
        setCurrentSubDistrict(defaultAddress.subDistrict);
      } else {
        setCurrentSubDistrict("");
      }
    }
  }, [currentDistrict]);
  return (
    <>
      <InputWarperCss>
        <TitleCss>รายการที่อยู่</TitleCss>
        <select
          style={inputCss}
          name={"showAddress"}
          id={"showAddress"}
          onChange={(e) => setShowAddress(e.target.value)}
          value={showAddress}
        >
          {showAddressOptions.map((x) => (
            <option style={optionCss} key={x} value={x}>
              {x.slice(0, 40) + "..."}
            </option>
          ))}
        </select>
      </InputWarperCss>
      <InputWarperCss style={{ justifyContent: "flex-end" }}>
        <span>ใช้เป็นที่อยู่หลัก</span>
        <input
          type="checkbox"
          checked={isDefaultAddress}
          readOnly
          onClick={() => setIsDefaultAddress(true)}
        />
      </InputWarperCss>
      <InputWarperCss>
        <TitleCss>ชื่อจริง</TitleCss>
        <input
          style={inputCss}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </InputWarperCss>
      <InputWarperCss>
        <TitleCss>นามสกุล</TitleCss>
        <input
          style={inputCss}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </InputWarperCss>
      <InputWarperCss>
        <TitleCss>รหัสไปรษณีย์</TitleCss>
        <input
          style={inputCss}
          maxLength="5"
          onChange={(e) => {
            setCurrentZipcode(e.target.value);
          }}
          value={currentZipcode}
        />
      </InputWarperCss>
      <InputWarperCss>
        <TitleCss>จังหวัด</TitleCss>
        <select
          style={inputCss}
          name={"province"}
          id={"province"}
          onChange={(e) => {
            const temp = provinceList.filter(
              (x) => x.key === e.target.value
            )[0];
            setCurrentProvince(temp);
            // setCurrentProvince(e.target.value);
          }}
          value={currentProvince.key}
        >
          {provinceList.map((x) => (
            <option key={x.key}>{x.key}</option>
          ))}
        </select>
      </InputWarperCss>
      <InputWarperCss>
        <TitleCss>อำเภอ/เขต</TitleCss>
        <select
          style={inputCss}
          name={"district"}
          id={"district"}
          onChange={(e) => {
            setCurrentDistrict(e.target.value);
          }}
          value={currentDistrict}
        >
          {districtList.map((x, i) => (
            <option key={x}>{x}</option>
          ))}
        </select>
      </InputWarperCss>
      <InputWarperCss>
        <TitleCss>แขวง/ตำบล</TitleCss>
        <select
          style={inputCss}
          name={"subDistrict"}
          id={"subDistrict"}
          onChange={(e) => setCurrentSubDistrict(e.target.value)}
          value={currentSubDistrict}
        >
          {subDistrictList.map((x, i) => (
            <option key={x}>{x}</option>
          ))}
        </select>
      </InputWarperCss>
      <InputWarperCss>
        <TitleCss>เลขที่ หมู่บ้าน หมู่ ถนน</TitleCss>
        <textarea
          rows="2"
          style={inputCss}
          value={currentNumber}
          onChange={(e) => setCurrentNumber(e.target.value)}
        />
      </InputWarperCss>
      {callBack && (
        <InputWarperCss>
          <button
            style={{
              ...inputCss,
              backgroundColor: style.colors.success,
              color: "white",
            }}
            onClick={async () => {
              const newString = `${currentNumber} ${currentSubDistrict} ${currentDistrict} ${currentProvince.key} ${currentZipcode} ${firstName} ${lastName}`;
              const response = await handleSave(
                {
                  isDefault: isDefaultAddress,
                  oldString: currentString,
                  currentString: newString,
                  firstName,
                  lastName,
                  currentZipcode,
                  currentProvince: currentProvince.key,
                  currentDistrict,
                  currentSubDistrict,
                  currentNumber,
                  orderId,
                },
                "orders"
              );
              callBack(response);
            }}
          >
            บันทึกและใช้ที่อยู่นี้
          </button>
        </InputWarperCss>
      )}
      <InputWarperCss>
        <button
          disabled={
            !firstName ||
            !lastName ||
            !currentZipcode ||
            !currentProvince.key ||
            !currentDistrict ||
            !currentSubDistrict ||
            !currentNumber
          }
          style={inputCss}
          onClick={async () => {
            const newString = `${currentNumber} ${currentSubDistrict} ${currentDistrict} ${currentProvince.key} ${currentZipcode} ${firstName} ${lastName}`;
            const response = await handleSave({
              isDefault: isDefaultAddress,
              oldString: currentString,
              currentString: newString,
              firstName,
              lastName,
              currentZipcode,
              currentProvince: currentProvince.key,
              currentDistrict,
              currentSubDistrict,
              currentNumber,
            });
            setAddressList(response.addressList);
            setInitState(response.addressList, newString);
          }}
        >
          บันทึก
        </button>
      </InputWarperCss>
      {!isDefaultAddress && currentString !== NEW_ADDRESS && currentString && (
        <InputWarperCss>
          <button
            style={{
              ...inputCss,
              backgroundColor: style.colors.danger,
              color: "white",
            }}
            onClick={async () => {
              const response = await callApi("users/deleteAddress", {
                currentString,
              });
              setAddressList(response.data.addressList);
              setInitState(response.data.addressList);
            }}
          >
            ลบที่อยู่สำรอง
          </button>
        </InputWarperCss>
      )}
    </>
  );
};

const inputCss = {
  display: "inline-block",
  margin: "15px 0",
  padding: "10px",
  width: "100%",
  fontSize: "1.2rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
// whiteSpace: "nowrap",
const optionCss = {
  width: "100px",
  appearance: "none",
  overflowWrap: "break-word",
  whiteSpace: "pre-line",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const TitleCss = styled.div`
  position: absolute;
  background-color: white;
  left: 7px;
  top: 0.2rem;
  font-size: 1rem;
  padding: 0px 3px 0px 3px;
  color: DarkGray;
`;
const InputWarperCss = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
`;
