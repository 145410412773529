import React from "react";
import styled from "styled-components";
import themes from "../themes";
import { Typo } from "./index";
import { IoReceiptOutline } from "react-icons/io5";
import { BiUser } from "react-icons/bi";
import { FaRegCreditCard } from "react-icons/fa";
import { MdOutlineDone } from "react-icons/md";

const CIRCLE_R = 50;
const SPACE = 0.8;

export const ProgressBar = ({ status }) => {
  if (!status) return;

  return (
    <Wrapper>
      <Block>
        <Circle
          isCompleted={
            status === 1
              ? themes.colors.yellow
              : status > 1
              ? themes.colors.navy
              : themes.colors.white
          }
          border={
            status === 1
              ? themes.colors.yellow
              : status > 1
              ? themes.colors.navy
              : themes.colors.gray3
          }
        >
          <IoReceiptOutline
            style={{
              color: status >= 1 ? themes.colors.white : themes.colors.gray3,
              fontSize: "1.5em",
            }}
          />
        </Circle>

        <Typo.Body5
          margin="5px 0 0 0"
          textAlign="center"
          color={status >= 1 ? "textBlack" : "gray3"}
          style={{ overflow: "overlay" }}
        >
          คำสั่งซื้อ
        </Typo.Body5>
      </Block>
      <Dash
        isCompleted={
          status === 1
            ? themes.colors.yellow
            : status > 1
            ? themes.colors.navy
            : themes.colors.gray3
        }
      />
      <Dash
        isCompleted={status > 1 ? themes.colors.navy : themes.colors.gray3}
      />
      {/* /////////////////////2/////////////////// */}

      <Block>
        <Circle
          isCompleted={
            status === 2
              ? themes.colors.yellow
              : status > 2
              ? themes.colors.navy
              : themes.colors.white
          }
          border={
            status === 2
              ? themes.colors.yellow
              : status > 2
              ? themes.colors.navy
              : themes.colors.gray3
          }
        >
          <BiUser
            style={{
              color: status >= 2 ? themes.colors.white : themes.colors.gray3,
              fontSize: "1.5em",
            }}
          />
        </Circle>
        <Typo.Body5
          margin="5px 0 0 0"
          textAlign="center"
          color={status >= 2 ? "textBlack" : "gray3"}
          style={{ overflow: "overlay" }}
          wrap="nowrap"
        >
          ข้อมูลผู้ซื้อ
        </Typo.Body5>
      </Block>
      <Dash
        isCompleted={
          status === 2
            ? themes.colors.yellow
            : status > 2
            ? themes.colors.navy
            : themes.colors.gray3
        }
      />
      <Dash
        isCompleted={status > 2 ? themes.colors.navy : themes.colors.gray3}
      />
      {/* /////////////////////3/////////////////// */}

      <Block>
        <Circle
          isCompleted={
            status === 3
              ? themes.colors.yellow
              : status > 3
              ? themes.colors.navy
              : themes.colors.white
          }
          border={
            status === 3
              ? themes.colors.yellow
              : status > 3
              ? themes.colors.navy
              : themes.colors.gray3
          }
        >
          <FaRegCreditCard
            style={{
              color: status >= 3 ? themes.colors.white : themes.colors.gray3,
              fontSize: "1.5em",
            }}
          />
        </Circle>
        <Typo.Body5
          margin="5px 0 0 0"
          textAlign="center"
          color={status >= 3 ? "textBlack" : "gray3"}
          style={{ overflow: "overlay" }}
        >
          ชำระเงิน
        </Typo.Body5>
      </Block>
      <Dash
        isCompleted={
          status === 3
            ? themes.colors.yellow
            : status > 3
            ? themes.colors.navy
            : themes.colors.gray3
        }
      />
      <Dash
        isCompleted={status > 3 ? themes.colors.navy : themes.colors.gray3}
      />
      {/* /////////////////////4/////////////////// */}

      <Block>
        <Circle
          isCompleted={status === 4 ? themes.colors.navy : themes.colors.white}
          border={status === 4 ? themes.colors.navy : themes.colors.gray3}
        >
          <MdOutlineDone
            style={{
              color: status >= 4 ? themes.colors.white : themes.colors.gray3,
              fontSize: "1.5em",
            }}
          />
        </Circle>
        <Typo.Body5
          margin="5px 0 0 0"
          textAlign="center"
          color={status >= 4 ? "textBlack" : "gray3"}
          style={{ overflow: "overlay" }}
        >
          สำเร็จ
        </Typo.Body5>
      </Block>
    </Wrapper>
  );
};

const Dash = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 3px;
  margin-top: -20px;
  width: ${CIRCLE_R * SPACE}px;
  border-bottom: 2px solid ${(props) => props.isCompleted};
`;
const Wrapper = styled.div`
  height: ${CIRCLE_R * 2.5}px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  padding: 24px 10px;
  box-shadow: 0px 4px 12px 0px rgba(0, 10, 27, 0.07);
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  position: relative;
  width: ${CIRCLE_R}px;
  height: ${CIRCLE_R}px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  background-color: white;
  background-color: ${({ isCompleted }) => isCompleted};
  border: 3px solid ${(props) => props.border};
  margin-bottom: ${CIRCLE_R * 0.05}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
