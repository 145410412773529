import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const Banner = () => {
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {}, []);
  return (
    <Holder>
      <Main
        onClick={() =>
          window.open(
            "https://www.facebook.com/Hongthonglotterysocial",
            "_blank"
          )
        }
      >
        {/* <Image src={`/images/banner.jpg`} /> */}
        <Img src={`/images/banner.png`} alt="banner" />
      </Main>
    </Holder>
  );
};

const Holder = styled.div`
  width: 100%;
  margin: 10px auto 0 auto;
  box-shadow: 0px 4px 16px 0px rgba(0, 10, 27, 0.1);
`;
const Main = styled.div`
  width: 100%;
  display: block;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  @media only screen and (max-width: 543px) {
    max-width: 100%;
  }
`;
const Img = styled.img`
  width: 100%;

  background-position: center;
  background-image: url("${(props) => props.src}");
  background-size: contain;
`;
