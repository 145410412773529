import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import style from "../constants/style";
import Context from "../Context";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

import { Button, Wrapper, Container, Typo, Flex } from "../components";

import { LoginWrapper, Absolute, LogoWrapper } from "./Login";

export const RegisterSuccess = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    gtag("js", new Date());
    gtag("config", "UA-213158358-1");
  });
  const gtag = (arg) => {
    window.dataLayer.push(arg);
  };
  return (
    <Warpper>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-213158358-1"
        ></script>
      </Helmet>
      <Success />
    </Warpper>
  );
};

const Warpper = styled.div`
  text-align: center;
  color: ligntgray;
`;
const Success = () => {
  const { go } = Context();
  return (
    <Container>
      <LoginWrapper>
        <Absolute>
          <img
            src="/images/bg-hong.svg"
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Absolute>
        <LogoWrapper>
          <img
            src="/images/logodotcom.svg"
            alt="logo"
            style={{ width: "100%" }}
          />
        </LogoWrapper>
        <Wrapper width="100%" maxWidth="500px">
          <Flex direction="column" align="center">
            <Typo.H6 margin="0 0 15px 0" color={"navy"}>
              การสมัครสมาชิกเสร็จสิ้น
            </Typo.H6>
            <Typo.H6 margin="0 0 35px 0" color={"navy"}>
              ยินดีตอนรับสู่หงษ์ทองออนไลน์
            </Typo.H6>
            <Button
              onClick={() => {
                go("/");
                window.location.reload();
              }}
              background="yellow"
              hoverBg="transparent"
              hoverColor="yellow"
              hoverBorder="yellow"
              width="100%"
            >
              ซื้อลอตเตอรี่
            </Button>
          </Flex>
        </Wrapper>
      </LoginWrapper>
    </Container>
  );
};
