import React from "react";
import styled from "styled-components";
import themes from "../../themes";
import { devices } from "../../constants";

const DivStyle = styled.div`
  position: ${(props) => props.position || "relative"};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: ${(props) => props.transform};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  line-height: ${(props) => props.lineHeight};
  flex-wrap: ${(props) => props.wrap};
  gap: ${(props) => props.gap};
  background: ${(props) => themes.colors[props.background]};
  flex-basis: ${(props) => props.basis};
  flex: ${(props) => props.flex};
  border-radius: ${(props) => props.radius};
  border: ${(props) => props.border};
  cursor: ${(props) => props.cursor};
  flex-grow: ${(props) => props.grow};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  z-index: ${(props) => props.zIndex};
  color: ${(props) => themes.colors[props.color]};
  text-align: ${(props) => props.textAlign};
  cursor: ${(props) => props.cursor};
  order: ${(props) => props.order};
  justify-self: ${(props) => props.justifySelf};
  overflow: ${(props) => props.overflow};
  transition-duration: ${(props) => props.duration};
  z-index: ${(props) => props.zIndex};
  &.btn-login-close {
    display: none;
  }
  @media only screen and (${devices.tablet}) {
    padding: ${(props) => props.paddingTablet || props.padding};
    order: ${(props) => props.orderTablet};
    justify-self: ${(props) => props.justifySelfTablet};
    &.btn-login-close {
      display: block;
    }
  }

  @media only screen and (max-width: 510px) {
    padding: ${(props) => props.paddingMobile || props.padding};
  }
`;
export const Div = (props) => {
  const { children, className } = props;
  return (
    <DivStyle className={className} {...props}>
      {children}
    </DivStyle>
  );
};
