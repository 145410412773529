export const tsToStr = () =>
  parseInt(
    new Date()
      .toISOString()
      .slice(-22)
      .replace(/(\.|-|:|T|Z)/g, "")
  ).toString(36);
export const sumPxQ = (arr, basePrice) => {
  const result = arr.reduce(
    (r, x) => (r += x.quantity * (x.price + basePrice)),
    0
  );
  if (parseInt(result)) {
    return result;
  } else {
    return "xxx";
  }
};
export const sum = (arr) => arr.reduce((r, x) => (r += x), 0);
export const addressToHtmlString = ({
  address,
  subDistrict,
  district,
  province,
  zipcode,
}) => {
  return `${address}<br>
  ${subDistrict}${district}<br/>
  ${province}${zipcode}`;
};

export const getPrice = ({ specialPrices, target }) => {
  target = String(target);
  if (target.includes("magicBlock")) {
    // return spexcialPrices["magicBlock"] || 0;
    target = String(target).split("-")[1];
  }
  if (!specialPrices || !target) return 0;
  return Object.keys(specialPrices).reduce((result, xxxxxx) => {
    const match = xxxxxx
      .split("")
      .every((x, i) => ["x", "X", target[i]].includes(x));

    if (match) {
      return specialPrices[xxxxxx];
    } else {
      return result;
    }
  }, 0);
};

export const getShippingPrize = ({ shipping, size }) => {
  let total = 0;
  for (let i = 0; i < size; i++) {
    total += shipping[i] || shipping[shipping.length - 1];
  }
  return total;
};
export const thaiDate = (date) => {
  return (
    (date || new Date()).toLocaleString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }) + " น."
  );
};
export const fullDate = (ts) => {
  return new Date(ts).toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};
export const shortDate = (ts) => {
  return new Date(ts).toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
export const shortTime = (ts) => {
  return (
    new Date(ts).toLocaleString("th-TH", {
      hour: "2-digit",
      minute: "2-digit",
    }) + " น."
  );
};
export const roundDateToDate = (roundDate) => {
  if (!roundDate) return "";
  return new Date(roundDate).toLocaleDateString("th-TH", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};
export const randomLetters = (length) => {
  //for now max is 10 digits
  const max = Math.pow(36, length) - 1;
  return Math.floor(Math.random() * max)
    .toString(36)
    .toUpperCase()
    .padStart(length, "0");
};

export const getKeyTranslated = (key) => {
  switch (key) {
    case "first":
      return { name: "ที่ 1", sequence: 1 };
    case "nearBy":
      return { name: "ไกล้เคียงรางวัลที่ 1", sequence: 2 };
    case "second":
      return { name: "ที่ 2", sequence: 3 };
    case "third":
      return { name: "ที่ 3", sequence: 4 };
    case "forth":
      return { name: "ที่ 4", sequence: 5 };
    case "fith":
      return { name: "ที่ 5", sequence: 6 };
    case "firstThree":
      return { name: "เลขหน้า 3 ตัว", sequence: 7 };
    case "lastThree":
      return { name: "เลขท้าย 3 ตัว", sequence: 8 };
    case "lastTwo":
      return { name: "เลขท้าย 2 ตัว", sequence: 9 };
    default:
      break;
  }
};

export const monthTh = {
  "01": "มกราคม",
  "02": "กุมภาพันธ์",
  "03": "มีนาคม",
  "04": "เมษายน",
  "05": "พฤษภาคม",
  "06": "มิถุนายน",
  "07": "กรกฎาคม",
  "08": "สิงหาคม",
  "09": "กันยายน",
  10: "ตุลาคม",
  11: "พฤศจิกายน",
  12: "ธันวาคม",
};

export const bankList = {
  0: "โปรดเลือก",
  1: "ธนาคารกรุงเทพฯ",
  2: "ธนาคารไทยพาณิชย์",
  3: "ธนาคารกสิกรไทย",
  4: "ธนาคารกรุงศรีอยุธยา",
  5: "ธนาคารกรุงไทย",
  6: "ธนาคารธนชาต",
  7: "ธนาคารทิสโก้",
  8: "ธนาคารออมสิน",
  9: "ธนาคารอาคารสงเคราะห์",
  10: "ธนาคารอิสลามแห่งประเทศไทย",
  11: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  12: "ธนาคารทหารไทยธนชาต",
  13: "ธนาคารซีไอเอ็มบีไทย",
  14: "ธนาคารยูโอบี",
  15: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
  16: "ธนาคารเกียรตินาคินภัทร",
};

export const CountDown = ({ second = 0, zeroRefresh }) => {
  const hh = String(Math.floor(second / 3600)).padStart(2, "0");
  const mm = String(Math.floor(second / 60) % 60).padStart(2, "0");
  const ss = String(second % 60).padStart(2, "0");
  const pattern = `${hh === "00" ? "" : hh + ":"}${mm}:${ss}`;
  if (zeroRefresh && pattern === "00:00") {
    window.location.reload();
  }

  return pattern;
};

export const resizeImage = (file) =>
  new Promise((resolve, reject) => {
    if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type))
      reject(false);
    const maxW = 600; //กำหนดความกว้าง
    const maxH = 1200; //กำหนดความสูง
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = document.createElement("img");
    img.onload = function () {
      const iw = img.width;
      const ih = img.height;
      const scale = Math.min(maxW / iw, maxH / ih);
      const iwScaled = iw * scale;
      const ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      context.drawImage(img, 0, 0, iwScaled, ihScaled);
      resolve(canvas.toDataURL("image/jpeg", 1)); //0.5 คือ คุณภาพของรูป ที่ Resize
    };
    img.src = URL.createObjectURL(file);
  });
