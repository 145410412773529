import React, { useEffect, useState } from "react";
import Context from "../Context";
import {
  Typo,
  Flex,
  StackImages,
  ContentContainer,
  OrderContainer,
  Button,
  Container,
} from "../components";
import styled from "styled-components";
import themes from "../themes";
import { Collapse, Input, Table } from "reactstrap";
import { getThaiDateFormat, statuses, locale } from "../constants";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { getKeyTranslated } from "../util";

export const Histories = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { callApi, configs, profileState } = Context();
  const [orderList, setOrderList] = useState([]);
  const [, setIsLastCallApiFound] = useState(true);
  const [, setLastOrderId] = useState("");
  const [showFailedOrder, setShowFailedOrder] = useState(false);
  const [isHasPrize, setIsHasPrize] = useState(false);
  // const [isOldAward, setIsOldAward] = useState(false);

  const [tempLang, setTempLang] = useState("TH");
  const [roundDate, setRoundDate] = useState(
    configs.isAwardReady
      ? configs.roundDate
      : configs.roundDates.sort().reverse()[1]
  );
  const [rewards, setRewards] = useState([]);

  const [totalReward, setTotalReward] = useState(0);

  const getMoreOrder = async (body = {}) => {
    body.limit = 10;
    // const orders = await callApi("orders-getOrder", body);
    const { orderData } = await callApi("orders-getUserOrders", body);
    if (orderData.length) {
      setLastOrderId(orderData.slice(-1)[0].id);
    }
    setIsLastCallApiFound(orderData.length === body.limit);
    setIsLoaded(true);
    setOrderList(orderData || []);
    let tPrizes = {
      THB: 0,
      LAK: 0,
    };

    const prizes = orderData.reduce((acc, order) => {
      order?.prizes?.forEach(({ prizeType, prizeAmount, number, winner }) => {
        const key = prizeType + number;
        acc[key] = acc[key] || {
          type: prizeType,
          number,
          winner,
          amount: {
            THB: 0,
            LAK: 0,
          },
          count: 0,
          lotteries: order.lotteries.filter(
            (lottery) => lottery.number === number
          ),
        };
        acc[key].amount["THB"] += prizeAmount.THB;
        acc[key].amount["LAK"] += prizeAmount.LAK;
        acc[key].count++;
        tPrizes["THB"] += prizeAmount.THB;
        tPrizes["LAK"] += prizeAmount.LAK;
      });
      return acc;
    }, {});

    setTotalReward(tPrizes);

    setIsHasPrize(!!tPrizes.THB);

    const finalReward = Object.values(prizes)
      .reduce((acc, reward) => {
        reward.sequence = getKeyTranslated(reward.type).sequence;
        reward.number = [reward.number];
        var f = acc.filter((e) => e.type === reward.type);
        if (f.length > 0) {
          f[0].number = [].concat(f[0].number, reward.number);
          f[0].winner = [].concat(f[0].winner, reward.winner);
          f[0].count += reward.count;
          f[0].amount["THB"] += reward.amount.THB;
          f[0].amount["LAK"] += reward.amount.LAK;
          f[0].lotteries = [...f[0].lotteries, ...reward.lotteries];
        } else {
          acc.push(reward);
        }
        return acc;
      }, [])
      .sort((a, b) => a.sequence - b.sequence);
    setRewards(finalReward);
  };

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      if (localStorage.accessToken) {
        getMoreOrder({
          roundDate: configs.isAwardReady
            ? configs.roundDate
            : configs.roundDates[1],
        });
      }

      switch (profileState.bank.currency) {
        case "THB":
          setTempLang("TH");
          break;
        case "LAK":
          setTempLang("LAO");
          break;
        default:
          setTempLang("TH");
      }
    })();
    // eslint-disable-next-line
  }, []);

  const awardText = () => {
    if (!configs.isAwardReady && configs.roundDate === roundDate) {
      return (
        <Flex width="100%" direction="column" align="center">
          <Flex className="responsive-column" align="center" margin="20px 0">
            <Typo.H7 color="navy" margin="0 10px 0 0">
              รางวัลที่ 1{" "}
            </Typo.H7>
            <Typo.H7 color="textBlack"> กำลังรอคุณอยู่...</Typo.H7>
          </Flex>

          <img
            src="/images/prize_not_ready.png"
            alt="no_ready_yet"
            style={{ maxWidth: "300px", width: "100%" }}
          />
        </Flex>
      );
    }
    if (isHasPrize) {
      return (
        <Flex width="100%" direction="column" align="center">
          <Flex className="responsive-column" align="center" margin="20px 0">
            <Typo.H2 color="navy" margin="0 10px 0 0">
              {locale.history_congrat[tempLang].split(" ")[0]}
            </Typo.H2>
            <Typo.H7 color="green" margin="0 10px 0 0">
              {locale.history_congrat[tempLang].split(" ")[1]}
            </Typo.H7>
          </Flex>
          <Typo.H1 color="yellow">
            {totalReward[profileState?.bank?.currency || "THB"].toLocaleString(
              "en-US"
            )}{" "}
            {locale.currency[tempLang]}
          </Typo.H1>
          <img
            alt="reward"
            src={"/images/reward.png"}
            style={{ maxWidth: "300px", width: "100%" }}
          />
        </Flex>
      );
    } else {
      return (
        <Flex width="100%" direction="column" align="center">
          <Flex className="responsive-column" align="center" margin="20px 0">
            <Typo.H7 color="navy" margin="0 5px 0 0">
              เสียใจด้วย...{" "}
            </Typo.H7>
            <Typo.H7 color="textBlack">คุณไม่ถูกรางวัล</Typo.H7>
          </Flex>

          <img
            src="/images/prize_no_prize.png"
            alt="no_ready_yet"
            style={{ maxWidth: "300px", width: "100%" }}
          />
        </Flex>
      );
    }
  };

  return (
    <Container>
      <ContentContainer margin="0">
        <Typo.H6 textAlign="center" color="navy">
          ผลการตรวจรางวัล
        </Typo.H6>
        <Input
          type="select"
          style={{ margin: "20px 0" }}
          onChange={(e) => {
            setRoundDate(e.currentTarget.value);
            getMoreOrder({ roundDate: e.currentTarget.value });
          }}
          defaultValue={roundDate}
        >
          {configs.isAwardReady
            ? [...configs.roundDates]
                .filter((date) => date !== configs.nextRoundDate)
                .sort()
                .reverse()
                .slice(0, 4)
                .map((roundDate) => (
                  <option key={roundDate} value={roundDate}>
                    งวดวันที่ {getThaiDateFormat(new Date(roundDate))}
                  </option>
                ))
            : [...configs.roundDates]
                .sort()
                .reverse()
                .slice(1, 5)
                .map((roundDate) => (
                  <option key={roundDate} value={roundDate}>
                    งวดวันที่ {getThaiDateFormat(new Date(roundDate))}
                  </option>
                ))}
        </Input>

        {awardText()}
      </ContentContainer>

      {isHasPrize && (
        <>
          {rewards.map((reward, index) => (
            <RewardList
              key={index}
              reward={reward}
              profileState={profileState}
              tempLang={tempLang}
              index={index}
              showImage={configs.roundDate === roundDate}
            />
          ))}
          <RewardTable
            rewards={rewards}
            roundDatess={roundDate}
            profileState={profileState}
          />
        </>
      )}
      <OrderViewSelector
        orderList={orderList}
        setShowFailedOrder={setShowFailedOrder}
        showFailedOrder={showFailedOrder}
      />
      {orderList.length ? (
        orderList
          .sort((a, b) => b.createdAt - a.createdAt)
          .filter((lotto) =>
            showFailedOrder
              ? lotto.status !== "approved"
              : lotto.status === "approved"
          )
          .map((order, i) => {
            return (
              <OrderContainer
                key={i}
                order={order}
                viewOnly={true}
                showImage={configs.roundDate === roundDate}
              />
            );
          })
      ) : (
        <Typo.Body2 textAlign="center" margin="100px 0" color="navy">
          ไม่พบคำสั่งซื้อสำเร็จในงวดนี้
        </Typo.Body2>
      )}
    </Container>
  );
};

const RewardTable = ({ rewards, roundDatess, profileState }) => {
  return (
    <ContentContainer
      bgcolor="white"
      cursor="pointer"
      minHeight="0"
      direction="column"
    >
      <Typo.H3 color="textBlack" textAlign="center">
        ผลการตรวจรางวัล
      </Typo.H3>
      <Typo.Body2 color="textBlack" textAlign="center">
        งวดวันที่ {getThaiDateFormat(new Date(roundDatess))}
      </Typo.Body2>

      <table
        style={{
          borderCollapse: "collapse",
          borderRadius: "5px",
          width: "100%",
          border: "1px solid black",
          marginTop: "20px",
        }}
      >
        <thead style={{ border: "1px solid black" }}>
          <Tr
            style={{
              background: themes.colors.gray2,
            }}
          >
            <Th
              style={{ border: "1px solid black", borderRadius: "5px 0 0 0" }}
            >
              รางวัล
            </Th>
            <Th style={{ border: "1px solid black" }}>เลขสลาก</Th>
            <Th style={{ border: "1px solid black" }}>จำนวน</Th>
            <Th
              style={{ border: "1px solid black", borderRadius: "0 5px 0 0" }}
            >
              มูลค่า
            </Th>
          </Tr>
        </thead>
        <tbody
          style={{
            borderRadius: "5px",
            border: "1px solid black",
          }}
        >
          {rewards.map((reward, i) => (
            <Tr key={i}>
              <Td style={{ border: "1px solid black" }}>
                รางวัล{getKeyTranslated(reward.type).name}
              </Td>
              <Td style={{ border: "1px solid black" }}>
                {reward.number.map((data, index, arr) =>
                  index + 1 !== arr.length ? data + ", " : data
                )}
              </Td>
              <Td style={{ border: "1px solid black" }}>{reward.count}</Td>
              <Td style={{ border: "1px solid black" }}>
                {reward.amount.THB.toLocaleString()}
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td style={{ border: "1px solid black" }}>รวม</Td>
            <Td style={{ border: "1px solid black" }}></Td>
            <Td style={{ border: "1px solid black" }}></Td>
            <Td style={{ border: "1px solid black" }}>
              {rewards
                .reduce(
                  (acc, cur) =>
                    acc + cur.amount[profileState.bank?.currency || "THB"],
                  0
                )
                .toLocaleString()}
            </Td>
          </Tr>
        </tbody>
      </table>
    </ContentContainer>
  );
};
const RewardList = ({ reward, index, profileState, tempLang, showImage }) => {
  const [isOpen, setOpen] = useState(index === 0);

  return (
    <ContentContainer
      bgcolor="navy"
      cursor="pointer"
      key={index}
      minHeight="0"
      direction="column"
      onClick={() => setOpen(!isOpen)}
    >
      <Typo.H3 textAlign="center" color="white" weight={500}>
        รางวัล{getKeyTranslated(reward.type).name} จำนวน {reward.count} ใบ
      </Typo.H3>
      <Typo.H5 textAlign="center" color="white">
        เงินรางวัลรวม{" "}
        {reward.amount[profileState.bank?.currency || "THB"].toLocaleString(
          "en-US"
        )}{" "}
        {locale.currency[tempLang]}
      </Typo.H5>

      {/* {reward.lotteries.length && reward.lotteries[0].image && ( */}
      <>
        <Collapse isOpen={isOpen}>
          {showImage ? (
            <div style={{ width: "60%", height: "100%", margin: "2em auto" }}>
              <StackImages
                urls={reward.lotteries.map((x) => [x.image, x.isMagicNumber])}
              />
            </div>
          ) : (
            <>
              <Table bordered>
                <thead>
                  <tr>
                    <td>
                      <Typo.H7>ลำดับที่</Typo.H7>
                    </td>
                    <td>
                      <Typo.H7>หมายเลข</Typo.H7>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {reward?.lotteries.map((x, index) => {
                    return (
                      <tr>
                        <td>
                          <Typo.H7>{index + 1}</Typo.H7>
                        </td>
                        <th>
                          <Typo.H7>{x.number}</Typo.H7>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </Collapse>
        <Flex
          direction="column"
          justify="center"
          align="center"
          background={themes.colors.secondaryGradient2}
          padding="0.5em 0"
          margin="0"
          radius="0 0 10px 10px"
          onClick={() => setOpen(!isOpen)}
        >
          {isOpen ? (
            <FiChevronUp
              style={{ color: themes.colors.primary, fontSize: "1.5em" }}
            />
          ) : (
            <FiChevronDown color="yellow" />
          )}
        </Flex>
      </>
      {/* //   )} */}
    </ContentContainer>
  );
};

const OrderViewSelector = ({
  orderList,
  showFailedOrder,
  setShowFailedOrder,
}) => {
  return (
    <Flex
      justify="center"
      margin="20px 0"
      width="100%"
      height="68px"
      background="white"
      radius="60px"
      padding="8px"
    >
      <Button
        grow={1}
        background={!showFailedOrder && "yellow"}
        hoverBg={showFailedOrder && "transparent"}
        radius="60px"
        onClick={() => setShowFailedOrder(false)}
      >
        <Typo.Body2 color="navy">รายการคำสั่งซื้อที่เสร็จสมบูรณ์</Typo.Body2>
      </Button>
      <Button
        grow={1}
        hoverBg={!showFailedOrder && "transparent"}
        background={showFailedOrder && "yellow"}
        radius="60px"
        cursor={
          orderList.filter((order) => order.status === statuses.CANCEL)
            .length === 0
            ? "no-drop"
            : "pointer"
        }
        onClick={() =>
          orderList.filter((order) => order.status === statuses.CANCEL)
            .length !== 0 && setShowFailedOrder(true)
        }
      >
        <Typo.Body2
          color={
            orderList.filter((order) => order.status === statuses.CANCEL)
              .length === 0
              ? "gray2"
              : "navy"
          }
        >
          รายการคำสั่งซื้อที่ถูกยกเลิก
        </Typo.Body2>
      </Button>
    </Flex>
  );
};

const Head = styled.p`
  color: ${(props) => props.color};
  font-weight: 800;
  font-size: ${(props) => props.size || "5em"};
  margin: ${(props) => props.margin || "0"};

  @media screen and (max-width: 650px) {
    font-size: 2em;
  }
`;

const Tr = styled.tr`
  border: none;
  text-align: center;
  :nth-child(even) {
    background: rgba(255, 233, 38, 0.3);
  }
  border-radius: 5px;
`;
const Th = styled.th`
  padding: 5px 10px;
`;
const Td = styled.td`
  padding: 5px 0;
  tr:last-child &:last-child {
    border-radius: 0 0 5px 0;
  }
  tr:last-child &:first-child {
    border-radius: 0 0 0 5px;
  }
`;
