import React, { useState, useEffect, useRef } from "react";
import Context from "../../Context";
import {
  ContentContainer,
  Container,
  Typo,
  Flex,
  Button,
  Breadcrumb,
  Input,
  Line,
} from "../../components";
import { useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Modal, ModalBody } from "reactstrap";
import { validate } from "../../constants";
import { resizeImage, monthTh } from "../../util";

export const DonateDetail = () => {
  const { callApi, setModalLoading, profileState, go, configs } = Context();
  const uploadRef = useRef(null);
  const { donateId } = useParams();
  const [donate, setDonate] = useState([]);
  const [image, setImage] = useState({
    qrImage: undefined,
    previewImage: undefined,
  });

  const [donateAmount, setDonateAmount] = useState("");
  const [isDonateWithCash, setIsDonateWithCash] = useState(false);
  const [isShow, setShow] = useState({
    confirmModal: false,
    thankModal: false,
    bankInfo: false,
  });

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);

      setModalLoading({ isShow: true });
      const donate = await callApi("donates-getDonateById", {
        id: donateId,
      });

      setDonate(donate);
      setModalLoading({ isShow: false });
    })();
  }, []);

  const submitDonate = async (id) => {
    await callApi("donates-createRedeemTransaction", {
      placeId: donateId,
      amount: isDonateWithCash ? 0 : Number(donateAmount),
      isDonateWithCash,
      image: isDonateWithCash ? image.qrImage : false,
    }).then((result) => {
      if (result.status) {
        setShow((prevState) => ({
          ...prevState,
          //   confirmModal: !prevState.confirmModal,
          thankModal: !prevState.thankModal,
        }));
      } else {
      }
    });
  };

  if (donate.length === 0) return;

  return (
    <>
      <Container>
        <ThankModal
          isShow={isShow}
          setShow={setShow}
          place={donate}
          profileState={profileState}
        />

        <ConfirmModal
          isShow={isShow}
          setShow={setShow}
          place={donate}
          handleSubmit={submitDonate}
          amount={donateAmount}
        />
        <Breadcrumb
          data={[
            { path: "/", name: "หน้าแรก" },
            { path: "/donate", name: "บริจาค" },
            { name: donate.name },
          ]}
        />
        <ContentContainer
          bgcolor="gradient2"
          minHeight="10px"
          padding="16px 24px"
        >
          <Flex justify="space-between" align="center">
            <Flex direction="column" gap="8px">
              <Typo.Body2 color="white">คะแนนสะสมของคุณ</Typo.Body2>
              <Typo.Body5>
                ใช้ได้ถึงสิ้นเดือน
                {monthTh[profileState.pointExpireMonth || 12]}{" "}
              </Typo.Body5>
            </Flex>
            <Flex align="center" gap="10px">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.1593 0.443922C2.8527 1.80168 -3.27818 14.4552 2.65518 24.1873C6.88822 31.1305 15.9265 33.6553 23.16 29.9151C32.5898 25.0394 34.5623 12.4299 27.0676 4.9352C23.71 1.5776 18.8373 -0.117838 14.1593 0.443922ZM17.1762 7.87264C23.7081 8.62368 24.4731 17.6089 18.1593 19.4201C17.6254 19.5732 17.5036 19.5828 15.7686 19.6087L13.9405 19.636V21.9116V24.1873H11.7217H9.50302V15.9998V7.81224L13.0812 7.81272C15.0491 7.81296 16.8919 7.83992 17.1762 7.87264ZM13.9405 13.7112V15.7498H15.1258C17.1836 15.7498 17.9047 15.2222 17.9023 13.7185C17.9 12.2583 17.2163 11.7531 15.1749 11.703L13.9405 11.6726V13.7112Z"
                  fill="#FFCD00"
                />
              </svg>
              <Typo.H2 color="yellow" margin="5px 0 0 0">
                {new Intl.NumberFormat().format(profileState.buyPoint || 0)}
              </Typo.H2>
            </Flex>
          </Flex>
        </ContentContainer>
        <Flex direction="column" align="center">
          <Typo.H6 color="navy" margin="35px 0" textAlign="center">
            {donate.name}
          </Typo.H6>
          <img
            src={donate.image}
            alt="donate"
            style={{ width: "100%", maxWidth: "400px" }}
          />
          <ContentContainer minHeight="0px" padding="16px 24px">
            <Typo.H6 color="navy" margin="0 0 15px 0">
              ประวัติ
            </Typo.H6>
            <Typo.Body1 color="textBlack">{donate.detail}</Typo.Body1>
          </ContentContainer>
          <ContentContainer
            bgcolor="gradient2"
            minHeight="10px"
            margin="25px 0 0 0"
            padding="10px"
          >
            <Flex align="center" justify="space-between">
              <Typo.H6 color="white">ยอดคะแนนบริจาค</Typo.H6>
              <Flex align="center" gap="10px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.1593 0.443922C2.8527 1.80168 -3.27818 14.4552 2.65518 24.1873C6.88822 31.1305 15.9265 33.6553 23.16 29.9151C32.5898 25.0394 34.5623 12.4299 27.0676 4.9352C23.71 1.5776 18.8373 -0.117838 14.1593 0.443922ZM17.1762 7.87264C23.7081 8.62368 24.4731 17.6089 18.1593 19.4201C17.6254 19.5732 17.5036 19.5828 15.7686 19.6087L13.9405 19.636V21.9116V24.1873H11.7217H9.50302V15.9998V7.81224L13.0812 7.81272C15.0491 7.81296 16.8919 7.83992 17.1762 7.87264ZM13.9405 13.7112V15.7498H15.1258C17.1836 15.7498 17.9047 15.2222 17.9023 13.7185C17.9 12.2583 17.2163 11.7531 15.1749 11.703L13.9405 11.6726V13.7112Z"
                    fill="#FFCD00"
                  />
                </svg>
                <Typo.H2 color="yellow" margin="5px 0 0 0">
                  {new Intl.NumberFormat().format(donate.donatePoint || 0)}
                </Typo.H2>
              </Flex>
            </Flex>
          </ContentContainer>
          <ContentContainer
            bgcolor="gradient2"
            minHeight="10px"
            margin="25px 0 0 0"
            padding="10px"
          >
            <Flex align="center" justify="space-between">
              <Typo.H6 color="white">ยอดเงินบริจาค</Typo.H6>
              <Flex align="center" gap="10px">
                <Typo.H2 color="yellow" margin="5px 0 0 0">
                  ฿ {new Intl.NumberFormat().format(donate.donateCash || 0)}
                </Typo.H2>
              </Flex>
            </Flex>
          </ContentContainer>
          <Flex
            justify="center"
            width="100%"
            height="68px"
            background="white"
            radius="60px"
            padding="8px"
            margin="25px 0"
            gap="5px"
          >
            <Button
              grow={1}
              background={!isDonateWithCash && "yellow"}
              hoverBg={isDonateWithCash && "white"}
              onClick={() => setIsDonateWithCash(false)}
              radius="60px"
            >
              <Typo.H6
                color="navy"
                weight="bold"
                hoverColor={isDonateWithCash && "yellow"}
              >
                บริจาคด้วยคะแนน
              </Typo.H6>
            </Button>
            <Button
              grow={1}
              background={isDonateWithCash && "yellow"}
              hoverBg={!isDonateWithCash && "white"}
              onClick={() => setIsDonateWithCash(true)}
              radius="60px"
            >
              <Typo.H6
                color="navy"
                weight="bold"
                hoverColor={!isDonateWithCash && "yellow"}
              >
                บริจาคผ่านบัญชีธนาคาร
              </Typo.H6>
            </Button>
          </Flex>

          {!isDonateWithCash && (
            <ContentContainer
              bgcolor="gradient2"
              minHeight="10px"
              margin="0 0 75px 0"
              padding="24px"
            >
              <Typo.H6 color="white">บริจาคด้วยคะแนน</Typo.H6>
              <Typo.Body6 color="white" margin="20px 0 0 0">
                จำนวนที่ต้องการบริจาค
              </Typo.Body6>

              <Input
                placeHolder="คะแนน"
                value={donateAmount}
                key="accNo"
                type="input"
                width="200px"
                onChange={(newValue) => {
                  const value = newValue.target.value;
                  if (validate.digitOnly.test(value)) {
                    setDonateAmount(value);
                  }
                }}
              />

              <Button
                disabled={
                  !donateAmount ||
                  donateAmount === 0 ||
                  donateAmount > profileState.buyPoint
                }
                background="primary"
                margin="25px 0 0 0"
                onClick={() => {
                  setShow((prevState) => ({
                    ...prevState,
                    confirmModal: true,
                  }));
                }}
              >
                ยืนยันการบริจาค
              </Button>
            </ContentContainer>
          )}
          {isDonateWithCash && (
            <BankInfo
              donateAmount={donateAmount}
              uploadRef={uploadRef}
              image={image}
              setImage={setImage}
            />
          )}
          {isDonateWithCash && (
            <Button
              disabled={!image.previewImage}
              background="primary"
              width="100%"
              margin="25px 0 75px 0"
              onClick={() => {
                submitDonate();
              }}
            >
              ยืนยันการบริจาค
            </Button>
          )}
        </Flex>
      </Container>
    </>
  );
};

const BankInfo = ({ donateAmount, uploadRef, image, setImage }) => {
  return (
    <ContentContainer
      minHeight="10px"
      padding="32px 80px"
      paddingMobile="24px 20px"
      margin="0"
    >
      <Flex align="center">
        <Typo.Body1 width="100px" color="gray5">
          ธนาคาร
        </Typo.Body1>
        <img
          src="/images/uob_bank_logo.png"
          alt="uob_logo"
          style={{ width: "40px" }}
        />
        <Typo.Body1 margin="0 0 0 10px" color="textBlack">
          ธนาคารยูโอบี (UOB)
        </Typo.Body1>
      </Flex>
      <Flex align="center" margin="20px 0">
        <Typo.Body1 width="100px" color="gray5">
          ชื่อบัญชี
        </Typo.Body1>

        <Typo.Body1 color="textBlack">ฐิตาภา ธนทรัพย์ปรีชาญ์</Typo.Body1>
      </Flex>
      <Flex align="center" justify="space-between">
        <Flex>
          <Typo.Body1 width="100px" color="gray5">
            เลขที่บัญชี
          </Typo.Body1>
          <Typo.Body1 id="btnCopyLink" color="textBlack">
            305-341-7076
          </Typo.Body1>
        </Flex>

        <Button
          background="yellow"
          hoverBg="transparent"
          hoverColor="yellow"
          hoverBorder="yellow"
          color="navy"
          onClick={() => {
            const copyText = document.getElementById("btnCopyLink").innerHTML;
            window.navigator.clipboard.writeText(copyText);
            // toast("คัดลอกเลขบัญชีเเล้ว", {
            //   icon: "success",
            //   autoClose: 1000,
            // });
          }}
        >
          คัดลอก
        </Button>
      </Flex>

      {/* <Flex align="center" justify="space-between">
        <Typo.Body1 id="btnCopyLink" color="textBlack">
          ยอดที่ต้องชำระ
        </Typo.Body1>

        <Typo.H5 color="red">
          {new Intl.NumberFormat().format(donateAmount)} บาท
        </Typo.H5>
      </Flex> */}
      {image.previewImage && (
        <>
          <Line />
          <Flex align="center" justify="space-between">
            <Typo.Body1 id="btnCopyLink" color="textBlack">
              หลักฐานการโอนเงิน
            </Typo.Body1>
            <Flex gap="15px">
              <Button
                background="navy"
                hoverBg="transparent"
                hoverBorder="navy"
                hoverColor="navy"
                onClick={() => window.open(image.previewImage)}
              >
                ดูสลิป
              </Button>
              <Button
                background="red"
                hoverBg="transparent"
                hoverBorder="red"
                hoverColor="red"
                onClick={() => {
                  setImage((prevState) => ({
                    previewImage: undefined,
                    image: undefined,
                  }));
                }}
              >
                ลบ
              </Button>
            </Flex>
          </Flex>
        </>
      )}
      {!image.previewImage && (
        <>
          <Button
            margin="24px 0 12px 0"
            height="55px"
            width="100%"
            hoverColor="navy"
            background="gradient2"
            hoverBg="transparent"
            hoverBorder="navy"
            gap="10px"
            onClick={() => uploadRef.current.click()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.147 0.882191C9.965 0.960341 4.86025 6.05364 4.7475 6.26964C4.4763 6.78904 4.83795 7.45039 5.41615 7.49219C5.85085 7.52364 5.7787 7.58189 7.7935 5.57244L9.63925 3.73169V9.00939C9.63925 13.6601 9.64635 14.3074 9.69935 14.4575C9.9632 15.2053 11.0856 15.1773 11.3134 14.4172C11.3458 14.3089 11.358 12.8345 11.358 9.00004V3.73169L13.2037 5.57244C15.2185 7.58189 15.1463 7.52364 15.581 7.49219C16.1598 7.45034 16.5193 6.78919 16.2476 6.26644C16.1304 6.04094 11.0284 0.957941 10.8416 0.880591C10.6587 0.804791 10.3254 0.805541 10.147 0.882191ZM1.8072 10.8944C1.61575 10.9831 1.46005 11.1441 1.3796 11.3367C1.29215 11.5461 1.2893 15.3677 1.37625 15.8641C1.6684 17.5322 2.9665 18.8303 4.6346 19.1224C5.1241 19.2082 15.8731 19.2082 16.3626 19.1224C18.0307 18.8303 19.3288 17.5322 19.6209 15.8641C19.7079 15.3677 19.705 11.5461 19.6176 11.3367C19.3649 10.7318 18.5035 10.6408 18.1299 11.1796L18.0181 11.3409L17.9944 13.4341C17.9689 15.68 17.9692 15.6765 17.7544 16.1392C17.4628 16.7674 16.8669 17.2494 16.1626 17.4265C15.8434 17.5068 5.15375 17.5068 4.83455 17.4265C4.1303 17.2494 3.5344 16.7674 3.24275 16.1392C3.028 15.6765 3.0283 15.68 3.0028 13.4341L2.97905 11.3409L2.86725 11.1796C2.64565 10.86 2.16235 10.73 1.8072 10.8944Z"
                fill="#FFCD00"
              />
            </svg>
            อัปโหลดสลิป
          </Button>
          <input
            ref={uploadRef}
            style={{ display: "none" }}
            type="file"
            onChange={async (e) => {
              const file = e.target.files[0];
              const base64 = await resizeImage(file);
              if (base64) {
                const previewUrl = URL.createObjectURL(file);
                setImage({
                  qrImage: base64,
                  previewImage: previewUrl,
                });
              } else {
                alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
              }
            }}
          />

          <Typo.Body5 color="gray5" textAlign="center">
            รองรับนามสกุลไฟล์ .jpg, .png, .jpeg เท่านั้น
          </Typo.Body5>
        </>
      )}
    </ContentContainer>
  );
};

const ConfirmModal = ({ isShow, setShow, place, handleSubmit, amount }) => {
  const toggle = () =>
    setShow((prevState) => ({
      ...prevState,
      confirmModal: !prevState.confirmModal,
    }));

  return (
    <Modal centered isOpen={isShow.confirmModal} toggle={toggle}>
      <ContentContainer padding="16px 24px" minHeight="0px">
        <Flex direction="column">
          <Typo.H6 color="navy" textAlign="center">
            ยืนยันร่วมการบริจาค
            <br /> ให้ {place?.name || ""}
            <br /> โดย ร่วมบริจาคจำนวน {amount} คะแนน
          </Typo.H6>
          <Flex margin="25px 0 15px 0" justify="center" gap="15px">
            <Button
              background="yellow"
              hoverBg="transparent"
              hoverColor="yellow"
              hoverBorder="yellow"
              onClick={() => handleSubmit()}
            >
              ยืนยัน
            </Button>
            <Button
              background="red"
              hoverBg="transparent"
              hoverColor="red"
              hoverBorder="red"
              onClick={() => {
                setShow((prevState) => ({
                  ...prevState,
                  confirmModal: !prevState.confirmModal,
                }));
              }}
            >
              ยกเลิก
            </Button>
          </Flex>
        </Flex>
      </ContentContainer>
    </Modal>
  );
};

const ThankModal = ({ isShow, setShow, place, profileState }) => {
  const toggle = () => {
    setShow((prevState) => ({
      ...prevState,
      confirmModal: !prevState.confirmModal,
      thankModal: !prevState.thankModal,
    }));
    window.location.reload();
  };

  return (
    <Modal centered isOpen={isShow.thankModal} toggle={toggle}>
      <ContentContainer padding="16px 24px">
        <Flex direction="column">
          {place.imageThankyou ? (
            <img src={place.imageThankyou} alt="thanks" />
          ) : (
            <img src="/images/love.svg" alt="thanks" />
          )}
          <Typo.H5 color="navy" textAlign="center" margin="25px 0">
            คุณ {profileState.firstName + " " + profileState.lastName}{" "}
            ได้ร่วมบริจาค ให้ {place.name}
          </Typo.H5>

          <Typo.Body1 color="textBlack" textAlign="center">
            ด้วยกุศลเจตนาที่ท่านได้มีจิตศรัทธาในการนี้ขออำนาจคุณพระศรีรัตนตรัยและสิ่งศักดิ์สิทธ์ในสากลโลก
            จงช่วยบัลดาลให้ท่านและครอบครัว ประสบสิ่งที่ท่านพึงประสงค์
            ทุกประการเทอญฯ ให้ไว้ ณ วันที่{" "}
            {new Date(Date.now()).toLocaleString("th-TH", {
              dateStyle: "long",
            })}
          </Typo.Body1>
          <Flex margin="25px 0 15px 0" justify="center" gap="15px">
            <Button
              onClick={() => {
                toggle();
                window.location.reload();
              }}
              background="yellow"
              hoverBg="transparent"
              hoverColor="yellow"
              hoverBorder="yellow"
            >
              ปิด
            </Button>
          </Flex>
        </Flex>
      </ContentContainer>
    </Modal>
  );
};
