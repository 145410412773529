import React from "react";
import style from "../constants/style";
import styled from "styled-components";

export const WhiteHolder = ({ children, header }) => {
  return (
    <Wrapper>
      {header && <Header>{header}</Header>}
      <WhiteHolderSc header={header}>{children}</WhiteHolderSc>
    </Wrapper>
  );
};

const Header = styled.div`
  font-size: 6vw;
  @media screen and (min-width: 450px) {
    font-size: 1.6rem;
  }
  background: ${style.colors.lightBlue};
  border-radius: 10px 10px 0 0;
  color: #f1c40e;
  text-align: center;
  padding: 10px;
`;
const WhiteHolderSc = styled.div`
  overflow: scroll;
  background: white;

  ${({ header }) =>
    header ? `border-radius:  0 0 10px 10px;` : `border-radius:  10px;`}
  text-align: center;
  width: auto;
  padding: 20px;
`;
const Wrapper = styled.div`
  position: relative;
  margin: 20px;
  max-width: 750px;
  width: 100%;
  ${style.css.mdRad}
  margin: auto;
  box-shadow: 4px 4px 8px 2px rgb(0 0 0 / 10%);
`;
