import React, { useState } from "react";
import styled from "styled-components";
import themes from "../../themes";
import { devices, fontWeight } from "../../constants";
import { Input as reactstrapInput } from "reactstrap";

const InputStyle = styled(reactstrapInput)`
  padding: ${(props) => props.padding || "14px 16px 14px 16px"};
  border: 1px solid ${themes.colors.gray2};
  border-radius: ${(props) => props.radius || "12px"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "48px"};
  font-weight: ${(props) => fontWeight[props.weight] || fontWeight.bold};
  outline: none;
  margin: ${(props) => props.margin};

  &.text-center {
    text-align: center;
  }
  ::placeholder {
    color: ${themes.colors.gray2};
  }
  :focus {
    border-color: ${themes.colors.yellow};
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export const Input = (props) => {
  const { children, className } = props;
  return (
    <InputStyle className={className} {...props}>
      {children}
    </InputStyle>
  );
};

const Password = (props) => {
  const { children, className } = props;
  const [showPassword, setIsShowPassword] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <InputStyle
        className={className}
        type={showPassword ? "text" : "password"}
        {...props}
      >
        {children}
      </InputStyle>
      <img
        src={`/images/${showPassword ? "passwordeye-show" : "passwordeye"}.svg`}
        alt="eye"
        style={{
          width: "18px",
          height: "15px",
          position: "absolute",
          top: "50%",
          right: "3%",
          transform: "translate(0,-50%)",
          cursor: "pointer",
        }}
        onClick={() => setIsShowPassword(!showPassword)}
      />
    </div>
  );
};

Input.Password = Password;
