import React, { useEffect, useState } from "react";
import Context from "../Context";
import styled from "styled-components";
import {
  Input,
  Button,
  Wrapper,
  Container,
  Div,
  Typo,
  Flex,
  InputBox,
} from "../components";
import style from "../constants/style";
import * as R from "ramda";
import { FiX } from "react-icons/fi";
import themes from "../themes";
import { LoginWrapper, Absolute, LogoWrapper } from "./Login";

export const RegisterOtp = () => {
  const { callApi, toast, profileState, go } = Context();
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [expireIn, setExpireIn] = useState(
    profileState?.otp?.expireAt - new Date()
  );

  const random = () => {
    return Math.floor(Math.random() * 60466175)
      .toString(36)
      .toUpperCase()
      .padStart(5, "0");
  };
  const [otpRef, setOtpRef] = useState(profileState?.otp?.ref || "");

  const submit = async () => {
    const otpCode = digits.join("");
    if (digits.length !== otpCode.length) {
      toast("โปรดใส่รหัสให้ครบถ้วน");
    } else {
      const result = await callApi("users-activateUser", {
        otpRef,
        otpCode,
        referNo: localStorage.getItem("referNo"),
      });
      if (result.status) {
        go("/register-success");
      } else {
        result.toastMessage && toast(result.toastMessage);
      }
    }
  };

  const CountDown = ({ second = 0 }) => {
    if (second <= 0) return <span>00:00</span>;
    second = parseInt(second / 1000);
    const pattern =
      String(Math.floor(second / 60)).padStart(2, "0") +
      ":" +
      String(second % 60).padStart(2, "0");
    return <span>{pattern}</span>;
  };

  useEffect(() => {
    const countDown = () =>
      setTimeout(() => {
        setExpireIn((x) => {
          if (x >= 1000) {
            return x - 1000;
          }
        });
        countDown();
      }, 1000);
    countDown();
    return () => clearTimeout(countDown);
  }, []);

  return (
    <Container>
      <Div
        className="btn-login-close"
        position="absolute"
        top="5%"
        right="5%"
        onClick={() => go("/")}
        zIndex={1}
      >
        <FiX
          style={{
            fontSize: "40px",
            color: themes.colors.white,
            cursor: "pointer",
          }}
        />
      </Div>

      <LoginWrapper>
        <Absolute>
          <img
            src="/images/bg-hong.svg"
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Absolute>
        <LogoWrapper>
          <img
            src="/images/logodotcom.svg"
            alt="logo"
            style={{ width: "100%" }}
          />
        </LogoWrapper>
        <Wrapper width="100%" maxWidth="500px">
          <Flex direction="column" align="center">
            <Typo.H6 margin="0 0 40px 0" color={"navy"}>
              ยืนยันการสมัครสมาชิก
            </Typo.H6>
            {expireIn ? (
              <div>
                <Typo.Body2 textAlign="center" color="navy">
                  กรุณากรอกรหัสยืนยันของท่าน
                </Typo.Body2>
                <Typo.Body2 textAlign="center" color="navy" margin="5px 0">
                  รหัสอ้างอิง {otpRef}
                </Typo.Body2>
                <Typo.Body2
                  textAlign="center"
                  color="navy"
                  margin="0 0  40px 0"
                >
                  หมดอายุภายใน <CountDown second={expireIn} />
                </Typo.Body2>
                <InputBox
                  digits={digits}
                  setDigits={setDigits}
                  action={submit}
                  gap="15px"
                />
                <Button
                  disabled={R.isEmpty(digits[5])}
                  onClick={submit}
                  margin="2em 0"
                  background="yellow"
                  hoverBg="transparent"
                  hoverColor="yellow"
                  hoverBorder="yellow"
                  width="100%"
                >
                  ยืนยัน
                </Button>
              </div>
            ) : (
              <Button
                background="yellow"
                hoverBg="transparent"
                hoverColor="yellow"
                hoverBorder="yellow"
                onClick={async () => {
                  const tempOtpRef = random();
                  const result = await callApi("users-requestOtp", {
                    otpRef: tempOtpRef,
                  });
                  setOtpRef(tempOtpRef);
                  setExpireIn(result.expireAt - new Date());
                  setDigits(["", "", "", "", "", ""]);
                }}
              >
                ขอรหัส OTP
              </Button>
            )}
          </Flex>
        </Wrapper>
      </LoginWrapper>
    </Container>
    // <Warpper>
    //   <br />
    //   <br />
    //   <ProgressBarNumber status={"2"} texts={["1", "2", "3"]} />
    //   <h2 style={{ color: "darkgoldenrod" }}>ยืนยันการสมัครสมาชิก</h2>
    //   {expireIn && (
    //     <h3 style={{ color: "lightgray" }}>
    //       กรุณากรอกรหัสยืนยันของท่าน
    //       <br />
    //       เลขที่อ้างอิง (<span>{otpRef || "กดขอรหัส"}</span>) <br />
    //       หมดอายุภายใน <CountDown second={expireIn} />
    //     </h3>
    //   )}
    //   <div
    //     style={{
    //       maxWidth: "300px",
    //       margin: "auto",
    //       padding: "20px",
    //     }}
    //   >
    //     {expireIn && (
    //       <div>
    //         <InputBox digits={digits} setDigits={setDigits} action={submit} />
    //       </div>
    //     )}
    //     <Flex direction="column" align="center">
    //       {!(expireIn > 0) && (
    //         <Btn
    //           className="button-style-primary small"
    //           text={otpRef ? "กดเพื่อขอ OTP ใหม่" : " กดเพื่อขอ OTP"}
    //           onClick={async () => {
    //             const tempOtpRef = random();
    //             const result = await callApi("users-requestOtp", {
    //               otpRef: tempOtpRef,
    //             });
    //             setOtpRef(tempOtpRef);
    //             setExpireIn(result.expireAt - new Date());
    //             setDigits(["", "", "", "", "", ""]);
    //           }}
    //           style={{ margin: "auto" }}
    //         />
    //       )}
    //       {expireIn && (
    //         <Btn
    //           className={
    //             R.isEmpty(digits[5])
    //               ? "disabled small"
    //               : "button-style-primary small"
    //           }
    //           text={"ยืนยัน"}
    //           disabled={R.isEmpty(digits[5])}
    //           onClick={submit}
    //           margin="2em 0"
    //         />
    //       )}
    //     </Flex>
    //   </div>
    // </Warpper>
  );
};

const Warpper = styled.div`
  text-align: center;
  color: ligntgray;
`;
