import React from "react";
import { Grid } from "../components";
import { ShopItem } from "./";

export const ShopList = ({ blocks, ...rests }) => {
  return (
    <Grid
      columns={3}
      gap="10px"
      gapTablet="6px"
      gapMobile="3px"
      padding="0 10px"
    >
      {blocks
        .filter((x) => x.size)
        .map((block) => (
          <ShopItem key={block.number} {...block} {...rests} />
        ))}
    </Grid>
  );
};
