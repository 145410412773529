import React, { useEffect, useState, useCallback } from "react";
import Context from "../Context";
import { roundDateToDate, shortDate, shortTime } from "../util";
import { Label, Flex, Btn } from "../components";
import styled from "styled-components";
import { Container, Input } from "reactstrap";
import style from "../constants/style";
import themes from "../themes";
import { isEmpty } from "ramda";
import lottieTick from "../constants/lottie/tick.json";
import Lottie from "lottie-react";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

export const Complain = () => {
  const { callApi, go } = Context();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [complainBody, setComplainBody] = useState("");
  const [token, setToken] = useState("");

  const [isSubmitted, setSubmitted] = useState(false);
  const handleOnChange = (event) => {
    setComplainBody(event.target.value);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("yourAction");
    setToken(recaptchaToken);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleSubmit = async () => {
    if (window.confirm("ต้องการส่งเรื่องร้องเรียนเข้าระบบใช่หรือไม่")) {
      const result = await callApi("complains-addComplain", {
        body: complainBody,
        reCaptchaToken: token,
      });
      if (result) {
        setSubmitted(true);
      }
    }
  };
  return (
    <Container>
      <Flex direction="column" align="center">
        <ContentContainer>
          <Flex direction="column" align="center">
            {isSubmitted ? (
              <>
                <Lottie
                  animationData={lottieTick}
                  style={{ width: "150px" }}
                  loop={false}
                />
                <Label.H3 weight={500}>
                  ขอบคุณที่ช่วยเป็นส่วนนึงในการพัฒนาระบบของเราให้ดียิ่งขึ้น
                </Label.H3>
                <Label.H4>
                  เราจะพิจารณา ตรวจสอบ แก้ไข และติดต่อกลับโดยเร็วที่สุด
                </Label.H4>
                <Flex margin="1em 0">
                  <Btn
                    className="button-style-primary small"
                    text="กลับไปหน้าร้องเรียน"
                    margin="0 0.5em "
                    onClick={() => {
                      setSubmitted(false);
                      handleReCaptchaVerify();
                    }}
                  />

                  <Btn
                    className="button-style-primary-inline small"
                    text="กลับสู่หน้าหลัก"
                    margin="0 0.5em "
                    onClick={() => go("/")}
                  />
                </Flex>
              </>
            ) : (
              <>
                <Label.H4 weight={500}>ร้องเรียน</Label.H4>
                <FormContainer>
                  {/* <Label.H5>เรื่องที่ร้องเรียน</Label.H5>
                  <Input
                    id="header"
                    name="header"
                    placeholder="โปรดระบุเรื่องที่ร้องเรียน"
                    type="text"
                    onChange={(e) => handleOnChange(e)}
                  /> */}
                  <Label.H5 margin="1em 0 0 0">รายละเอียด</Label.H5>
                  <Input
                    id="body"
                    name="body"
                    placeholder="โปรดระบุรายละเอียด"
                    type="textarea"
                    onChange={(e) => handleOnChange(e)}
                    style={{ minHeight: "300px" }}
                  />
                  {/* <div>
                    <GoogleReCaptcha
                      onVerify={(token) => {
                        setToken(token);
                      }}
                    />
                  </div> */}

                  <Btn
                    className={
                      complainBody
                        ? "button-style-primary small"
                        : "disabled small"
                    }
                    isDisable={isEmpty(complainBody)}
                    text="ยืนยัน"
                    margin="1em 0 0 0"
                    onClick={() => handleSubmit()}
                  />
                </FormContainer>
              </>
            )}
          </Flex>
        </ContentContainer>
      </Flex>
    </Container>
  );
};

const FormContainer = styled.div`
  width: 100%;
  max-width: 100%;

  border-radius: 7px;
  padding: 20px;
`;

const ContentContainer = styled.div`
  margin: 3em 0;
  background-color: ${themes.colors.white};
  width: 100%;
  max-width: 800px;

  border-radius: 7px;
  padding: 20px;
`;
