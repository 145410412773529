/* eslint-disable import/no-anonymous-default-export */
export default {
  blue: "#3E99EE",
  bgBlue: "#ECF7FE",
  gradient2: "linear-gradient(90deg, #002C7B 0%, #2065C4 100%)",
  newGrReverse: "linear-gradient(90deg, #1788D8 0%, #012E7D 100%)",

  newGr: "linear-gradient(90deg, #012E7D 0%, #1788D8 100%)",
  gray: "#6A6A6A",
  gray2: "#DDDDDD",
  gray3: "#C4C4C4",
  gray4: "#909090",
  gray5: "#6A6A6A",
  greenLine: "#06C755",
  green: "#1EA94C",
  fadeGreen: "#F1FFF5",
  white: "#ffff",
  lightGreen: "#DDF2E4",
  lightNavy: "#F2F6FF",
  white80: "#FFFFFFCC",
  white50: "#FFFFFF80",
  navy: "#003A9E",
  lightNavy2: "#CDD8F0",
  red: "#D60B0B",
  lightRed: "#FFE1E1",
  orange: "#F16804",
  textBlack: "#333333",
  yellow: "#FFCD00",
  lightYellow: "#FBF9DF",
  transparent: "transparent",

  //old version
  primary: "rgba(245,194,64,1)",
  primaryFade: "rgb(220,190,105,0.4)",
  primaryGradient:
    "linear-gradient(90deg, rgba(233,171,26,1) 0%, rgba(254,241,126,1) 100%)",
  primaryGradient2:
    "linear-gradient(90deg, rgba(233,171,26,1) 0%, rgba(254,241,126,1) 50%, rgba(233,171,26,1) 100%)",
  secondaryDark: "rgba(5,39,126,1)",
  secondary: "rgba(40,60,150,1)",
  secondaryGradient:
    "linear-gradient(90deg,rgba(20,30,75,1) 0%,rgba(5,39,126,1)  60%,rgba(40,60,150,1) 100%)",
  secondaryGradient2:
    "linear-gradient(90deg,rgba(5,39,126,1) 0%,rgba(7,69,172,1) 100%)",

  bgMild: "rgb(242,247,255)",
  darkGray: "rgb(109,119,141)",

  black: "rgb(16,16,16)",
  lightBlack: "rgb(16,16,16,0.8)",
  couponGreen: "rgb(63,156,64)",

  disable: "rgb(255,255,255,0.3)",
  border: "rgb(222,226,230)",

  brightBlue: "rgb(40,202,239)",
  warningOrange: "rgb(255,176,0)",
  dangerRed: "rgb(219,9,52)",
  oldGrean: "rgb(0,134,84)",

  fadeBlue: "#90CCF4",
  fadeRed: "#F78888",
  fadeOrange: "#F3D250",

  lightBlue: "rgb(31,74,195)",
  mainDark1: "#79a0ab",
  mainDark2: "#5b7197",
  mainDark3: "#2A4C84",
  mainLight1: "#e3a494",
  mainLight2: "#eecfc2",
  mainLight3: "#e8e3dd",
  lightGray: "#eeeeee",
  darktGray: "#999999",
  textDark: "white",
  textWhite: "black",
  success: "#019035",
  danger: "darkred",
  darkBlue: "rgb(24,39,106)",
  goldText: "rgb(219, 193, 103)",
  brightGoldText: "rgb(222,170,90)",
  golden: `rgba(245,194,64,1)`,
  golden8: `rgba(245,194,64,.8)`,
};
