import React, { useState } from "react";
import styled from "styled-components";
import style from "../constants/style";
import { Modal } from "reactstrap";
export const ModalBase = ({
  className,
  hide,
  noBackground,
  children,
  isFullWidth,
}) => {
  return (
    // <Modal isOpen={true} onClick={hide}>
    <ModalBackGround onClick={hide}>
      <ModalHolder className={className}>
        {noBackground ? (
          children
        ) : (
          <ModalDataHolder
            onClick={(e) => e.stopPropagation()} // stop all early click event
            isFullWidth={isFullWidth}
          >
            <MockModalData>{children}</MockModalData>
          </ModalDataHolder>
        )}
      </ModalHolder>
    </ModalBackGround>

    // </Modal>
  );
};

const ModalBackGround = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.7);
  top: 0;

  left: 0;

  width: 100%;
  height: 100%;
  z-index: 1000;
`;
const ModalHolder = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  ${"" /* margin: auto; */}
  &.limit-width-250 {
    max-width: 550px;
    z-index: 9999;
  }
`;
const ModalDataHolder = styled.div`
  border: 1px solid ${style.colors.brightGoldText};
  display: ${(props) => (props.isFullWidth ? "block" : "inline-block")};
  box-shadow: 0 0 200px 10px black;
  padding: 0;
  max-width: calc(1000px - 10%);
  max-height: calc(100vh - 100px);
  margin: auto;
  background: rgb(30, 45, 100);
  position: relative;
  ${style.css.mdRad}
`;
const MockModalData = styled.div`
  position: relative;
  width: 100%;
  max-height: calc(100vh - 100px);
  display: block;
  overflow: scroll;
`;
