import React from "react";
import styled from "styled-components";
import { ModalBase } from "./ModalBase";
import Context from "../Context";
import { Container } from "reactstrap";
import { Button, Flex, Typo, Div } from "./index";

export const ModalSpecial = () => {
  const { hideModal, setModalSpecial, go } = Context();
  return (
    <ModalBase hide={hideModal} isFullWidth={true} noBackground={true}>
      <Container>
        <Flex direction="column" align="center">
          <ImageWrapper>
            <Typo.H1
              onClick={() => setModalSpecial(false)}
              textAlign="right"
              color="red"
            >
              x
            </Typo.H1>

            <img
              src="/images/ประกาศ.jpg"
              alt="ประกาศ"
              style={{ width: "100%" }}
            />
          </ImageWrapper>
        </Flex>

        <Flex margin="2em 0" width="100%" justify="center">
          <Button
            background="yellow"
            margin="0 1em"
            onClick={() => {
              go("/profile");
              setModalSpecial(false);
            }}
          >
            ตั้งค่าบัญชี
          </Button>

          <Button
            background="red"
            margin="0 1em"
            onClick={() => setModalSpecial(false)}
          >
            ปิด
          </Button>
        </Flex>
      </Container>
    </ModalBase>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  max-height: 80vh;
  border-radius: 0.375em;
`;
