import React, { useEffect, useState, useContext } from "react";
import Context from "../Context";
import { Container, OrderContainer, Loading } from "../components";
import { useParams } from "react-router-dom";

export const OrderDetail = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState({});
  const { callApi, go } = Context();

  useEffect(() => {
    (async () => {
      if (!orderId) {
        go("/");
      }
      const result = await callApi("orders-getOrder", { orderId });
      if (result.status) {
        setOrder(result.orderData);
      } else {
        go("/");
      }
    })();
  }, [orderId]);

  if (!order.id) {
    return (
      <div className="w-full h-dvh flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <Container>
      <OrderContainer order={order} go={go} showImage={true} />
    </Container>
  );
};
