import React, { useEffect, useState, useCallback } from "react";
import { ModalBase } from "./";
import Context from "../Context";
import style from "../constants/style";
import styled from "styled-components";
import { Btn, LineButton } from "./";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

export const ModalLogin = () => {
  const {
    hideModal,
    callApi,
    setProfileState,
    go,
    db,
    loginOldUser,
    showModalLoading,
    toast,
  } = Context();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("yourAction");
    setToken(recaptchaToken);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const login = async () => {
    if (username === "" || password === "") {
      return toast("เบอร์โทรศัพท์และรหัสผ่าน ไม่สามารถเว้นว่างได้");
    }
    const { success, data } = await callApi("users-login", {
      username,
      password,
      reCaptchaToken: token,
      userAgent: window.navigator.userAgent || "",
    });
    if (success) {
      localStorage.setItem("accessToken", data.accessToken);
      setProfileState(data.userData);
      window.location.pathname.includes("/search") && window.location.reload();
      hideModal();
    }
  };
  return (
    <ModalBase className="limit-width-250" hide={hideModal} isFullWidth={true}>
      <div style={{ maxWidth: "350px", margin: "auto", padding: "2em 0" }}>
        <img
          src="/images/logo_single.png"
          style={{ marginTop: "20px", width: "50%", maxWidth: "100px" }}
          alt="logo"
        />
        <h2 style={{ color: "darkgoldenrod" }}>หงษ์ทอง.com</h2>
        <div
          style={{ textAlign: "center", margin: "auto", marginBottom: "20px" }}
        >
          <br />
          <Input
            id="username"
            placeholder="เบอร์โทรศัพท์"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <Input
            id="password"
            placeholder="รหัสผ่าน"
            type={isShowPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            id="showPassword"
            type={"checkbox"}
            style={{
              textAlign: "left",
              width: "20px",
              height: "20px",
              marginRight: "5px",
              verticalAlign: "sub",
              borderRadius: "5px",
            }}
            onChange={() => setIsShowPassword((x) => !x)}
          />
          <label
            htmlFor="showPassword"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "left",
              color: "lightgray",
            }}
          >
            แสดงรหัสผ่าน
          </label>
          <div
            style={{
              marginBottom: "10px",
              textAlign: "right",
              color: "goldenrod",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              go("/register-reset-password");
              hideModal();
            }}
          >
            ลืมรหัสผ่าน?
          </div>
          <Btn
            className="button-style-primary"
            text={"เข้าสู่ระบบ"}
            onClick={login}
            bgColor={style.css.goldenPremium}
            textColor={"black"}
            customStyle={{
              width: "100%",
              justifyContent: "center",
              margin: "0",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
          />
          <LineButton text={"เข้าสู่ระบบ"} />
          <div
            style={{ margin: "10px", textAlign: "center", color: "lightgray" }}
          >
            <nobr>
              ฉันไม่ได้เป็นสมาชิก
              <wbr />
              หงษ์ทอง?
            </nobr>
          </div>
          <Btn
            text={"สมัครสมาชิกใหม่"}
            onClick={() => {
              go("/register");
              hideModal();
            }}
            className="button-style-primary-inline"
            customStyle={{
              width: "100%",
              margin: "0",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
    </ModalBase>
  );
};

const Input = styled.input`
  width: calc(100% - 14px);
  font-size: 20px;
  ${style.css.mdRad}
  padding: 5px;
  margin-bottom: 10px;
`;
