import React, { useState, useEffect } from "react";
import Context from "../../Context";
import {
  ContentContainer,
  Container,
  Typo,
  Flex,
  Button,
  Grid,
  Div,
  Breadcrumb,
} from "../../components";
import { monthTh } from "../../util";
import { IoIosArrowForward } from "react-icons/io";

export const Shop = () => {
  const { callApi, configs, profileState, setProfileState, go } = Context();
  const [allProduct, setAllProducts] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [filterKey, setFilterKey] = useState("richItem");
  useEffect(() => {
    (async () => {
      const allProduct = await callApi("products-getAllProducts");
      const availableProduct = allProduct.filter(
        (product) => product.isShowInMarket
      );

      const sorted = availableProduct.sort((a, b) => b.price - a.price);
      setAllProducts(sorted);

      const filterd = availableProduct.filter(
        (product) => product.type === "richItem"
      );
      setFilteredProduct(filterd);
    })();
  }, []);

  const filteringProduct = (key) => {
    setFilterKey(key);
    const filterd = allProduct
      .filter((product) => product.type === key)
      .sort((a, b) => b.price - a.price);
    setFilteredProduct(filterd);
  };

  const filterArr = [
    { name: "วัตถุรวยไว", key: "richItem" },
    { name: "พรีเมียม", key: "premium" },
    { name: "ทั่วไป", key: "regular" },
    { name: "Art Toy", key: "artToy" },
  ];
  return (
    <Container>
      <Breadcrumb
        data={[{ path: "/", name: "หน้าแรก" }, { name: "ร้านค้า" }]}
      />
      <Typo.H6 color="navy" textAlign="center">
        ร้านค้า
      </Typo.H6>

      <ContentContainer
        bgcolor="gradient2"
        minHeight="10px"
        padding="16px 24px"
      >
        <Flex justify="space-between" align="center">
          <Flex direction="column" align="center" gap="8px">
            <Typo.Body2 color="white">คะแนนสะสมของคุณ</Typo.Body2>
            <Typo.Body5>
              ใช้ได้ถึงสิ้นเดือน {monthTh[profileState.pointExpireMonth || 12]}
            </Typo.Body5>
          </Flex>
          <Flex align="center" gap="10px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1593 0.443922C2.8527 1.80168 -3.27818 14.4552 2.65518 24.1873C6.88822 31.1305 15.9265 33.6553 23.16 29.9151C32.5898 25.0394 34.5623 12.4299 27.0676 4.9352C23.71 1.5776 18.8373 -0.117838 14.1593 0.443922ZM17.1762 7.87264C23.7081 8.62368 24.4731 17.6089 18.1593 19.4201C17.6254 19.5732 17.5036 19.5828 15.7686 19.6087L13.9405 19.636V21.9116V24.1873H11.7217H9.50302V15.9998V7.81224L13.0812 7.81272C15.0491 7.81296 16.8919 7.83992 17.1762 7.87264ZM13.9405 13.7112V15.7498H15.1258C17.1836 15.7498 17.9047 15.2222 17.9023 13.7185C17.9 12.2583 17.2163 11.7531 15.1749 11.703L13.9405 11.6726V13.7112Z"
                fill="#FFCD00"
              />
            </svg>
            <Typo.H2 color="yellow" margin="5px 0 0 0">
              {new Intl.NumberFormat().format(profileState.buyPoint || 0)}
            </Typo.H2>
          </Flex>
        </Flex>
      </ContentContainer>
      <div className="flex justify-between my-8">
        <Typo.Body2 color="textBlack">สินค้าทั้งหมด</Typo.Body2>
        <div className="flex">
          <Typo.Link1
            className="flex gap-2"
            color="navy"
            cursor="pointer"
            onClick={() => go("/shop/history")}
          >
            ประวัติการสั่งซื้อ
          </Typo.Link1>
          <IoIosArrowForward />
        </div>
      </div>

      <Grid
        className="fix-height mb-11"
        columns={4}
        columnsTablet={2}
        columnsIpad={2}
        columnsMobile={2}
        background="white"
        radius="10px"
        padding="10px"
      >
        {filterArr.map((filter) => {
          return (
            <Button
              grow={1}
              background={filterKey === filter.key && "yellow"}
              hoverBg={filterKey !== filter.key && "white"}
              onClick={() => filteringProduct(filter.key)}
              radius="10px"
              width="100%"
            >
              <Typo.H6
                color="navy"
                weight={filterKey === filter.key ? "bold" : "regular"}
              >
                {filter.name}
              </Typo.H6>
            </Button>
          );
        })}
      </Grid>
      <Grid
        className="fix-height"
        columns={4}
        columnsTablet={2}
        columnsIpad={2}
        columnsMobile={2}
        width="100%"
        justify="center"
        align="flex-start"
        gap="15px"
      >
        {filteredProduct
          .filter((coup) => coup.quantity > 0 && coup.isHotDeal)
          .sort((a, b) => a.point - b.point)
          .map((product) => {
            return (
              <ProductContainer key={product.id} go={go} product={product} />
            );
          })}
        {filteredProduct
          .filter((coup) => coup.quantity > 0 && !coup.isHotDeal)
          .sort((a, b) => a.price - b.price)
          .map((product) => {
            return (
              <GiftshopCard key={product.id} go={go} productData={product} />
            );
          })}
        {filteredProduct
          .filter((coup) => !coup.quantity)
          .sort((a, b) => a.price - b.price)
          .map((product) => {
            return (
              <GiftshopCard key={product.id} go={go} productData={product} />
            );
          })}
      </Grid>
    </Container>
  );
};

const ProductContainer = ({ go, product }) => {
  return (
    <Div height="100%">
      {product.isHotDeal && (
        <Div
          position="absolute"
          top="0"
          left="0"
          transform="translate(-30%, -30%)"
          width="70px"
          zIndex={1}
        >
          <img
            src={"/images/hotdeal.png"}
            alt="hotdeal"
            style={{ width: "100%" }}
          />
        </Div>
      )}
      <GiftshopCard go={go} productData={product} />
    </Div>
  );
};

const GiftshopCard = ({ productData, go }) => {
  const [onHover, setHover] = useState(false);
  return (
    <Div
      padding="15px 0"
      cursor={productData.quantity || "no-drop"}
      height="100%"
      width="100%"
      // onMouseOver={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
      // onClick={() =>
      //   productData.quantity > 0 && go(`/giftshop/${productData.id}`)
      // }
    >
      <Flex
        direction="column"
        justify="space-between"
        maxWidth="280px"
        height="100%"
        margin="0 auto"
      >
        <Div>
          <Flex justify="center">
            <img
              src={productData.image}
              style={{
                maxWidth: "280px",
                width: "100%",
                filter: productData.quantity ? "none" : "grayscale(1)",
                borderRadius: "12px",
              }}
              alt={productData.id}
            />
            {!productData.quantity && (
              <Div
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                <img src={"/images/soldout.png"} alt="coupon" />
              </Div>
            )}
          </Flex>

          <Typo.Body3 color="textBlack" margin="10px 0">
            {productData.name}
          </Typo.Body3>
          <Typo.Body5 color="navy">
            ราคา {new Intl.NumberFormat().format(productData.price)} บาท
          </Typo.Body5>
        </Div>

        <Div>
          <Typo.Body6 color="textBlack" margin="10px 0">
            {productData.detail || ""}
          </Typo.Body6>
          <Button
            width="100%"
            background="yellow"
            hoverColor="yellow"
            hoverBg="transparent"
            hoverBorder="yellow"
            disabled={!productData.quantity}
            onClick={() =>
              productData.quantity > 0 && go(`/shop/product/${productData.id}`)
            }
          >
            ซื้อเลย
          </Button>
        </Div>
      </Flex>
    </Div>
  );
};
