"use client";

import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "../../@/lib/utils";

const Progress = ({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    // ref={ref}
    className={cn(
      "relative h-2 w-full overflow-hidden rounded-full bg-gray-300",
      className
    )}
    {...props}
  >
    <div className="w-4 h-4 bg-black rounded-full" />
    <ProgressPrimitive.Indicator
      className="h-full w-full flex-1 bg-primary transition-all"
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
