import styled from "styled-components";
import style from "../constants/style";

const SingleDropdownOptionSc = styled.div`
  background-color: white;
  border: solid black 1px;
  padding: 10px;
  border-collapse: collapse;
  margin: -1px 0 0 -1px;
  :hover {
    background-color: ${style.colors.mainDark1};
  }
`;

export default SingleDropdownOptionSc;
