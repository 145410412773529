import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import Context from "../Context";
import themes from "../themes";
import {
  Wrapper,
  Flex,
  Container,
  Typo,
  Input,
  Button,
  Div,
  ContentContainer,
} from "../components";
import { devices, validate } from "../constants";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FiX } from "react-icons/fi";
import { FcCheckmark } from "react-icons/fc";
import { useParams } from "react-router-dom";

export const Register = () => {
  const { callApi, setProfileState, profileState, go } = Context();
  const { refId } = useParams();
  const [showModal, setModal] = useState({
    policy: false,
    pdpa: false,
  });
  const [registerData, setRegisterData] = useState({
    phone: "",
    firstName: "",
    lastName: "",
    nationality: "TH",
    password: "",
    confirm: "",
    accept: false,
    acceptPdpa: false,
  });
  let [searchParams, setSearchParams] = useSearchParams();

  const validRegist =
    registerData.phone &&
    registerData.firstName &&
    registerData.lastName &&
    registerData.password &&
    registerData.confirm === registerData.password &&
    registerData.accept &&
    registerData.acceptPdpa;

  useEffect(() => {
    if (profileState.referrer === refId) {
      sessionStorage.setItem("referrer", refId);
      go("/search");
    }
  }, []);

  const handleLogin = async () => {
    const lineId = searchParams.get("lineId");
    const adsKey = searchParams.get("ads");
    if (validRegist) {
      const newUser = {
        ...registerData,
        referrer: refId || false,
        lineId: lineId || "",
        adsKey: adsKey || "",
      };

      const result = await callApi("users-createUser", { newUser });
      if (!result) {
        return;
      }
      setProfileState(result.newUser);
      localStorage.setItem("accessToken", result.accessToken);
      go("/register-otp");
    }
  };

  return (
    <Container>
      <Div
        className="btn-login-close"
        position="absolute"
        top="5%"
        right="5%"
        onClick={() => go("/")}
        zIndex={1}
      >
        <FiX
          style={{
            fontSize: "40px",
            color: themes.colors.white,
            cursor: "pointer",
          }}
        />
      </Div>
      <ModalPolicy
        showModal={showModal}
        setModal={setModal}
        setRegisterData={setRegisterData}
        registerData={registerData}
      />
      <ModalPdpa
        showModal={showModal}
        setModal={setModal}
        setRegisterData={setRegisterData}
        registerData={registerData}
      />

      <LoginWrapper>
        <Absolute>
          <img
            src="/images/bg-hong.svg"
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Absolute>
        <LogoWrapper>
          <img
            src="/images/logodotcom.svg"
            alt="logo"
            style={{ width: "100%" }}
          />
        </LogoWrapper>
        <Wrapper width="100%" maxWidth="500px">
          <Flex direction="column" align="center">
            <Typo.H6 margin="0 0 40px 0" color={"navy"}>
              สมัครสมาชิก
            </Typo.H6>
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Typo.Body6 color={"textBlack"}>เบอร์โทรศัพท์</Typo.Body6>
              <Input
                type="text"
                placeholder="0xx-xxx-xxxx"
                value={registerData.phone}
                onChange={(e) => {
                  if (validate.digitOnly.test(e.target.value)) {
                    setRegisterData((prevState) => ({
                      ...prevState,
                      phone: e.target.value,
                    }));
                  }
                }}
              />
            </div>

            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Typo.Body6 color={"textBlack"}>ชื่อ</Typo.Body6>
              <Input
                type="text"
                placeholder="ชื่อ"
                value={registerData.firstName}
                onChange={(e) => {
                  setRegisterData((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }));
                }}
              />
            </div>

            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Typo.Body6 color={"textBlack"}>นามสกุล</Typo.Body6>
              <Input
                type="text"
                placeholder="นามสกุล"
                value={registerData.lastName}
                onChange={(e) => {
                  setRegisterData((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }));
                }}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Typo.Body6 color={"textBlack"}>สัญชาติ</Typo.Body6>
              <Input
                type="select"
                placeholder="สัญชาติ"
                value={registerData.nationality}
                onChange={(e) => {
                  setRegisterData((prevState) => ({
                    ...prevState,
                    nationality: e.target.value,
                  }));
                }}
              >
                <option value="TH">ไทย</option>
                <option value="LAO">ลาว</option>
              </Input>
            </div>

            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Typo.Body6 color={"textBlack"}>รหัสผ่าน</Typo.Body6>
              <Input.Password
                placeholder="กรุณากรอก"
                onChange={(e) =>
                  setRegisterData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
              />
            </div>
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <Typo.Body6 color={"textBlack"}>ยืนยันรหัสผ่าน</Typo.Body6>
              <Input.Password
                placeholder="กรุณากรอก"
                onChange={(e) =>
                  setRegisterData((prevState) => ({
                    ...prevState,
                    confirm: e.target.value,
                  }))
                }
              />
            </div>
            <Policy accept={registerData.accept} setModal={setModal} />
            <Pdpa accept={registerData.acceptPdpa} setModal={setModal} />
            <Button
              disabled={!validRegist}
              width="100%"
              weight="bold"
              background={"gradient2"}
              onClick={() => handleLogin()}
            >
              สมัครสมาชิก
            </Button>
          </Flex>
        </Wrapper>
      </LoginWrapper>
    </Container>
  );
};

const Pdpa = ({ accept, setModal }) => {
  return (
    <Flex
      width="100%"
      justify="flex-start"
      align="flex-start"
      margin="1em 0"
      gap="15px"
    >
      <Div>
        <input
          id="accept"
          type={"checkbox"}
          checked={accept}
          style={{
            textAlign: "left",
            width: "15px",
            height: "15px",
            verticalAlign: "sub",
            borderRadius: "5px",

            cursor: "pointer",
          }}
          onChange={() => {
            setModal((prevState) => ({
              ...prevState,
              pdpa: !prevState.pdpa,
            }));
          }}
        />
      </Div>

      <Flex
        onClick={() => {
          setModal((prevState) => ({
            ...prevState,
            pdpa: !prevState.pdpa,
          }));
        }}
      >
        <h6
          style={{
            margin: "0",
            fontWeight: "700",
            color: "rgb(50, 101, 150)",
            cursor: "pointer",
            marginRight: "20px",
          }}
        >
          ข้อกำหนดและเงื่อนไขยินยอมให้มีการเก็บใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
          ของเจ้าของข้อมูลส่วนบุคคล (PDPA)
        </h6>
      </Flex>
    </Flex>
  );
};
const Policy = ({ accept, setModal }) => {
  return (
    <Flex
      width="100%"
      justify="flex-start"
      align="center"
      margin="1em 0"
      gap="15px"
    >
      <Div>
        <input
          id="accept"
          type={"checkbox"}
          checked={accept}
          style={{
            textAlign: "left",
            width: "15px",
            height: "15px",
            verticalAlign: "sub",
            borderRadius: "5px",

            cursor: "pointer",
          }}
          onChange={() => {
            setModal((prevState) => ({
              ...prevState,
              policy: !prevState.policy,
            }));
          }}
        />
      </Div>

      <Flex
        onClick={() => {
          setModal((prevState) => ({
            ...prevState,
            policy: !prevState.policy,
          }));
        }}
      >
        <h6
          style={{
            margin: "0",
            fontWeight: "700",
            color: "rgb(50, 101, 150)",
            cursor: "pointer",
            marginRight: "20px",
          }}
        >
          ข้อตกลงและเงื่อนไขของแอปหงษ์ทอง
        </h6>
      </Flex>
    </Flex>
  );
};

const ModalPdpa = ({ showModal, setModal, setRegisterData, registerData }) => {
  const [disable, setDisable] = useState(!registerData.accept);

  return (
    <Modal
      centered
      isOpen={showModal.pdpa}
      toggle={() => {
        setDisable(true);
        setRegisterData((prevState) => ({ ...prevState, acceptPdpa: false }));
        setModal((prevState) => ({
          ...prevState,
          pdpa: false,
        }));
      }}
    >
      <ContentContainer padding="15px">
        <ModalHeader>
          <Typo.Body2 color={"navy"}>
            ข้อกำหนดและเงื่อนไขยินยอมให้มีการเก็บใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            ของเจ้าของข้อมูลส่วนบุคคล (PDPA)
          </Typo.Body2>
        </ModalHeader>
        <ModalBody>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง บริษัท หงษ์ทอง
            ลอตเตอรี่ออนไลน์ จำกัด กับผู้ซื้อหรือผู้รับบริการ
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            1. "ผู้ซื้อ" หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
            ตลอดจนผู้มีส่วนเกี่ยวข้อง
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            2. "บริการ"
            หมายถึงบริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            3. "ผู้ให้บริการ" หมายถึง บริษัท หงษ์ทองลอตเตอรี่ออนไลน์ จำกัด
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            4. "บริษัทฯ" หมายถึง บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            5. "สลากกินแบ่งรัฐบาล" หมายถึง
            สลากกันแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่ง รัฐบาลทุกประเภท
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            6. "แพลตฟอร์ม" หมายถึง "https://www.hongthongofficial.com" หรือ "
            https://shop.hongthongofficial.com " หรือ "แอปพลิเคชันหงษ์ทอง.COM"
            ซึ่งเป็นบริษัทฯ ผู้ให้บริการจำหน่าย
            รับจัดเก็บรับฝากรับขึ้นเงินสลากกินแบ่งรัฐบาลตลอด จนประกอบกิจการอื่น
            ๆ ตามวัตถุประสงค์ของบริษัทฯ
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            7. ผู้ซื้อยันยอมให้บริษัทฯ จัดเก็บข้อมูลส่วนบุคคล (Personal Data)
            ตามพระราชบัญญัติ คุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 PDPA (Personal Data
            Protection Act B.E. 2562 (2019) เพื่อมีไว้ใช้และเปิดเผย
            ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล หรือชื่อเล่น /
            เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
            เลขใบอนุญาตขับขี่, เลขประจำตัวผู้เสียภาษี, เลขบัญชีรนาคาร,
            เลขบัตรเครดิต (การเก็บเป็นภาพสำเนาบัตร ประชาชนหรือสำเนาบัตรอื่น
            ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคล
            ได้โดยตัวมันเอง จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่,
            อีเมล์,เลขโทรศัพท์ / ข้อมูลอุปกรณ์ หรือเครื่องมือ เช่น IP address,
            MAC address, Cookie ID / ข้อมูลระบุทรัพย์สินของ บุคคลเช่น
            ทะเบียนรถยนต์, โฉนดที่ดิน /
            ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
            วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก, ส่วนสูง,
            ข้อมูลตำแหน่งที่อยู่ (location), ข้อมูลการแพทย์, ข้อมูลการศึกษา,
            ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน / ข้อมูลหมายเลข
            อ้างอิงที่เก็บไว้ในไมโครฟิล์มแม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบด้ชนีข้อมูลอีกระบบหนึ่งก็จะสามารดระบุไปถึงตัวบุคคลได้
            /ข้อมูลการประเมินผลการทำงานหรือ
            ความเห็นของนายจ้างต่อการทำงานของลูกจ้าง / ข้อมูลบันทึกต่าง
            ๆที่ใช้ติดตามตรวจสอบ กิจกรรมต่าง ๆ ของบุคคล เช่น log file
            /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคล อื่นในอินเทอร์เน็ต
            ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive Personal Data)
            ได้แก่ ข้อมูล เชื้อชาติ, เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
          </Typo.Body3>

          <Flex
            justify="space-between"
            align="center"
            onClick={() => {
              setDisable(false);
            }}
          >
            <Typo.Body4 cursor="pointer" color="textBlack">
              ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม ใช้
              และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
              การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่นๆ
              ของผู้ให้บริการแก่ข้าพเจ้า รวมถึงวัตถุประสงค์อื่นๆ
              ตามที่ผู้ให้บริการเห็นสมควร
            </Typo.Body4>
          </Flex>

          <Button
            disabled={!disable}
            size="18px"
            weight="bold"
            color="green"
            background="lightGreen"
            hoverBorder="green"
            hoverBg="white"
            width="100%"
            margin="25px 0 0 0"
            grow={1}
            gap="10px"
            onClick={() => {
              setDisable(false);
            }}
          >
            <FcCheckmark style={{ marginBottom: "5px" }} />{" "}
            ข้าพเจ้ารับทราบและยินยอม
          </Button>
        </ModalBody>
        <Flex padding="20px 20px" align="center">
          <Flex justify="center" grow={1}>
            <Typo.Body2
              color="navy"
              onClick={() => {
                setDisable(true);
                setModal((prevState) => ({
                  ...prevState,
                  pdpa: false,
                }));
              }}
              cursor="pointer"
            >
              ย้อนกลับ
            </Typo.Body2>
          </Flex>
          <Button
            disabled={disable}
            size="18px"
            weight="bold"
            color="green"
            background="lightGreen"
            hoverBorder="green"
            hoverBg="white"
            grow={1}
            onClick={() => {
              setRegisterData((prevState) => ({
                ...prevState,
                acceptPdpa: !prevState.acceptPdpa,
              }));

              setModal((prevState) => ({
                ...prevState,
                pdpa: false,
              }));
            }}
          >
            ยอมรับ
          </Button>
        </Flex>
      </ContentContainer>
    </Modal>
  );
};
const ModalPolicy = ({
  showModal,
  setModal,
  setRegisterData,
  registerData,
}) => {
  const [disable, setDisable] = useState(!registerData.accept);

  return (
    <Modal
      centered
      isOpen={showModal.policy}
      toggle={() => {
        setDisable(true);
        setRegisterData((prevState) => ({ ...prevState, accept: false }));
        setModal((prevState) => ({
          ...prevState,
          policy: false,
        }));
      }}
    >
      <ContentContainer padding="15px">
        <ModalHeader>
          <Typo.Body2 color={"navy"}>
            ข้อตกลงและเงื่อนไขของแอปหงษ์ทอง
          </Typo.Body2>
        </ModalHeader>
        <ModalBody>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ฉัน ในฐานะผู้ซื้อลอตเตอรี่
            ตกลงและทราบดีว่าหงษ์ทองไม่จำหน่ายลอตเตอรี่ในสถาน
            ศึกษาและให้กับบุคคลที่อายุต่ำกว่า 20 ปี
            ทางหงษ์ทองขอสงวนสิทธิ์ในการดำเนินคดีตาม กฎหมายสูงสุด
            ทั้งทางแพ่งและอาญากับผู้ที่มาซื้อลอตเตอรี่กับหงษ์ทอง
            โดยมีอายุต่ำกว่า 20 ปีบริบูรณ์หรือซื้อในสถานศึกษา
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ฉันตกลงยินยอม และยอมรับเงื่อนไขต่าง ๆ ที่ทางหงษ์ทองกำหนดขึ้น
            ทั้งในปัจจุบันและ หรือในอนาคตเพื่อตรวจสอบอายุผู้ซื้อลอตเตอรี่
            ว่าไม่ต่ำกว่า 20 ปีบริบูรณ์
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            หงษ์ทองขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อและฉันตกลงยอมจ่ายค่าปรับให้กับทางหงษ์ทองเป็นเงิน
            10,000 (หนึ่งหมื่นบาทถ้วน) ต่อการซื้อลอตเตอรี่ 1 ใบ ในกรณีที่หงษ์ทอง
            ตรวจพบว่า ฉันซื้อลอตเตอรี่ ในสถานศึกษา และหรืออายุฉันยังไม่ถึง 20
            ปีบริบูรณ์
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            หงษ์ทองขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อและฉันตกลงยอมจ่ายค่าปรับให้กับทางหงษ์ทองเป็นเงิน
            10,000 (หนึ่งหมื่นบาทถ้วน) ต่อการซื้อลอตเตอรี่ 1 ใบ ในกรณีที่หงษ์ทอง
            ตรวจพบว่า ฉันซื้อลอตเตอรี่ ในสถานศึกษา และหรืออายุฉันยังไม่ถึง 20
            ปีบริบูรณ์
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ฉันตกลงและทราบดีว่าทางหงษ์ทองขอสงวนสิทธิ์ในการไม่โอนเงินรางวัลในกรณีผู้ซื้อถูก
            รางวัล และจะไม่คืนเงินค่ซื้อลอตเตอรี่ให้กับผู้ซื้อลอตเตอรี่
            ในกรณีที่หงษ์ทองตรวจพบว่า ผู้ซื้อมีอายุต่ำกว่า 20 ปีบริบูรณ์
          </Typo.Body3>
          <Flex justify="center" align="flex-start" margin="1em 0" gap="15px">
            <input
              id="accept"
              type={"checkbox"}
              checked={!disable}
              style={{
                textAlign: "left",
                width: "20px",
                height: "20px",
                verticalAlign: "sub",
                borderRadius: "5px",

                cursor: "pointer",
              }}
              onChange={() => {
                setDisable(!disable);
              }}
            />

            <Flex
              justify="space-between"
              align="center"
              onClick={() => {
                setDisable(!disable);
              }}
            >
              <Typo.Body4 cursor="pointer" color="textBlack">
                ขอรับรองว่า ฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษา และ
                ปัจจุบันฉันมีอายุครบ 20 ปีบริบูรณ์
              </Typo.Body4>
            </Flex>
          </Flex>
        </ModalBody>
        <Flex padding="20px 20px" align="center">
          <Flex justify="center" grow={1}>
            <Typo.Body2
              color="navy"
              onClick={() => {
                setDisable(true);
                setModal((prevState) => ({
                  ...prevState,
                  policy: false,
                }));
              }}
              cursor="pointer"
            >
              ย้อนกลับ
            </Typo.Body2>
          </Flex>
          <Button
            disabled={disable}
            size="18px"
            weight="bold"
            color="green"
            background="lightGreen"
            hoverBorder="green"
            hoverBg="white"
            grow={1}
            onClick={() => {
              setRegisterData((prevState) => ({
                ...prevState,
                accept: !prevState.accept,
              }));

              setModal((prevState) => ({
                ...prevState,
                policy: false,
              }));
            }}
          >
            ยอมรับ
          </Button>
        </Flex>
      </ContentContainer>
    </Modal>
  );
};

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 50px 0;
  @media only screen and (${devices.tablet}) {
    flex-direction: column;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    flex-direction: column;
  }
`;
const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 338px;
  height: 92px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    max-width: 130;
    height: 30;
    margin-bottom: 30px;
    &.absolute {
      height: 500px;
      max-width: 300px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  //iphone size
  /* @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  } */
`;
const Absolute = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  left: 0;
  top: 100px;
  z-index: 0;
  @media only screen and (${devices.tablet}) {
    margin: 0;
    top: 0%;
    width: 100%;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  }
`;
