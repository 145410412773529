// import firebase from "firebase"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import constant from "../constants";

const firebaseConfig = constant.webConfigs.firebaseConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const database = getFirestore(app);
if (process.env.REACT_APP_EVIRONMENT === "local") {
  connectFirestoreEmulator(database, "localhost", 8080);
}
export const db = database;
