import React, { useEffect, useState } from "react";
import style from "../constants/style";
import styled from "styled-components";
import Context from "../Context";
import {
  Button,
  ProgressBar,
  Flex,
  Container,
  ContentContainer,
  Typo,
  Grid,
  Div,
} from "../components";
import themes from "../themes";
import { Collapse } from "reactstrap";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import { deliverChannels } from "../constants";
export const DeliverChannel = () => {
  const {
    callApi,
    setCart,
    profileState,
    showModalLoading,
    cart,
    configs,
    go,
  } = Context();
  const [deliverChannel, setDeliverChannel] = useState("");

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      const result = await callApi("carts-getCart", {
        guestId: localStorage.guestId || false,
      });

      if (!result || Object.keys(result.lotteryBlock).length === 0) {
        go("/");
      }
      setDeliverChannel(result.deliverChannel || "");
    })();
  }, [cart]);

  const onSubmit = async () => {
    if (!deliverChannel) return;
    if (!cart) return;

    if (deliverChannel !== deliverChannels.online) {
      await callApi("carts-ejectCoupon");
    }
    showModalLoading({ isShow: true });
    await callApi("carts-editSelf", {
      deliverChannel,
      guestId: localStorage.guestId,
    }).then(() => {
      setCart((prevState) => ({ ...prevState, deliverChannel }));
    });

    go("/cart");
  };
  const options = [
    {
      channel: deliverChannels.online,
      header: "ฝากลอตเตอรี่ที่ซื้อไว้กับหงษ์ทอง",
      body: `และให้หงษ์ทองโอนเงินรางวัลให้ก่อนขึ้นเงินรางวัลกับสำนักงานสลากฯ
มีค่าบริการใบละ ${configs.fee}.00 บาท
โดยลูกค้าจะได้รับบริการอย่างเต็มรูปแบบ`,
      details: [
        `ลูกค้าสามารถใช้บริการแผนกบริการลูกค้า Call Center โทร. 02-246-6666 (อัตโนมัติ 20 คู่สาย) ของทางหงษ์ทองได้ตลอด 24 ชม.`,
        `บริการแจ้งเตือนคำสั่งซื้อสำเร็จ : หงษ์ทองจะทำการแจ้งผลรางวัลให้ลูกค้าทราบทันที ผ่านช่องทาง SMS หรือ Line ตามที่ลูกค้าได้แจ้งความต้องการไว้ในระบบกับหงษ์ทอง`,
        `บริการแจ้งเตือนคำสั่งซื้อถูกยกเลิก : หงษ์ทองจะทำการแจ้งผลรางวัลให้ลูกค้าทราบทันที ผ่านช่องทาง SMS หรือ Line ตามที่ลูกค้าได้แจ้งความต้องการไว้ในระบบกับหงษ์ทอง`,
        `ลูกค้าสามารถใช้บริการ "แจ้งเตือนลูกค้า ถ้าเลขลอตเตอรี่ที่ต้องการเข้าสู่ระบบ" ได้`,
        `ลูกค้าสามารถชำระเงินค่าลอตเตอรี่ผ่านระบบ QR Code ได้`,
        `หงษ์ทองขอสงวนสิทธิ์ในการขายลอตเตอรี่ที่ท่านได้เลือกไว้ในระบบก่อนหน้านี้ ให้กับลูกค้าท่านอื่น หากท่านไม่ได้ทำการชำระเงิน ภายใน 15 นาที`,
        `ทุกการซื้อลอตเตอรี่ 1 ใบ จะได้คะแนนสะสม เพื่อแลกรับของรางวัลมากมาย`,
        `ลอตเตอรี่ทุกใบที่ลูกค้าทำการซื้อ ทางหงษ์ทองจะเก็บรักษาไว้ในห้องนิรภัย ที่มีระบบความปลอดภัยสูงสุด พร้อมรับประกันการสูญหาย`,
        `กรณีที่ลูกค้าถูกรางวัล หงษ์ทองจะทำการแจ้งผลรางวัลให้ลูกค้าทราบทันที ผ่านช่องทาง SMS หรือ Line ตามที่ลูกค้าได้แจ้งความต้องการไว้ในระบบกับหงษ์ทอง`,
        `เนื่องจากลอตเตอรี่ใบจริงที่ลูกค้าซื้อ ได้เก็บรักษาไว้กับทางหงษ์ทอง ดังนั้น ในกรณีที่ลูกค้าถูกรางวัล หงษ์ทองจะทำการโอนเงินรางวัลนั้นๆ ให้กับลูกค้าภายใน 24 ชม. โดยลูกค้าจะได้รับโอนรางวัลเต็มจำนวน ตามที่สำนักงานสลากฯ กำหนด (ไม่ถูกหักค่าอากร 0.5% ที่จะต้องถูกหักโดยสำนักงานสลากฯ หงษ์ทองจะเป็นผู้รับผิดชอบจ่าย 0.5% ) ตามหมายเลขบัญชีที่ลูกค้าได้แจ้งไว้ในระบบ ด้วยเงินสำรองของทางหงษ์ทอง หลังจากนั้นหงษ์ทองจึงจะจองคิว / นำลอตเตอรี่ใบจริงไปขึ้นรางวัลกับสำนักงานสลากฯในภายหลัง`,
        `หงษ์ทองขอสงวนสิทธิ์ในการเปลี่ยนแปลงค่าบริการ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า`,
      ],
      disabled: false,
    },
    {
      channel: deliverChannels.mail,
      header: `จัดส่งลอตเตอรี่ใบจริงด้วยบริการส่งด่วน`,
      body: `โดยไปรษณีย์ไทย (มีค่าส่ง) พร้อมบริการแจ้งหมายเลขพัสดุ ฯ`,
      details: [
        `ทางหงษ์ทองมีบริการจัดส่งด่วน (EMS)โดยไปรษณีย์ไทย มีค่าจัดส่งในอัตราใบแรก ${configs.shipping.prices.join(
          ".00 บาท ใบต่อไป "
        )}.00 บาท / ออเดอร์ โดยราคานี้รวมค่าบรรจุซอง ค่าซองจดหมาย (หรือกล่องพัสดุ แล้วแต่กรณี) ค่าดำเนินการจัดส่ง ค่า SMS แจ้งหมายเลขพัสดุ เพื่อติดตามสถานะการส่งพัสดุ ค่าบริการติดตามพัสดุให้ลูกค้า ในกรณีที่ลูกค้าไม่สะดวกติดตามด้วยตนเอง`,
        `ลูกค้าสามารถใช้บริการแผนกบริการลูกค้า Call Center โทร. 02-246-6666 (อัตโนมัติ 20 คู่สาย) ของทางหงษ์ทองได้ตลอด 24 ชม.`,
        `บริการแจ้งเตือนคำสั่งซื้อถูกยกเลิก : หงษ์ทองจะทำการแจ้งผลรางวัลให้ลูกค้าทราบทันที ผ่านช่องทาง SMS หรือ Line ตามที่ลูกค้าได้แจ้งความต้องการไว้ในระบบกับหงษ์ทอง`,
        `ลูกค้าสามารถใช้บริการ "แจ้งเตือนลูกค้า ถ้าเลขลอตเตอรี่ที่ต้องการเข้าสู่ระบบ" ได้`,
        `ลูกค้าสามารถชำระค่าลอตเตอรี่ผ่านระบบ QR Code ได้`,
        `หงษ์ทองขอสงวนสิทธิ์ในการขายลอตเตอรี่ที่ท่านได้เลือกไว้ในระบบก่อนหน้านี้ ให้กับลูกค้าท่านอื่น หากท่านไม่ได้ทำการชำระเงิน ภายใน 30 นาที`,
        `ในกรณีที่ลูกค้าถูกรางวัล ทางหงษ์ทองมีบริการแจ้งผลรางวัลให้ลูกค้าทราบ ผ่านช่องทาง SMS หรือ Line ตามที่ลูกค้าแจ้งความต้องการไว้ในระบบกับหงษ์ทอง`,
        `กรณีที่ลูกค้าถูกรางวัลใดๆ ลูกค้าต้องดำเนินการจองคิว และไปขึ้นเงินรางวัลกับสำนักงานสลากฯ ด้วยตัวเองเนื่องจากลอตเตอรี่ใบจริงมิได้อยู่กับทางหงษ์ทองแล้ว`,
        `ทางหงษ์ทองขอปฎิเสธความรับผิดชอบ ในกรณีลอตเตอรี่ที่ส่งออกไปเกิดการสูญหายหรือเสียหาย ทั้งนี้ลูกค้าสามารถเรียกร้องค่าเสียหายกับไปรษณีย์ไทย (EMS) ได้ ตามหลักเกณฑ์การรับประกันของไปรษณีย์ไทย`,
      ],
      disabled: profileState.bank?.currency === "LAK" || localStorage.guestId,
    },
    {
      channel: deliverChannels.offline,
      header: `ลอตเตอรี่ราคาขาย ${configs.price} บาท ไม่มีค่าบริการ`,
      body: `โดยลูกค้าต้องมารับลอตเตอรี่ใบจริงที่สำนักงานใหญ่หงษ์ทองเท่านั้น และจำกัดการซื้อไม่เกินท่านละ  ${
        configs.max80 || 5
      } ใบ`,
      details: [
        `รบกวนเตรียม บัตรประชาชนตัวจริงมาเพื่อยืนยันการรับลอตเตอรี่ใบจริงที่สำนักงานหงษ์ทอง`,
        `เนื่องจากไม่มีการบริการใดๆ จึงไม่เสียค่าบริการ`,
        `หงษ์ทองขอสงวนสิทธิ์ในการขายลอตเตอรี่ให้กับลูกค้าได้ไม่เกินท่านละ ${
          configs.max80 || 5
        } ใบ ในกรณีที่เลือกลอตเตอรี่ไว้เกิน ${
          configs.max80 || 5
        } ใบ ระบบจะทำการยกเลิกคำสั่งซื้อนั้นทันที`,
        `ลูกค้าต้องมารับลอตเตอรี่ใบจริงที่สำนักงานใหญ่หงษ์ทองเท่านั้น โดยนัดหมายล่วงหน้าในเวลาทำการ 10:00 - 16:00 ( หยุดเสาร์ อาทิตย์) ได้ที่แผนกบริการลูกค้า Call Center โทร. 02-246-6666 เพื่อชำระเงินค่าลอตเตอรี่`,
        `ลูกค้าต้องมาชำระเงินค่าลอตเตอรี่ด้วยเงินสดเท่านั้น หงษ์ทองไม่มีบริการชำระผ่านระบบ QR Code (เนื่องจากต้องเสียค่าธรรมเนียมให้กับธนาคารฯ)`,
        `ลูกค้าต้องตรวจผลรางวัลเอง ทางหงษ์ทองไม่มีบริการแจ้งผลรางวัลผ่าน SMS (เนื่องจากต้องเสียค่าบริการส่ง SMS) อีกทั้งไม่มีการจัดเก็บประวัติเลขลอตเตอรี่ที่ลูกค้าได้ซื้อไป`,
        `หงษ์ทองขอสงวนสิทธิ์ในการขายลอตเตอรี่ที่ลูกค้าได้เลือกไว้ในระบบก่อนหน้านี้ ให้กับลูกค้าท่านอื่น หากท่านไม่สามารถทำการชำระค่าลอตเตอรี่ได้ภายใน 30 นาที`,
        `เฉพาะช่วงโปรโมชั่นนี้เท่านั้นที่สามารถเลือกเลข / จองเลขได้ โดยลูกค้าต้องมา ยืนยันตัวตนกับและชำระเงินภายใน 30 นาที ที่ หงษ์ทองสำนักงานใหญ่ ( หากไม่มายืนยันตัวตนภายใน 30 นาที จะโดนยกเลิกทันที ) หลังจากหมดโปร ฯ นี้แล้ว ลูกค้าจะต้องมาเลือกเลขจากลอตเตอรี่ใบจริง ที่แผงขายลอตเตอรี่ สำนักงานใหญ่หงษ์ทองเท่านั้น`,
        `ลูกค้าจะไม่สามารถสะสมคะแนน เพื่อแลกรับของรางวัลฯ ได้`,
      ],
      disabled: profileState.bank?.currency === "LAK" || localStorage.guestId,
    },
  ];
  return (
    <Flex direction="column" width="100%">
      <ProgressBar status={1} />
      <Container>
        <ContentContainer
          padding="12px 16px"
          paddingTablet="12px 16px"
          paddingMobile="12px 16px"
          bgcolor="newGrReverse"
          minHeight="0"
          width="100%"
        >
          <Typo.H6 textAlign="center">ช่องทางการใช้บริการ</Typo.H6>
        </ContentContainer>
        <Typo.H6 color="textBlack" margin="24px 0">
          กรุณาเลือกช่องทาง
        </Typo.H6>
        {options.map((data, i) => (
          <ChannelSelector
            setDeliverChannel={setDeliverChannel}
            deliverChannel={deliverChannel}
            key={i}
            data={data}
            disabled={data.disabled}
          />
        ))}

        <Grid margin="24px 0" columns={2}>
          <Button
            onClick={() => go("/search")}
            color="navy"
            hoverBg="transparent"
            background="transparent"
          >
            <IoIosArrowBack
              style={{
                color: themes.colors.navy,
                marginRight: "7px",
              }}
            />
            ย้อนกลับ
          </Button>
          <Button
            disabled={!deliverChannel}
            width="100%"
            hoverBg="transparent"
            hoverBorder="navy"
            hoverColor="navy"
            background="gradient2"
            onClick={() => onSubmit()}
          >
            ดำเนินการต่อ{" "}
            <IoIosArrowForward
              style={{
                color: deliverChannel
                  ? themes.colors.yellow
                  : themes.colors.white,
                marginLeft: "7px",
              }}
            />
          </Button>
        </Grid>
      </Container>
    </Flex>
  );
};

const ChannelSelector = ({
  data,
  setDeliverChannel,
  deliverChannel,
  disabled,
}) => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  return (
    <ChannelSelectorStyle
      disabled={disabled}
      onClick={() => !disabled && setDeliverChannel(data.channel)}
      selected={deliverChannel === data.channel}
      padding={deliverChannel === data.channel ? "24px 10px" : "24px 80px"}
      paddingMobile="24px 20px"
      minHeight="0"
    >
      <Flex gap="15px">
        {deliverChannel === data.channel && (
          <img
            src="/images/selected.svg"
            alt="selected"
            style={{ maxWidth: "100px" }}
          />
        )}
        <Div>
          <Typo.Body2 color="textBlack">{data.header}</Typo.Body2>
          <Typo.Body5 margin="10px 0" color="textBlack">
            {data.body}
          </Typo.Body5>
          <Collapse isOpen={isShowDetail}>
            <ul>
              {data.details.map((detail, index) => (
                <li key={index}>
                  <Typo.Body5 margin="10px 0" color="textBlack">
                    {detail}
                  </Typo.Body5>
                </li>
              ))}
            </ul>
          </Collapse>
          <Typo.Link1
            cursor={disabled ? "no-drop" : "context-menu"}
            width="max-content"
            textAlign="left"
            color="navy"
            onClick={() => !disabled && setIsShowDetail((x) => !x)}
          >
            {isShowDetail ? "ซ่อนรายละเอียดเพิ่มเติม" : "ดูรายละเอียดเพิ่มเติม"}
          </Typo.Link1>
        </Div>
      </Flex>
    </ChannelSelectorStyle>
  );
};

const ChannelSelectorStyle = styled(ContentContainer)`
  border: 2px solid
    ${(props) =>
      props.selected
        ? themes.colors.yellow
        : props.disabled
        ? themes.colors.gray3
        : themes.colors.white};

  transition-duration: 0.3s;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};

  background: ${(props) =>
    props.selected ? themes.colors.lightYellow : themes.colors.white};

  :hover {
    border: ${(props) =>
      !props.disabled && `2px solid ${themes.colors.yellow}`};
  }
  ${(props) => props.disabled && `opacity: 0.4`}
`;
