import React from "react";
import style from "../constants/style";
import styled from "styled-components";

export const Burger = ({ handleClick }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Container onClick={handleClick}>
        <Bar />
        <Bar />
        <Bar />
      </Container>
    </div>
  );
};
const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 8px;
`;
const Bar = styled.div`
  width: 25px;
  height: 3px;
  ${style.css.smRad}
  background-color: white;
  margin: 5px 0;
`;
