import React from "react";
import style from "../constants/style";
import styled from "styled-components";

export const TextBody = ({ children, align = "left" }) => {
  return <Text align={align}>{children}</Text>;
};
const Text = styled.div`
  text-align: ${({ align }) => align};
  @media (max-width: 700px) {
    font-size: 1rem;
  }
`;
