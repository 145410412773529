import React, { useContext } from "react";
import Context from "../Context";
import style from "../constants/style";
import styled from "styled-components";
import { Btn, ModalBase, Flex, ContentContainer } from "./";

export const ModalConsent = ({ createOrder, clearCart, isShow }) => {
  const { hideModal } = Context();
  return (
    <ModalBase hide={hideModal} isFullWidth={true}>
      <ContentContainer>
        <Text>
          <Header>
            <nobr>
              ข้อตกลงและเงื่อนไข
              <wbr />
              ในการซื้อ
              <wbr />
              ลอตเตอรี่ออนไลน์
              <wbr />
              ผ่านหงษ์ทอง
            </nobr>
          </Header>
          <Body>
            <div style={{ color: "red" }}>
              <div style={{ color: "red" }}>
                <nobr>
                  หงษ์ทอง
                  <wbr /> คือแพลตฟอร์มจำหน่ายลอตเตอรี่ออนไลน์
                  <wbr /> ในราคาใบละ 80 บาท
                  <wbr /> โดยผู้สั่งซื้อจะได้รับการบริการดังต่อไปนี้
                </nobr>
              </div>
              <div style={{ color: "red" }}>
                * บริการ ระบบจัดหาลอตเตอรี่ด้วยระบบโปรแกรมอัจฉริยะ
              </div>
              <div style={{ color: "red" }}>
                * บริการ แจ้งเตือนรายการสั่งซื้อไปยัง sms หรือ Line
                ของผู้สั่งซื้อ
              </div>
              <div style={{ color: "red" }}>
                * บริการ รับฝากลอตเตอรี่ (พร้อมประกันการสูญหาย)
              </div>
              <div style={{ color: "red" }}>* บริการ รับฝากตรวจผลลอตเตอรี่</div>
              <div style={{ color: "red" }}>
                * บริการ แจ้งผลถูกรางวัลไปยัง sms หรือ Line ของผู้สั่งซื้อ
              </div>
              <div style={{ color: "red" }}>
                * บริการ รับขึ้นเงินรางวัล ภายใน 24-48 ชั่วโมง
              </div>
            </div>
            <Flex margin="1em 0" justify="center" gap="10px">
              <Btn
                className="button-style-danger-inline small"
                borderColor="#eeeeee"
                margin="5px"
                text={"ยกเลิกการสั่งซื้อ"}
                onClick={clearCart}
              />
              <Btn
                className="button-style-primary small"
                text={"ยืนยันการสั่งซื้อ"}
                onClick={createOrder}
                margin="5px"
                weight={300}
              />
            </Flex>
            <div style={{ color: style.colors.brightGoldText }}>
              <div>เงื่อนไขการขอรับต้นฉบับลอตเตอรี่</div>
              <div>
                * หากลอตเตอรี่ของลูกค้าถูกรางวัล
                สามารถแจ้งความประสงค์เพื่อรับลอตเตอรี่ใบจริงเพื่อนำไปขึ้นเงินด้วยตนเองได้
                หรือสามารถให้หงษ์ทองนำลอตเตอรี่ไปขึ้นเงินแทนลูกค้าเพื่อความสะดวก
                โดยจะไม่มีค่าธรรมเนียมการขึ้นเงินรางวัล
                และทางหงษ์ทองจะดำเนินการโอนเงินรางวัลให้ลูกค้า ภายใน 24-48
                ชั่วโมง หลังจากได้รับ SMS หรือ Line
                ยืนยันการถูกรางวัลจากทางหงษ์ทอง
              </div>
              <div>
                * หากลอตเตอรี่ของลูกค้าไม่ถูกรางวัลในงวดนั้น
                ลูกค้าสามารถติดต่อขอรับใบจริงได้ และติดต่อขอรับใบจริงภายใน 2
                วันหลังจากประกาศผล (มีค่าบริการ)
                จากนั้นผู้ซื้อขอยกเลิกการฝากลอตเตอรรี่ไว้กับหงษ์ทองและให้กรรมสิทธิ์ในลอตเตอรี่
                ใบที่ไม่ถูกรางวัลนั้น
                ตกเป็นกรรมสิทธิ์ของหงษ์ทองเพื่อนำไปใช้ประโยชน์ เช่น บริจาค
                รีไซเคิล ทำลาย
                หรือดำเนินการใดตามประสงค์ของหงษ์ทองได้ทันทีเมื่อสิ้นสุดการออกรางวัลประจำงวด
                โดยลูกค้าผู้ซื้อไม่ติดใจเรียกร้องเอาลอตเตอรี่คืนหรือดำเนินการใดกับลอตเตอรี่ใบดังกล่าวอีกทั้งสิ้น
              </div>
              <div>
                * สำหรับลอตเตอรี่ที่ลูกค้าซื้อกับตัวแทนในระบบแพลตฟอร์มหงษ์ทอง
                หลังจากผลรางวัลออกในแต่ละงวด
                ทางระบบของหงษ์ทองจะตรวจสอบว่าลอตเตอรี่ในระบบของบริษัทหงษ์ทองถูกรางวัลหรือไม่ทันที
                หากไม่ถูกรางวัลทางบริษัทหงษ์ทองจะคัดแยกออกและให้สลากดังกล่าวตกเป็นกรรมสิทธิของบริษัทหงษ์ทองแต่เพียงผู้เดียวทันที
                เนื่องจาก
                บริษัทหงษ์ทองจะเก็บรักษาลอตเตอรี่ที่ไม่ถูกรางวัลไว้สูงสุด 2 ปี
                เพื่อให้หน่วยงานที่เกี่ยวข้องสามารถตรวจสอบได้ว่าบริษัทหงษ์ทองมีใบจริงที่ตรวจสอบได้แน่นอน
              </div>
              <div>
                * ส่วนกรณีลูกค้าถูกรางวัล แล้วต้องการลอตเตอรี่ใบจริง
                ตรงนี้สามารถแจ้งรับลอตเตอรี่ใบจริงได้เหมือนเดิมตามปกติ
              </div>
              <div>
                หากผู้สั่งซื้อ รับทราบและยินยอมที่จะชำระค่าลอตเตอรี่ดังกล่าว และ
                ต้องการดำเนินการสั่งซื้อต่อ ให้ทำการคลิกที่ปุ่ม
                หลังจากทำการจองรายการสั่งซื้อแล้ว
                ทางบริษัทจะถือว่าผู้สั่งซื้อได้รับทราบและทำความเข้าใจเกี่ยวกับรายละเอียดเป็นอันถี่ถ้วนแล้ว
                ก่อนที่จะทำรายการสั่งซื้อในรายการสั่งซื้อนั้น
                ข้อมูลการยืนยันนี้จะถูกนำไปบันทึกเป็นหลักฐาน
                หากในกรณีที่ผู้สั่งซื้อแจ้งความประสงค์ไม่ยอมรับกฎข้อกำหนดในการบริการของทางบริษัท
                ขอบพระคุณที่เลือกใช้บริการของเรา ขอบคุณครับ / ค่ะ
              </div>
            </div>
          </Body>
        </Text>
      </ContentContainer>
    </ModalBase>
  );
};

const Header = styled.div`
  border-radius: 10px 10px 0 0;
  text-align: center;
  color: ${style.colors.goldOrange};
  padding: 10px;
  width: calc(100% - 20px);
  background: ${style.colors.darkBlue};
  font-weight: bold;
  font-size: 1.4rem;
`;
const Body = styled.div`
  padding: 20px;
`;
const Text = styled.div`
  text-align: left;
  border-radius: 10px 10px 0 0;
  font-size: 16px;
`;
