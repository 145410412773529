import React, { useContext } from "react";
import Context from "../Context";
import { AddressInputs, ModalBase } from "./";
// import { updateAddress } from "../util/address"

export const ModalAddress = () => {
  const { hideModal, modalAddress, profileState, go } = Context();
  const initString = Object.keys(modalAddress.addressList).map((string) => {
    modalAddress.addressList[string].isDefault = true;
    return string;
  })[0];
  const addressList = {
    ...modalAddress.addressList,
    ...profileState.addressList,
  };
  return (
    <ModalBase
      hide={hideModal}
      isFullWidth={true}
      style={{ overflow: "scroll" }}
    >
      <AddressInputs
        style={{ overflow: "scroll" }}
        hide={hideModal}
        addressList={addressList}
        callBack={modalAddress.callBack}
        initString={initString}
        orderId={modalAddress.orderId}
        handleSave={async (data, type) => {
          hideModal();
          // return await updateAddress(data, callApi, setProfileState, type)
          return;
        }}
      />
    </ModalBase>
  );
};
