import React from "react";
import styled from "styled-components";
import { ModalBase } from "./ModalBase";
import Context from "../Context";
import { Div, Flex } from "./index";
import { GridLoader } from "react-spinners";
import themes from "../themes";
import { Modal } from "reactstrap";

export const ModalLoading = () => {
  const { hideModal } = Context();

  return (
    <Modal centered onClick={() => hideModal()} isOpen={true}>
      <Div textAlign="center" margin="15px auto 0 auto">
        <GridLoader color={themes.colors.yellow} />
      </Div>
    </Modal>
    // <ModalBase hide={hideModal} isFullWidth={true} noBackground={true}>
    //   <Loading>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //   </Loading>
    // </ModalBase>
  );
};

export const LoadingIcon = ({ height }) => {
  return (
    <Flex
      justify="center"
      align="center"
      height={height || "200px"}
      textAlign="center"
      margin="15px auto 0 auto"
    >
      <GridLoader color={themes.colors.yellow} />
    </Flex>
  );
};

const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;
  }
  div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%,
    100% {
      background-color: rgba(40, 60, 145);
    }
    50% {
      background-color: rgba(245, 194, 64);
    }
  }
`;
