import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalBase } from "./ModalBase";
import Context from "../Context";
import { Collapse, Modal } from "reactstrap";
import {
  Button,
  Flex,
  Typo,
  Input,
  Div,
  ContentContainer,
  InputBox,
} from "./index";
import { validate } from "../constants";
import { GridLoader } from "react-spinners";
import themes from "../themes";
import { randomLetters, CountDown } from "../util";

export const ModalRequiredInformation = ({
  isOpen,
  setShowModalRequiredInformation,
}) => {
  const { hideModal, toast, callApi, profileState, go, updateProfileState } =
    Context();

  const [isShowOtp, setIsShowOtp] = useState(false);
  const [userRequiredData, setUserRequiredData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [expireIn, setExpireIn] = useState(0);
  const [otpRef, setOtpRef] = useState("");
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [isShowPass, setIsShowPass] = useState(false);
  useEffect(() => {
    setUserRequiredData({
      firstName: profileState.firstName || "",
      lastName: profileState.lastName || "",
      phone: profileState.phone || "",
    });
  }, [profileState]);

  useEffect(() => {
    const countDown = () =>
      setTimeout(() => {
        setExpireIn((x) => {
          if (x >= 1000) {
            return x - 1000;
          }
        });
        countDown();
      }, 1000);
    countDown();
    return () => clearTimeout(countDown);
  }, []);

  const handleChange = (e) => {
    setUserRequiredData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(!isSubmitting);
    const tempOtpRef = randomLetters(5);
    const { result } = await callApi(
      "users-requestChangePhoneNumberOtp",
      {
        otpRef: tempOtpRef,
        phone: userRequiredData.phone,
      },
      { notShowLoading: true }
    );
    if (result.expireAt) {
      setIsSubmitting(false);
      setOtpRef(tempOtpRef);
      setExpireIn(result.expireAt - new Date());
      setIsShowOtp(!isShowOtp);
      setDigits(["", "", "", "", "", ""]);
    } else {
      setIsSubmitting(!isSubmitting);
    }
  };

  const CountDown = ({ second = 0 }) => {
    if (second <= 0) return <span>00:00</span>;
    second = parseInt(second / 1000);
    const pattern =
      String(Math.floor(second / 60)).padStart(2, "0") +
      ":" +
      String(second % 60).padStart(2, "0");
    return <span>{pattern}</span>;
  };

  const submit = async () => {
    setIsSubmitting(true);
    const otpCode = digits.join("");
    if (digits.length === otpCode.length) {
      const res = await callApi(
        "users-updateRequiredUserData",
        {
          phone: userRequiredData.phone,
          lastName: userRequiredData.lastName,
          firstName: userRequiredData.firstName,
          otpCode,
          otpRef,
        },
        { notShowLoading: true }
      );

      if (expireIn <= 0 || expireIn === undefined) {
        setIsShowOtp(false);
        setIsSubmitting(false);
        return toast("หมดเวลา โปรดลองใหม่อีกครั้ง");
      }
      if (res.data.result) {
        updateProfileState();
        setIsFinished(true);
      } else {
        setIsSubmitting(false);
        toast(res.data.toastMessage || "รหัสผิดพลาดโปรดลองใหม่อีกครั้ง");
      }
    } else {
      toast("โปรดใส่รหัสให้ครบถ้วน");
    }
  };

  return (
    <Modal centered isOpen={isOpen}>
      <ContentContainer
        minHeight="0"
        bgcolor="navy"
        margin="0"
        padding="25px 15px"
      >
        {!isFinished && (
          <>
            <Collapse isOpen={!isShowOtp}>
              <Typo.H5 textAlign="center">
                กรุณาใส่ข้อมูลให้ครบถ้วนก่อนสั่งซื้อสลาก
              </Typo.H5>
              <br />
              <Div padding="10px 0">
                <Typo.Body4>ชื่อ</Typo.Body4>
                <Input
                  type="text"
                  width="100%"
                  name="firstName"
                  onChange={(e) => handleChange(e)}
                  value={userRequiredData.firstName}
                />
              </Div>
              <Div padding="10px 0">
                <Typo.Body4>นามสกุล</Typo.Body4>
                <Input
                  type="text"
                  width="100%"
                  name="lastName"
                  onChange={(e) => handleChange(e)}
                  value={userRequiredData.lastName}
                />
              </Div>
              <Div padding="10px 0">
                <Typo.Body4>เบอร์โทรศัพท์</Typo.Body4>
                <Input
                  type="text"
                  width="100%"
                  name="phone"
                  onChange={(e) =>
                    validate.digitOnly.test(e.target.value) && handleChange(e)
                  }
                  value={userRequiredData.phone}
                />
              </Div>

              {isSubmitting ? (
                <Div textAlign="center" margin="15px auto 0 auto">
                  <GridLoader color={themes.colors.yellow} />
                </Div>
              ) : (
                <Button
                  disabled={Object.values(userRequiredData).some((e) => !e)}
                  onClick={() => handleSubmit()}
                  background="yellow"
                  margin="15px auto 0 auto"
                >
                  ยืนยัน
                </Button>
              )}
            </Collapse>

            <Collapse isOpen={isShowOtp}>
              <>
                <Typo.Body2 textAlign="center">
                  กรุณากรอกรหัสยืนยันของท่าน
                </Typo.Body2>
                <Typo.Body5 textAlign="center" weight="bold" color="yellow">
                  ** หากลูกค้าอยู่ต่างประเทศสามารถขอรับ OTP ได้ที่แอดมิน **
                </Typo.Body5>
                <Typo.Body2 textAlign="center" margin="5px 0">
                  รหัสอ้างอิง {otpRef}
                </Typo.Body2>
                <Typo.Body2 textAlign="center" margin="0 0 40px 0">
                  หมดอายุภายใน{" "}
                  <CountDown second={expireIn} zeroRefresh={true} />
                </Typo.Body2>
                <InputBox
                  digits={digits}
                  setDigits={setDigits}
                  gap="15px"
                  action={() => setIsShowPass(true)}
                />
                {isSubmitting ? (
                  <Div textAlign="center" margin="15px auto 0 auto">
                    <GridLoader color={themes.colors.yellow} />
                  </Div>
                ) : (
                  <Button
                    disabled={digits.some((x) => !x)}
                    onClick={() => submit()}
                    margin="25px 0 0 0"
                    background="yellow"
                    hoverBg="transparent"
                    hoverColor="yellow"
                    hoverBorder="yellow"
                    width="100%"
                  >
                    ยืนยัน
                  </Button>
                )}
              </>
            </Collapse>
          </>
        )}
        <Collapse isOpen={isFinished}>
          <Typo.Body2 textAlign="center">ยืนยันข้อมูลบุคคลสำเร็จ</Typo.Body2>
          <Button
            onClick={() =>
              setShowModalRequiredInformation((prevState) => ({
                isShow: !prevState.isShow,
              }))
            }
            margin="25px 0 0 0"
            background="yellow"
            hoverBg="transparent"
            hoverColor="yellow"
            hoverBorder="yellow"
            width="100%"
          >
            ยืนยัน
          </Button>
        </Collapse>
      </ContentContainer>
    </Modal>
  );
};

const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;
  }
  div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%,
    100% {
      background-color: rgba(40, 60, 145);
    }
    50% {
      background-color: rgba(245, 194, 64);
    }
  }
`;
