import React from "react";
import styled from "styled-components";
import themes from "../../themes";
import { devices } from "../../constants";

const WrapperStyle = styled.div`
  border-radius: ${(props) => props.backgroundColor || "16px"};
  background: ${(props) => props.backgroundColor || themes.colors.white};
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding || "40px 55px 40px 55px"};
  z-index: ${(props) => props.zIndex || "1"};

  //ipad size
  @media only screen and (${devices.tablet}) {
  }
  //iphone size
  @media only screen and (max-width: 480px) {
    padding: ${(props) => props.padding || "32px 20px 32px 20px"};
  }
`;

export const Wrapper = (props) => {
  const { children, className } = props;
  return (
    <WrapperStyle className={className} {...props}>
      {children}
    </WrapperStyle>
  );
};
