const style = {
  colors: {
    mainDark1: "#79a0ab",
    mainDark2: "#5b7197",
    mainDark3: "#2A4C84",
    mainLight1: "#e3a494",
    mainLight2: "#eecfc2",
    mainLight3: "#e8e3dd",
    lightGray: "#eeeeee",
    gray: "#bcbcbc",
    darktGray: "#999999",
    textDark: "white",
    textWhite: "black",
    success: "#019035",
    danger: "darkred",
    darkBlue: "rgb(30,45,100)",
    lightBlue: "rgba(40,60,150,1)",
    goldText: "rgb(219, 193, 103)",
    brightGoldText: "rgb(222,170,90)",
    goldenGline:
      "linear-gradient(to left, #e8be50 0%, #f6e094 35%, #f9f295 50.39%, #e5c24c 100%)",
    goldOrange: "rgb(222,170,90)",
    goldCoupon: 'linear-gradient(120deg, rgba(245,194,64,1) 0%, rgba(245,194,64,1) 50%, rgba(255,249,133,1) 100%)'
  },
  status: {
    approved: "green",
    pending: "#dbc51a",
    paid: "orange",
    canceled: "red",
  },
  css: {
    bgMild: "background: rgb(242,247,255);",
    bgWhite: "background: white;",
    primary: "background: rgba(245,194,64,1);",
    lightShadow: "box-shadow: 0 0px 25px -5px black;",
    shadow: "box-shadow: 0 0px 15px 5px black;",
    shadowBottom: "box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.4);",
    gradian:
      "background: linear-gradient(90deg, rgba(20,30,75,1) 0%, rgba(30,40,90,1) 60%, rgba(40,60,150,1) 100%);",
    goldenGradian:
      "background: linear-gradient(90deg, rgba(245,194,64,1) 0%, rgba(245,194,64,1) 10%, rgba(255,249,133,1) 50%, rgba(245,194,64,1) 90%,rgba(245,194,64,1) 100%);",
    blueGradian:
      "background: linear-gradient(180deg, rgba(40,60,145,1) 0%, rgba(25,30,75,1) 100%);",
    yellowGradian:
      "background: linear-gradient(90deg, rgba(225,200,100,1) 0%, rgba(250,240,160,1) 50%, rgba(225,200,100,1) 100%);",
    goldenGline:
      "background: linear-gradient(to left, #e8be50 0%, #f6e094 35%, #f9f295 50.39%, #e5c24c 100%);",
    goldenYellow:
      "background: linear-gradient(90deg, rgba(245,194,64,1) 0%, rgba(245,194,64,1) 19%, rgba(255,249,133,1) 100%);",
    goldenPremium:
      "background: linear-gradient(90deg, rgba(190,160,100,1) 0%, rgba(250,240,175,1) 30%, rgba(250,240,175,1) 70%, rgba(190,160,100,1) 100%);",
    smRad: "border-radius: 5px;",
    mdRad: "border-radius: 10px;",
  },
};
export default style;
