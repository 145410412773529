import React, { useState, useRef, useEffect } from "react";
import { devices, getThaiDateFormat } from "../constants";

import styled from "styled-components";
import Context from "../Context";
import { Button, Flex, Div, Typo, ContentContainer } from "../components";
import { FiSearch } from "react-icons/fi";
import themes from "../themes";
import { SquareInput } from "./SquareInputs";
import * as Scroll from "react-scroll";
import { Profile } from "../pages";
export const SearchBox = ({
  digitsPath,
  filterPath,
  filterStatus,
  refresh,
}) => {
  const [digits, setDigits] = useState(
    (digitsPath || "xxxxxx").split("").map((x) => (x !== "x" ? x : ""))
  );
  const [filter, setFilter] = useState(filterPath || filterStatus.all);
  const { go, configs, showModalLogin, profileState } = Context();
  const [, setTs] = useState(Date.now());
  const isLogin = localStorage.getItem("accessToken");
  let scroll = Scroll.animateScroll;

  const handleSearch = () => {
    const ds = digits.reduce((acc, x, i) => {
      acc[`d${i + 1}`] = x;
      return acc;
    }, {});
    const body = {};
    Object.keys(ds).forEach((dx) => {
      if (ds[dx]) {
        body[dx] = ds[dx];
      }
    });
    const path = digits.map((x) => x || "x").join("");
    go(`/search/${filter}/${path}`);
    scroll.scrollMore(500, { duration: 0 });
  };

  const handleFilter = (status) => {
    setFilter(status);
    go(`/search/${status}`);
  };
  return (
    <ContentContainer>
      <Div>
        <Typo.H4 color="navy">ค้นหาเลขเด็ด โดนใจของคุณ!</Typo.H4>
        <Flex
          margin="24px 0"
          width="100%"
          align="center"
          justify="space-between"
        >
          <Typo.H7 color="textBlack">
            งวดวันที่ {getThaiDateFormat(new Date(configs.roundDate))}
          </Typo.H7>
          <Typo.Body4
            color="red"
            cursor="pointer"
            onClick={() => setDigits(["", "", "", "", "", ""])}
          >
            <u>ล้างข้อมูล</u>
          </Typo.Body4>
        </Flex>
        <Numbers>
          <SquareInput
            text={""}
            index={0}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            text={""}
            index={1}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            text={""}
            index={2}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            text={""}
            index={3}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            text={""}
            index={4}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            text={""}
            index={5}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
        </Numbers>

        <Grid>
          <Button
            className={`${filter === filterStatus.all && `active`}`}
            background="lightNavy"
            color="navy"
            size="16px"
            hoverColor="white"
            hoverBg="navy"
            onClick={() => handleFilter(filterStatus.all)}
          >
            ดูทั้งหมด
          </Button>
          {configs.showButton?.one && (
            <Button
              className={` ${filter === filterStatus.one && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => handleFilter(filterStatus.one)}
            >
              ใบเดียว
            </Button>
          )}
          {configs.showButton?.two && (
            <Button
              className={` ${filter === filterStatus.two && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => handleFilter(filterStatus.two)}
            >
              ชุด 2 ใบ
            </Button>
          )}
          {configs.showButton?.three && (
            <Button
              className={` ${filter === filterStatus.three && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => handleFilter(filterStatus.three)}
            >
              ชุด 3 ใบ
            </Button>
          )}
          {configs.showButton?.four && (
            <Button
              className={` ${filter === filterStatus.four && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => handleFilter(filterStatus.four)}
            >
              ชุด 4 ใบ
            </Button>
          )}
          {configs.showButton?.five && (
            <Button
              className={` ${filter === filterStatus.five && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => handleFilter(filterStatus.five)}
            >
              ชุด 5 ใบ
            </Button>
          )}
          {configs.showButton?.random && (
            <Button
              className={` ${filter === filterStatus.random && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => {
                handleFilter(filterStatus.random);
                if (filter === filterStatus.random) {
                  refresh();
                }
              }}
            >
              เลขสุ่ม
            </Button>
          )}
          {configs.showButton?.bigNumber && (
            <Button
              // className="small disabled"
              className={` ${filter === filterStatus.big && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => handleFilter(filterStatus.big)}
            >
              ชุดใหญ่
            </Button>
          )}
          {configs.showButton?.prettyFronts && (
            <Button
              // className="small disabled"
              className={` ${filter === filterStatus.prettyFronts && `active`}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => {
                if (!isLogin) {
                  showModalLogin({ isShow: true });
                  return;
                }
                setDigits(["", "", "", "", "", ""]);
                setFilter("prettyFronts");
                go(`/search/prettyFronts`);
              }}
            >
              เลขหน้าสวย
            </Button>
          )}
          {!!configs.showButton?.specialNumber && (
            <Button
              // className="small disabled"
              className={` ${
                filter === filterStatus.specialNumber && `active`
              }`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => {
                if (!isLogin) {
                  showModalLogin({ isShow: true });
                  return;
                }
                setDigits(["", "", "", "", "", ""]);
                setFilter("specialNumber");
                go(`/search/specialNumber`);
              }}
            >
              เลขเซียนดัง
            </Button>
          )}
          {/* {!!configs.showButton?.magicNumber && (
            <Button
              // className="small disabled"
              className={` ${filter === filterStatus.magicNumber && "active"}`}
              background="lightNavy"
              color="navy"
              size="16px"
              hoverColor="white"
              hoverBg="navy"
              onClick={() => {
                setDigits(["", "", "", "", "", ""]);
                setFilter("magicNumber");
                go(`/search/magicNumber`);
              }}
            >
              {configs.magicNumber?.name}
            </Button>
          )} */}
        </Grid>
        {!!configs.showButton?.magicNumber && (
          <Button
            // className="small disabled"
            className={` ${filter === filterStatus.magicNumber && "active"}`}
            background="lightNavy"
            width="100%"
            color="navy"
            size="16px"
            hoverColor="white"
            hoverBg="navy"
            onClick={() => {
              setDigits(["", "", "", "", "", ""]);
              setFilter("magicNumber");
              go(`/search/magicNumber`);
            }}
          >
            {configs.magicNumber?.name}
          </Button>
        )}
        <br />

        {/* <Button
          // className="small disabled"
          className={` ${filter === filterStatus.labubuNumber && "active"}`}
          background="lightNavy"
          width="100%"
          color="navy"
          size="16px"
          hoverColor="white"
          hoverBg="navy"
          onClick={() => {
            setDigits(["", "", "", "", "", ""]);
            setFilter("labubuNumber");
            go(`/search/labubuNumber`);
          }}
        >
          เลขกิจกรรม Labubu
        </Button>

        <br /> */}
        <Button
          width="100%"
          margin="10px 0 0 0"
          background="gradient2"
          color="white"
          weight="bold"
          size="18px"
          hoverBg="white"
          hoverColor="navy"
          hoverBorder="navy"
          onClick={() => handleSearch()}
        >
          <FiSearch style={{ margin: "0 5px", color: themes.colors.yellow }} />
          ค้นหาลอตเตอรี่
        </Button>
      </Div>
    </ContentContainer>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  margin: 32px 0;
  grid-gap: 16px;

  //ipad size
  @media only screen and (${devices.tablet}) {
  }
  //iphone size

  @media only screen and (${devices.mobile}) {
    gap: 8px;
  }
`;
const Numbers = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  @media only screen and (${devices.mobile}) {
    gap: 8px;
  }
`;
