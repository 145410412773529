import { collection, doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Context from "../Context";
import {
  Typo,
  Container,
  ContentContainer,
  Flex,
  ProgressBar,
  Button,
  Line,
} from "../components";
import { CountDown, resizeImage } from "../util";
import { Collapse } from "reactstrap";
import { FaRegCopy } from "react-icons/fa";
import themes from "../themes";
import { locale } from "../constants";

export const PaymentDetail = () => {
  const { orderId } = useParams();
  const { callApi, configs, db, toast, go, profileState } = Context();
  const [order, setOrder] = useState(false);
  const [expireIn, setExpireIn] = useState(30 * 60);
  const [paymentSelected, setPaymentSelected] = useState(
    profileState.bank?.currency === "LAK"
      ? "LAO-LAK"
      : configs.payment.defaultPayment
  );
  const uploadRef = useRef(null);
  const [initCountDown, setInitCountDown] = useState(false);
  const [qrPaymentImage, setQrPaymentImage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!orderId) {
      return go("/");
    }

    sessionStorage.removeItem("affiliate");
    callApi("qrPayment-generateQr", {
      guestId: localStorage.guestId,
      orderId,
      bank: configs.payment.qrChannel || "cimb",
    }).then((result) => {
      result.status && setQrPaymentImage(result.qrPaymentImage);
    });
  }, [orderId]);

  useEffect(() => {
    (async () => {
      const countDown = () =>
        setTimeout(() => {
          setExpireIn((x) => {
            if (x >= 1) {
              return x - 1;
            } else if (x === 0) {
              setExpireIn(0);
              forceCanceledOrder();
              go("/");
              return () => clearTimeout(countDown);
            }
          });
          countDown();
        }, 1000);

      if (!initCountDown) {
        setInitCountDown(true);
        countDown();
      }

      return () => clearTimeout(countDown);
    })();
  }, [orderId]);

  const forceCanceledOrder = async () => {
    await callApi("orders-forceCanceledOrder", {
      orderId: orderId,
      guestId: localStorage.guestId || false,
      from: "paymentDetail line:78",
    });
  };

  useEffect(() => {
    const unsub = onSnapshot(
      doc(collection(db, "Orders"), orderId),
      (snapshot) => {
        const showOrder = snapshot.data();
        const status = snapshot.get("status");
        const expireAt = snapshot.get("expireAt");
        if ("paid" === status) {
          return go("/status/paid");
        } else if ("approved" === status) {
          return go("/status/approved");
        } else if ("canceled" === status) {
          toast("คำสั่งซื้อถูกยกเลิก");
          return go("/");
        }
        setExpireIn(parseInt((expireAt - Date.now()) / 1000));

        setOrder(showOrder);
      }
    );
    return unsub;
  }, []);

  const onFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
        return window.toast(
          "อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg, jpeg หรือ png"
        );
      }
      const base64 = await resizeImage(file);
      if (!base64) {
        return toast("การอัพโหลดผิดพลาด กรุณาลองใหม่อีกครั้ง");
      }
      await uploadSlip({ fileName: file.name, base64 });
    } catch {
      return toast("การอัพโหลดผิดพลาด กรุณาลองใหม่อีกครั้ง");
    }
  };

  const uploadSlip = async (slip) => {
    if (slip?.base64) {
      const res = await callApi("orders-uploadSlip", { ...slip, orderId });
      if (res) {
        window.location.reload();
      }
    }
  };

  const bankInfo = {
    bank: {
      name: (
        <>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_533_98539)">
              <rect width="32" height="32" rx="4" fill="#00A850" />
              <circle cx="16.0031" cy="15.9992" r="10.8" fill="white" />
              <path
                d="M21.6656 21.8121C21.1594 21.5496 20.6156 21.2871 19.8094 21.2871C19.0969 21.2871 18.5156 21.5496 17.9719 21.8121C17.3906 22.0746 16.7906 22.3559 15.9844 22.3559C15.2719 22.3559 14.7469 22.1121 14.1844 21.8496C13.6031 21.5871 13.0031 21.3059 12.1781 21.3059C11.4656 21.3059 10.8844 21.5684 10.3219 21.8121C9.74063 22.0746 9.12188 22.3559 8.33438 22.3559C8.29688 22.3559 8.24063 22.3559 8.20312 22.3559C8.27813 22.4496 8.37188 22.5621 8.44688 22.6559C9.25313 22.6371 9.85313 22.3559 10.4344 22.0934C10.9969 21.8496 11.5031 21.6059 12.1594 21.6059C12.9094 21.6059 13.4531 21.8684 14.0344 22.1309C14.5969 22.3934 15.1969 22.6746 15.9656 22.6746C16.8281 22.6746 17.4656 22.3746 18.0844 22.0934C18.6281 21.8496 19.1531 21.6059 19.8094 21.6059C20.5406 21.6059 21.0281 21.8496 21.5344 22.1121C22.0781 22.3934 22.6594 22.6746 23.5969 22.6746C23.6906 22.5809 23.7656 22.4684 23.8594 22.3559C23.7844 22.3559 23.6906 22.3746 23.6156 22.3746C22.7344 22.3371 22.1906 22.0746 21.6656 21.8121Z"
                fill="#827974"
              />
              <path
                d="M19.8063 21.8496C19.0938 21.8496 18.5125 22.1121 17.9688 22.3746C17.3875 22.6371 16.7875 22.9184 15.9813 22.9184C15.2688 22.9184 14.7438 22.6746 14.1813 22.4121C13.6 22.1496 13 21.8684 12.175 21.8684C11.4625 21.8684 10.8813 22.1309 10.3188 22.3746C9.83125 22.5996 9.30625 22.8246 8.6875 22.8996C8.78125 22.9934 8.85625 23.0871 8.95 23.1621C9.53125 23.0684 10 22.8621 10.45 22.6559C11.0125 22.4121 11.5187 22.1684 12.175 22.1684C12.925 22.1684 13.4688 22.4309 14.05 22.6934C14.6125 22.9559 15.2125 23.2371 15.9813 23.2371C16.8438 23.2371 17.4812 22.9371 18.1 22.6559C18.6438 22.3934 19.1688 22.1684 19.825 22.1684C20.5563 22.1684 21.0438 22.4121 21.55 22.6746C22 22.8996 22.45 23.1246 23.1063 23.2184C23.2 23.1246 23.2938 23.0309 23.3688 22.9371C22.6375 22.8996 22.15 22.6371 21.6813 22.4121C21.1375 22.1121 20.6125 21.8496 19.8063 21.8496Z"
                fill="#827974"
              />
              <path
                d="M19.8141 22.4121C19.1016 22.4121 18.5203 22.6746 17.9766 22.9371C17.3953 23.1996 16.7953 23.4809 15.9891 23.4809C15.2766 23.4809 14.7516 23.2371 14.1891 22.9746C13.6078 22.7121 13.0078 22.4309 12.1828 22.4309C11.4703 22.4309 10.8891 22.6934 10.3266 22.9371C9.97031 23.1059 9.59531 23.2746 9.16406 23.3684C9.25781 23.4434 9.33281 23.5371 9.42656 23.6121C9.78281 23.4996 10.1203 23.3496 10.4391 23.2184C11.0016 22.9746 11.5078 22.7309 12.1641 22.7309C12.9141 22.7309 13.4578 22.9934 14.0391 23.2559C14.6016 23.5184 15.2016 23.7996 15.9703 23.7996C16.8328 23.7996 17.4703 23.4996 18.0891 23.2184C18.6328 22.9746 19.1578 22.7309 19.8141 22.7309C20.5453 22.7309 21.0328 22.9746 21.5391 23.2184C21.8578 23.3871 22.1766 23.5371 22.5891 23.6496C22.6828 23.5746 22.7766 23.4809 22.8703 23.4059C22.4016 23.3121 22.0453 23.1246 21.6891 22.9371C21.1453 22.6934 20.6203 22.4121 19.8141 22.4121Z"
                fill="#827974"
              />
              <path
                d="M19.8109 22.9941C19.0984 22.9941 18.5172 23.2566 17.9734 23.5191C17.3922 23.7816 16.7922 24.0629 15.9859 24.0629C15.2734 24.0629 14.7484 23.8191 14.1859 23.5566C13.6047 23.2941 13.0047 23.0129 12.1797 23.0129C11.4672 23.0129 10.8859 23.2754 10.3234 23.5191C10.0984 23.6129 9.89219 23.7254 9.64844 23.8004C9.74219 23.8754 9.83594 23.9504 9.92969 24.0254C10.1172 23.9504 10.2859 23.8754 10.4547 23.8004C11.0172 23.5566 11.5234 23.3129 12.1797 23.3129C12.9297 23.3129 13.4734 23.5754 14.0547 23.8379C14.6172 24.1004 15.2172 24.3816 15.9859 24.3816C16.8484 24.3816 17.4859 24.0816 18.1047 23.8004C18.6484 23.5379 19.1734 23.3129 19.8297 23.3129C20.5609 23.3129 21.0484 23.5566 21.5547 23.8191C21.7234 23.9129 21.9109 24.0066 22.0984 24.0816C22.1922 24.0066 22.2859 23.9316 22.3797 23.8566C22.1359 23.7629 21.9109 23.6504 21.6859 23.5379C21.1422 23.2566 20.6172 22.9941 19.8109 22.9941Z"
                fill="#827974"
              />
              <path
                d="M19.8078 23.5566C19.0953 23.5566 18.5141 23.8191 17.9703 24.0816C17.3891 24.3441 16.7891 24.6254 15.9828 24.6254C15.2703 24.6254 14.7453 24.3816 14.1828 24.1191C13.6016 23.8379 13.0016 23.5754 12.1766 23.5754C11.4641 23.5754 10.8828 23.8379 10.3203 24.0816C10.2641 24.1191 10.1891 24.1379 10.1328 24.1754C10.2266 24.2504 10.3203 24.3066 10.4141 24.3816C10.4328 24.3816 10.4328 24.3816 10.4516 24.3629C11.0141 24.1191 11.5203 23.8754 12.1766 23.8754C12.9266 23.8754 13.4703 24.1379 14.0516 24.4004C14.6141 24.6629 15.2141 24.9441 15.9828 24.9441C16.8453 24.9441 17.4828 24.6441 18.1016 24.3629C18.6453 24.1004 19.1703 23.8754 19.8266 23.8754C20.5578 23.8754 21.0453 24.1191 21.5516 24.3816C21.5703 24.4004 21.5891 24.4004 21.6266 24.4191C21.7203 24.3629 21.8141 24.2879 21.9078 24.2316C21.8328 24.1941 21.7578 24.1566 21.7016 24.1191C21.1391 23.8379 20.6141 23.5566 19.8078 23.5566Z"
                fill="#827974"
              />
              <path
                d="M17.9328 24.643C17.3516 24.9055 16.7516 25.1867 15.9453 25.1867C15.2328 25.1867 14.7078 24.943 14.1453 24.6805C13.5641 24.418 12.9641 24.1367 12.1391 24.1367C11.5578 24.1367 11.0703 24.3055 10.6016 24.5117C10.7141 24.5867 10.8078 24.643 10.9203 24.6992C11.2953 24.5492 11.6891 24.4367 12.1391 24.4367C12.8891 24.4367 13.4328 24.6992 14.0141 24.9617C14.5766 25.2242 15.1766 25.5055 15.9453 25.5055C16.8078 25.5055 17.4453 25.2055 18.0641 24.9242C18.6078 24.6805 19.1328 24.4367 19.7891 24.4367C20.3141 24.4367 20.7078 24.5492 21.0641 24.718C21.1766 24.6617 21.2703 24.5867 21.3641 24.5305C20.9328 24.3242 20.4453 24.1367 19.7891 24.1367C19.0953 24.1367 18.4953 24.3992 17.9328 24.643Z"
                fill="#827974"
              />
              <path
                d="M16 4C9.38125 4 4 9.38125 4 16C4 22.6187 9.38125 28 16 28C22.6187 28 28 22.6187 28 16C28 9.38125 22.6187 4 16 4ZM16 26.0875C10.4312 26.0875 5.9125 21.5688 5.9125 16C5.9125 10.4312 10.4312 5.9125 16 5.9125C21.5688 5.9125 26.0875 10.4312 26.0875 16C26.0875 21.5688 21.5688 26.0875 16 26.0875Z"
                fill="#F23128"
              />
              <path
                d="M21.6078 13.0371C21.5328 13.4121 21.1578 13.8059 20.9516 13.9746C20.4828 14.3496 17.3703 16.2434 17.0141 16.6746C16.6578 17.1059 16.6016 17.7621 16.6016 18.2496V21.9059H17.0891C17.0891 21.5871 17.0891 21.0434 17.0891 20.9684C17.0891 20.6496 17.2766 20.1434 17.6516 19.7496C18.2891 19.0746 19.7703 18.2496 20.7641 17.5371C21.5703 16.9371 21.6266 16.1309 21.6266 15.4371C21.6266 14.8559 21.6266 13.2434 21.6266 12.9434L21.6078 13.0371Z"
                fill="#00A850"
              />
              <path
                d="M16.6172 16.45C16.9547 16.0188 20.1797 14.0688 20.6297 13.6938C21.0797 13.3188 21.6234 12.7563 21.6234 12.025C21.6234 11.4063 21.6234 8.36875 21.6234 7.9375C21.6234 8.51875 21.3047 8.95 20.8922 9.49375C20.4234 10.1125 18.2672 11.275 17.1984 12.025C16.1297 12.775 15.7734 13.5063 15.7734 14.425C15.7734 15.1938 15.7734 20.2563 15.7734 21.8875H16.2422C16.2422 20.8188 16.2422 18.3625 16.2422 17.9875C16.2234 17.5 16.2609 16.8813 16.6172 16.45Z"
                fill="#00A850"
              />
              <path
                d="M14.1828 12.2121C13.2078 11.4621 11.2203 9.86836 10.9766 9.54961C10.7328 9.23086 10.2266 8.66836 10.2266 7.97461C10.2266 8.48086 10.2266 11.3121 10.2266 11.7809C10.2266 12.3246 10.4891 13.2996 11.4266 13.9934C12.3641 14.6871 13.6953 15.6809 14.1078 16.0934C14.5016 16.4871 14.8766 17.2184 14.8766 18.2496C14.8766 18.9996 14.8766 21.0059 14.8766 21.9246H15.3453C15.3453 20.3496 15.3453 15.5871 15.3453 14.6309C15.3828 13.4309 15.1578 12.9621 14.1828 12.2121Z"
                fill="#00A850"
              />
            </g>
            <defs>
              <clipPath id="clip0_533_98539">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <Typo.Body1 margin="0 0 0 10px" color="textBlack">
            ธนาคารกสิกรไทย
          </Typo.Body1>
        </>
      ),
      accName: "บจก. หงษ์ทอง ออนไลน์",
      accNo: "105-324-6105",
      totalPrice: order?.totalPrice,
      currency: "TH",
    },
    "LAO-LAK": {
      name: (
        <>
          <img
            src="/images/jdb-ico.png"
            alt="jdb-ico"
            style={{ width: "45px" }}
          />
          <Typo.Body1 margin="0 0 0 10px" color="textBlack">
            Joint Development Bank
          </Typo.Body1>
        </>
      ),
      accName: "MISS THITAPA THANASAPPREECHA",
      accNo: "00120010010080447",
      totalPrice: order?.totalPrice_lak,
      currency: "LAO",
    },
    "LAO-THB": {
      name: (
        <>
          <img
            src="/images/jdb-ico.png"
            alt="jdb-ico"
            style={{ width: "45px" }}
          />
          <Typo.Body1 margin="0 0 0 10px" color="textBlack">
            Joint Development Bank
          </Typo.Body1>
        </>
      ),
      accName: "MISS THITAPA THANASAPPREECHA",
      accNo: "00120010210030720",
      totalPrice: order?.totalPrice,
      currency: "TH",
    },
  };

  if (!order) return;

  return (
    <Flex direction="column" width="100%" margin="0 0 80px 0">
      <ProgressBar status={order?.status === "pending" ? 3 : 4} />
      <Container margin="0 auto">
        <ContentContainer
          padding="12px 16px"
          paddingTablet="12px 16px"
          paddingMobile="12px 16px"
          bgcolor="newGrReverse"
          minHeight="0"
          width="100%"
          margin="25px 0"
        >
          <Typo.H6 textAlign="center">การชำระเงิน</Typo.H6>
        </ContentContainer>
        <PaymentSeletor
          paymentSelected={paymentSelected}
          setPaymentSelected={setPaymentSelected}
          profileState={profileState}
        />
        <BankContainer
          order={order}
          expireIn={expireIn}
          qrPaymentImage={qrPaymentImage}
          paymentSelected={paymentSelected}
          bankInfo={bankInfo}
        />
        {paymentSelected === "qr" ? (
          <QrButton callApi={callApi} orderId={orderId} go={go} />
        ) : (
          <UploadBtn uploadRef={uploadRef} onFileChange={onFileChange} />
        )}

        <ContentContainer
          bgcolor="lightRed"
          minHeight="0px"
          padding="12px 16px"
        >
          <Typo.Body3 color="red" textAlign="center">
            กรุณาแนบสลิปและตรวจสอบสลิปการชำระเงินของลูกค้าให้ถูกต้อง <br />
            ระบบจะยกเลิกคำสั่งซื้อโดยไม่แจ้งให้ทราบล่วงหน้าหากสลิปไม่ถูกต้องหรือไม่แนบสลิป
          </Typo.Body3>
        </ContentContainer>
      </Container>
    </Flex>
  );
};

const QrButton = ({ callApi, orderId, go }) => {
  return (
    <Button
      margin="16px 0"
      width="100%"
      hoverColor="navy"
      background="gradient2"
      hoverBg="transparent"
      hoverBorder="navy"
      gap="10px"
      onClick={() => {
        callApi("orders-getOrder", { orderId }).then((order) => {
          order.status !== "approved" && go("/status/pending");
        });
      }}
    >
      ยืนยันการชำระเงิน
    </Button>
  );
};

const UploadBtn = ({ uploadRef, onFileChange }) => {
  return (
    <>
      <Button
        margin="16px 0"
        height="55px"
        width="100%"
        hoverColor="navy"
        background="gradient2"
        hoverBg="transparent"
        hoverBorder="navy"
        gap="10px"
        onClick={() => uploadRef.current.click()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            fillrule="evenodd"
            cliprule="evenodd"
            d="M10.147 0.882191C9.965 0.960341 4.86025 6.05364 4.7475 6.26964C4.4763 6.78904 4.83795 7.45039 5.41615 7.49219C5.85085 7.52364 5.7787 7.58189 7.7935 5.57244L9.63925 3.73169V9.00939C9.63925 13.6601 9.64635 14.3074 9.69935 14.4575C9.9632 15.2053 11.0856 15.1773 11.3134 14.4172C11.3458 14.3089 11.358 12.8345 11.358 9.00004V3.73169L13.2037 5.57244C15.2185 7.58189 15.1463 7.52364 15.581 7.49219C16.1598 7.45034 16.5193 6.78919 16.2476 6.26644C16.1304 6.04094 11.0284 0.957941 10.8416 0.880591C10.6587 0.804791 10.3254 0.805541 10.147 0.882191ZM1.8072 10.8944C1.61575 10.9831 1.46005 11.1441 1.3796 11.3367C1.29215 11.5461 1.2893 15.3677 1.37625 15.8641C1.6684 17.5322 2.9665 18.8303 4.6346 19.1224C5.1241 19.2082 15.8731 19.2082 16.3626 19.1224C18.0307 18.8303 19.3288 17.5322 19.6209 15.8641C19.7079 15.3677 19.705 11.5461 19.6176 11.3367C19.3649 10.7318 18.5035 10.6408 18.1299 11.1796L18.0181 11.3409L17.9944 13.4341C17.9689 15.68 17.9692 15.6765 17.7544 16.1392C17.4628 16.7674 16.8669 17.2494 16.1626 17.4265C15.8434 17.5068 5.15375 17.5068 4.83455 17.4265C4.1303 17.2494 3.5344 16.7674 3.24275 16.1392C3.028 15.6765 3.0283 15.68 3.0028 13.4341L2.97905 11.3409L2.86725 11.1796C2.64565 10.86 2.16235 10.73 1.8072 10.8944Z"
            fill="#FFCD00"
          />
        </svg>
        อัปโหลดสลิป
      </Button>
      <input
        ref={uploadRef}
        style={{ display: "none" }}
        type="file"
        onChange={onFileChange}
      />

      <Typo.Body5 color="gray5" textAlign="center">
        รองรับนามสกุลไฟล์ .jpg, .png, .jpeg เท่านั้น
      </Typo.Body5>
    </>
  );
};

const BankContainer = ({
  expireIn,
  order,
  qrPaymentImage,
  paymentSelected,
  bankInfo,
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (paymentSelected) {
      setOpen(true);
    }
  }, [paymentSelected]);

  return (
    <ContentContainer
      padding="32px 80px"
      paddingTablet="20px"
      paddingMobile="20px"
    >
      <Collapse isOpen={paymentSelected === "qr"}>
        <Flex direction="column" align="center">
          {paymentSelected && (
            <img
              src={qrPaymentImage}
              style={{ width: "100%", maxWidth: "250px", margin: "20px 0" }}
              alt="qr_payment"
            />
          )}

          <a href={qrPaymentImage} download target="_blank" rel="noreferrer">
            <Button background="yellow" color="navy">
              แคปหน้าจอ Qr Code
            </Button>
          </a>
        </Flex>
      </Collapse>

      <Collapse isOpen={paymentSelected !== "qr"}>
        <Flex align="center">
          <Typo.Body1 width="100px" color="gray5">
            ธนาคาร
          </Typo.Body1>
          {bankInfo[paymentSelected]?.name}
        </Flex>
        <Flex align="center" margin="20px 0">
          <Typo.Body1 width="100px" color="gray5">
            ชื่อบัญชี
          </Typo.Body1>

          <Typo.Body1 color="textBlack">
            {bankInfo[paymentSelected]?.accName}
          </Typo.Body1>
        </Flex>
        <Flex align="center" justify="space-between">
          <Flex>
            <Typo.Body1 width="100px" color="gray5">
              เลขที่บัญชี
            </Typo.Body1>
            <Typo.Body1 id="btnCopyLink" color="textBlack">
              {bankInfo[paymentSelected]?.accNo}
            </Typo.Body1>
          </Flex>
          <FaRegCopy
            onClick={() => {
              const copyText = document.getElementById("btnCopyLink").innerHTML;
              window.navigator.clipboard.writeText(copyText);
            }}
            style={{ color: themes.colors.yellow, cursor: "pointer" }}
          />
        </Flex>
        <Line />
        {paymentSelected === "LAO-LAK" && (
          <Flex margin="20px 0" align="center" justify="space-between">
            <Typo.Body1 id="btnCopyLink" color="textBlack">
              ค่าเงินวันนี้
            </Typo.Body1>
            <Typo.Body2 color="red">
              {order.currency?.lak} LAK ต่อ 1 บาท
            </Typo.Body2>
          </Flex>
        )}
        <Flex align="center" justify="space-between">
          <Typo.Body1 id="btnCopyLink" color="textBlack">
            ยอดที่ต้องชำระ
          </Typo.Body1>

          <Typo.Body2 color="red">
            {new Intl.NumberFormat().format(
              bankInfo[paymentSelected]?.totalPrice
            )}{" "}
            {locale.currency[bankInfo[paymentSelected]?.currency]}
          </Typo.Body2>
        </Flex>
      </Collapse>

      <Typo.Body2 margin="24px 0 0 0" textAlign="center" color="textBlack">
        เพื่อยืนยันการสั่งซื้อให้เสร็จสมบูรณ์
      </Typo.Body2>
      <Typo.Body2 color="textBlack" textAlign="center">
        กรุณาชำระเงินและยืนยันการชำระเงินภายใน
      </Typo.Body2>
      <Typo.H6 weight="semiBold" color="navy" textAlign="center">
        <CountDown second={expireIn} />
      </Typo.H6>
      <Typo.Body5 margin="16px 0 0 0" color="gray5" textAlign="center">
        หากไม่ยืนยันการชำระเงินภายใน {localStorage.guestId ? "10" : "15"} นาที
        ระบบจะยกเลิกคำสั่งซื้อ
        <br />
        ลอตเตอรี่จะถูกนำไปจำหน่ายในเว็บไซต์ทันที
      </Typo.Body5>
    </ContentContainer>
  );
};

const PaymentSeletor = ({
  paymentSelected,
  setPaymentSelected,
  profileState,
}) => {
  return (
    <Flex
      justify="center"
      width="100%"
      height="68px"
      background="white"
      radius="60px"
      padding="8px"
    >
      {profileState.bank?.currency === "LAK" ? (
        <>
          <Button
            grow={1}
            background={paymentSelected === "LAO-LAK" && "yellow"}
            hoverBg={paymentSelected !== "LAO-LAK" && "white"}
            onClick={() => setPaymentSelected("LAO-LAK")}
            radius="60px"
          >
            <Typo.H6
              color="navy"
              weight="bold"
              hoverColor={paymentSelected !== "LAO-LAK" && "yellow"}
            >
              ชำระเป็นเงินกีบ (LAK)
            </Typo.H6>
          </Button>
          <Button
            grow={1}
            background={paymentSelected === "LAO-THB" && "yellow"}
            hoverBg={paymentSelected !== "LAO-THB" && "white"}
            onClick={() => setPaymentSelected("LAO-THB")}
            radius="60px"
          >
            <Typo.H6
              color="navy"
              weight="bold"
              hoverColor={paymentSelected !== "LAO-THB" && "yellow"}
            >
              ชำระเป็นเงินบาท (THB)
            </Typo.H6>
          </Button>
        </>
      ) : (
        <>
          <Button
            grow={1}
            background={paymentSelected === "bank" && "yellow"}
            hoverBg={paymentSelected !== "bank" && "white"}
            onClick={() => setPaymentSelected("bank")}
            radius="60px"
          >
            <Typo.H6
              color="navy"
              weight="bold"
              hoverColor={paymentSelected !== "bank" && "yellow"}
            >
              โอนเงินผ่านบัญชีธนาคาร
            </Typo.H6>
          </Button>
          <Button
            grow={1}
            background={paymentSelected !== "bank" && "yellow"}
            hoverBg={paymentSelected === "bank" && "white"}
            onClick={() => setPaymentSelected("qr")}
            radius="60px"
          >
            <Typo.H6
              color="navy"
              weight="bold"
              hoverColor={paymentSelected === "bank" && "yellow"}
            >
              แสกน QR Code
            </Typo.H6>
          </Button>
        </>
      )}
    </Flex>
  );
};
