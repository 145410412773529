import React, { useEffect, useContext } from "react";
import Context from "../Context";

export const Logout = () => {
  const { setProfileState, go, callApi } = Context();
  useEffect(() => {
    callApi("users-logout");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("adminToken");
    setProfileState({});
    go("/");
    // eslint-disable-next-line
  }, []);
  return <></>;
};
