const bankNames = require("./bankName.json");
const provinces = require("./province.json");
const configs = require("./configs.json");

const validate = {
  lotteryLength: new RegExp(/^[\d ]{0,6}$/),
  zipcode: new RegExp(/^[\d ]{0,5}$/),
  digitOnly: new RegExp(/^\d+$|^$/),
  phoneNumber: new RegExp(/^$|^[0]{0}[\d ]{1,10}$/),
};

const getThaiDateFormat = (date) => {
  const shortMonth = {
    มกราคม: "ม.ค.",
    กุมภาพันธ์: "ก.พ.",
    มีนาคม: "มี.ค.",
    เมษายน: "เม.ย.",
    พฤษภาคม: "พ.ค.",
    มิถุนายน: "มิ.ย.",
    กรกฎาคม: "ก.ค.",
    สิงหาคม: "ส.ค.",
    กันยายน: "ก.ย.",
    ตุลาคม: "ต.ค.",
    พฤศจิกายน: "พ.ย.",
    ธันวาคม: "ธ.ค.",
  };
  const fullDate = date
    .toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    .split(" ");

  return fullDate[0] + " " + shortMonth[fullDate[1]] + " " + fullDate[2];
};

const getThaiDateAndTimeFormat = (date) => {
  const shortMonth = {
    มกราคม: "ม.ค.",
    กุมภาพันธ์: "ก.พ.",
    มีนาคม: "มี.ค.",
    เมษายน: "เม.ย.",
    พฤษภาคม: "พ.ค.",
    มิถุนายน: "มิ.ย.",
    กรกฎาคม: "ก.ค.",
    สิงหาคม: "ส.ค.",
    กันยายน: "ก.ย.",
    ตุลาคม: "ต.ค.",
    พฤศจิกายน: "พ.ย.",
    ธันวาคม: "ธ.ค.",
  };
  const fullDate = date
    .toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    })
    .split(" ");

  return (
    fullDate[0] +
    " " +
    shortMonth[fullDate[1]] +
    " " +
    fullDate[2] +
    " " +
    fullDate[3] +
    " " +
    fullDate[4] +
    " น."
  );
};

const breakpoints = {
  // sm: "395px",
  sm: "430px",
  md: "864px",
  lg: "1024px",
};

const devices = {
  mobile: `max-width: ${breakpoints.sm}`,
  tablet: `max-width: ${breakpoints.md}`,
  laptop: `max-width: ${breakpoints.lg}`,
};

const statuses = {
  PENDING: "pending",
  PAID: "paid",
  APPROVE: "approved",
  CANCEL: "canceled",
  ACTIVE: "active",
  INACTIVE: "inactive",
  EXPIRED: "expired",
  HOLD: "hold",
};

const statusTranslated = {
  approved: "การสั่งซื้อเสร็จสมบูรณ์",
  pending: "รอการแนบสลิปการโอนเงิน",
  paid: "รออนุมัติคำสั่งซื้อ",
  canceled: "ยกเลิกคำสั่งซื้อ",
  active: "พร้อมใช้งาน",
  inactive: "รออนุมัติ",
  used: "ใช้งานแล้ว",
  expired: "หมดอายุ",
};

const statusColor = {
  approved: "green",
  pending: "yellow",
  paid: "orange",
  canceled: "dangerRed",
  active: "green",
  inactive: "yellow",
  used: "blue",
  expired: "red",
};

const couponTypes = {
  DISCOUNT: "discount",
  VOUCHER: "voucher",
  RESERVE: "reserve",
};

const couponTypeTranslated = {
  discount: "ส่วนลด",
  voucher: "วอยเชอร์",
  reserve: "จองเลขท้าย",
};

const donateStatusTranslated = {
  approved: "การบริจาคเสร็จสมบูรณ์",
  paid: "รอตรวจสอบยอดบริจาค",
  canceled: "ยกเลิกการบริจาค",
};

const shippingStatusesTranslate = {
  pernding: "รอดำเนินการ",
  packing: "กำลังเตรียม",
  shipped: "ส่งแล้ว",
  cancel: "ยกเลิก",
};

const userStatus = {
  active: "active",
  inactive: "inactive",
};

const deliverChannels = {
  online: "online",
  mail: "mail",
  offline: "offline",
};
const fontWeight = {
  bold: 700,
  semiBold: 600,
  regular: 500,
  light: 400,
  extraLight: 300,
};

const isHideDesktopCartFooter = (parentPath) =>
  [
    "login",
    "register",
    "cart",
    "deliverChannel",
    "receiverPerson",
    "premium-shop",
  ].includes(parentPath);
const isHideFooter = (parentPath) =>
  ["login", "register", "register-otp", "premium-shop"].includes(parentPath);
const isHideNavbar = (parentPath) => ["login", "register"].includes(parentPath);

const bankList = [
  { bankName: "ธนาคารกรุงเทพฯ", currency: "THB" },
  { bankName: "ธนาคารไทยพาณิชย์", currency: "THB" },
  { bankName: "ธนาคารกสิกรไทย", currency: "THB" },
  { bankName: "ธนาคารกรุงศรีอยุธยา", currency: "THB" },
  { bankName: "ธนาคารกรุงไทย", currency: "THB" },
  { bankName: "ธนาคารธนชาต", currency: "THB" },
  { bankName: "ธนาคารทิสโก้", currency: "THB" },
  { bankName: "ธนาคารออมสิน", currency: "THB" },
  { bankName: "ธนาคารอาคารสงเคราะห์", currency: "THB" },
  { bankName: "ธนาคารอิสลามแห่งประเทศไทย", currency: "THB" },
  { bankName: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", currency: "THB" },
  { bankName: "ธนาคารทหารไทยธนชาต", currency: "THB" },
  { bankName: "ธนาคารซีไอเอ็มบีไทย", currency: "THB" },
  { bankName: "ธนาคารยูโอบี", currency: "THB" },
  { bankName: "ธนาคารแลนด์ แอนด์ เฮ้าส์", currency: "THB" },
  { bankName: "ธนาคารเกียรตินาคินภัทร", currency: "THB" },
  { bankName: "Joint Development Bank", currency: "LAK" },
  {
    bankName: "Banque Pour Le Commerce Exterieur Lao Public",
    currency: "LAK",
  },

  { bankName: "Lao-Viet Bank Co,Ltd", currency: "LAK" },
  { bankName: "BIC Bank Lao Co., Ltd", currency: "LAK" },
  { bankName: "Lao Development Bank", currency: "LAK" },
  { bankName: "Indochina Bank LTD", currency: "LAK" },
  { bankName: "Maruhan Japan Bank Lao Co.,ltd", currency: "LAK" },
  { bankName: "Agricultural Promotion Bank", currency: "LAK" },
  {
    bankName: "Military Commercial Joint Stock Bank - Laos Branch",
    currency: "LAK",
  },
  { bankName: "KasikornBank in Lao PDR", currency: "LAK" },
  { bankName: "VietinBank Lao limited", currency: "LAK" },
  { bankName: "Acleda Bank Lao Ltd", currency: "LAK" },
  { bankName: "Banque Franco-Lao Ltd", currency: "LAK" },
  { bankName: "Phongsavanh Bank", currency: "LAK" },
  { bankName: "ST Bank LTD", currency: "LAK" },
];

const locale = {
  history_congrat: {
    TH: "ขอแสดงความยินดี คุณถูกรางวัลกับหงษ์ทอง",
    LAO: "ຂໍສະແດງຄວາມຍິນດີ ລູກຄ້າຖືກລາງວັນກັບຫົງທອງ",
  },
  currency: {
    TH: "บาท",
    LAO: "LAK",
  },
};
module.exports = {
  statuses,
  shippingStatusesTranslate,
  userStatus,
  bankNames,
  provinces,
  webConfigs: configs[process.env.REACT_APP_EVIRONMENT],
  validate,
  getThaiDateFormat,
  devices,
  fontWeight,
  isHideFooter,
  isHideNavbar,
  statusTranslated,
  deliverChannels,
  isHideDesktopCartFooter,
  donateStatusTranslated,
  bankList,
  locale,
  getThaiDateAndTimeFormat,
  statusColor,
  couponTypes,
  couponTypeTranslated,
};
