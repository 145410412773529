import React from "react";
import style from "../../constants/style";
import styled from "styled-components";
import themes from "../../themes";
import { devices } from "../../constants";
const FlexStyle = styled.div`
  ${style.css.mdRad}
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  line-height: ${(props) => props.lineHeight};
  flex-wrap: ${(props) => props.wrap};
  gap: ${(props) => props.gap};
  background: ${(props) => themes.colors[props.background]};
  color: ${(props) => themes.colors[props.color]};
  flex-basis: ${(props) => props.basis};
  flex: ${(props) => props.flex};
  border-radius: ${(props) => props.radius || "10px"};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  cursor: ${(props) => props.cursor};
  flex-grow: ${(props) => props.grow};

  filter: ${(props) => props.filter};
  transition-duration: 0.2s;
  :hover {
    transform: ${(props) => props.transform};
  }
  &.shadow1 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  &.shadow2 {
    box-shadow: ${themes.colors.secondary} 0px 5px 15px;
  }

  //ipad size
  @media only screen and (${devices.tablet}) {
    padding: ${(props) => props.paddingTablet};
  }

  @media only screen and (max-width: 640px) {
    &.responsive-column {
      flex-direction: column;
    }

    &.responsive-align-center {
      align-items: center;
    }
    &.responsive-justify-center {
      justify-content: center;
    }
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
  }
`;
export const Flex = (props) => {
  const { children, className } = props;
  return (
    <FlexStyle className={className} {...props}>
      {children}
    </FlexStyle>
  );
};
