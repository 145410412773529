import React, { useEffect, useState } from "react";
import Context from "../Context";
import { roundDateToDate, shortDate, shortTime } from "../util";
import { FlexHolder, Button, Flex } from "../components";
import styled from "styled-components";
import style from "../constants/style";

export const Closing = () => {
  const { configs, go } = Context();
  const [nextRoundDateTimeIn, setnNextRoundDateTimeIn] = useState(
    Math.max(configs.nextRoundDateTimeIn, 0)
  );
  const [nextBatchDateTimeIn, setnnextBatchDateTimeIn] = useState(
    Math.max(configs.nextBatchDateTimeIn, 0)
  );
  const [isStartedCountdown, setIsStartedCountdown] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const countDown = () => {
      if (nextRoundDateTimeIn < 1) {
        return setnNextRoundDateTimeIn(0);
      }
      setnNextRoundDateTimeIn((old) => --old);
      setTimeout(() => {
        countDown();
      }, 1000);
    };
    if (!isStartedCountdown) {
      setIsStartedCountdown(true);
      countDown();
    }
  }, []);

  if (configs.isOpen?.store) {
    go("/");
  }
  const translations = {
    closing: "ปิดขายชั่วคราว",
    maintenance: "ปรับปรุงระบบ",
    closed: "รองวดหน้า",
    adding: "เพิ่มหวย",
  };
  return (
    <>
      <FlexHolder>
        <Wrapper>
          <div className="relative h-full mt-[20dvh] flex flex-col justify-center">
            {/* <h1 style={{ marginBottom: "0" }}>{translations[configs.roundNotice]}</h1> */}
            <Landscape
              src={"./images/closeShopBackground_landscape.jpg"}
              width={"100%"}
            />
            <Horizontal
              src={"./images/closeShopBackground.jpg"}
              width={"100%"}
            />

            <TextRoundDate>
              งวดวันที่{" "}
              {roundDateToDate(configs.nextRoundDate) ||
                roundDateToDate(configs.roundDate)}
            </TextRoundDate>

            {
              //1 มกราคม 2565
            }
            <TextAppointment>
              วันเปิดแผง {shortDate(configs.nextRoundDateTime)} เวลา{" "}
              {shortTime(configs.nextRoundDateTime)} นี้
            </TextAppointment>

            <TimeBoxes sec={nextRoundDateTimeIn}></TimeBoxes>
          </div>
        </Wrapper>
        <Flex justify="center">
          <Button
            background="yellow"
            weight="bold"
            size="18px"
            margin="25px 20px"
            maxWidth="760px"
            width="100%"
            onClick={() => go("/coupon/reserve_coupon")}
          >
            จองสลาก
          </Button>
        </Flex>
      </FlexHolder>
    </>
  );
};

export const TimeBoxes = ({ sec }) => {
  const day = (sec / (60 * 60 * 24)) | 0;
  const hour = (sec / (60 * 60)) % 24 | 0;
  const min = (sec / 60) % 60 | 0;
  const second = sec % 60 || 0;

  return (
    <TextBoxes style={{ display: "flex", position: "absolute" }}>
      <>
        <Box>
          {/* <div style={{ verticalAlign: "middle" }}> */}

          <Time>{day}</Time>
          <Unit>วัน</Unit>
          {/* </div> */}
        </Box>
        <Box>
          <Time>{hour}</Time>
          <Unit>ชั่วโมง</Unit>
        </Box>
        <Box>
          <Time>{min}</Time>
          <Unit>นาที</Unit>
        </Box>
        <Box color="rgba(5,39,126,1)">
          <Time>{second}</Time>
          <Unit>วินาที</Unit>
        </Box>
      </>
    </TextBoxes>
  );
};
const Time = styled.p`
  margin-top: 2.2vw;
  font-size: 5vw;
  font-weight: 700;
  margin-bottom: 0;

  text-align: center;
  @media screen and (min-width: 800px) {
    margin-top: calc(8.4 * 2px);
    font-size: calc(8.4 * 5px);
  }
`;

const Unit = styled.p`
  margin-top: -1.2vw;
  font-size: 2vw;
  text-align: center;
  @media screen and (min-width: 800px) {
    margin-top: calc(5 * -2px);
    font-size: calc(3.4 * 5px);
  }
`;
const Box = styled.div`
  color: ${(props) => props.color || "white"};
  width: 16.3vw;
  margin-top: 4vw;
  padding: 0 10px;
  /* margin: 1.8vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: ${style.colors.darkBlue}; */
  @media screen and (max-width: 450px) {
    /* width: 21.7vw; */
    margin-top: 14vw;
    padding: 0 3px;
    width: calc(9.6 * 2vw);
  }
  @media screen and (max-width: 250px) {
    /* width: 21.7vw; */
    width: 20vw;
  }
  @media screen and (min-width: 800px) {
    margin-top: calc(30 * 1px);
    margin-left: 0%;
    max-width: 130px;
    max-height: calc(8.4 * 14px);
  }
  /* @media screen and (min-width: 840px) {
    margin-top: calc(15 * 1px);
    margin-left: calc(25 * 1px);
    max-width: calc(9.5 * 14px);
    max-height: calc(8.4 * 14px);
  } */
`;
const TextCut = styled.div`
  position: absolute;
  color: white;
  font-weight: bold;
`;
const TextRoundDate = styled(TextCut)`
  top: 24%;
  right: 30%;
  text-align: center;
  color: rgb(0, 120, 255);
  font-size: 3vw;
  @media screen and (min-width: 840px) {
    font-size: 25.2px;
  }
  @media screen and (max-width: 450px) {
    top: 38.25%;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    text-align: center;
    display: inline;
    font-size: 4vw;
  }
`;
const TextAppointment = styled(TextCut)`
  top: 36%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  font-size: 2vw;
  @media screen and (min-width: 840px) {
    font-size: 15px;
  }
  @media screen and (max-width: 450px) {
    top: 45%;
    left: 50%;
    width: 100%;
    display: inline;
    font-size: 2.9vw;
  }
`;
const TextBoxes = styled.div`
  transform: translateX(-50%);

  top: 40%;
  left: 50%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 450px) {
    top: 45.5%;
    font-size: 4vw;
  }
  @media screen and (min-width: 840px) {
    font-size: 18.3px;
  }
`;
const TextTime = styled(TextCut)`
  top: 28%;
  right: 3.4%;
  text-align: right;
  font-size: 3vw;
  @media screen and (min-width: 840px) {
    font-size: 25px;
  }
  @media screen and (max-width: 450px) {
    top: 40.75%;
    left: 5%;
    text-align: left;
    display: inline;
    font-size: 5vw;
  }
`;
const Landscape = styled.img`
  display: block;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;
const Horizontal = styled.img`
  display: block;
  @media screen and (min-width: 450px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  padding-bottom: 0;

  max-width: 800px;
  ${style.css.mdRad}
  margin: auto;
`;
