import styled from "styled-components";
import themes from "../../themes";

const LineStyle = styled.div`
  border-bottom: ${(props) =>
    props.border || ` 2px dashed ${themes.colors.gray2}`};
  margin: ${(props) => props.margin || "20px 0"};
`;

export const Line = ({ border, margin }) => {
  return <LineStyle border={border} margin={margin} />;
};
