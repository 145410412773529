import { FormGroup, Input } from "reactstrap";
import styled from "styled-components";
import themes from "../../themes";
const FormGroupStyled = styled(FormGroup)``;

const InputStyled = styled(Input)`
  cursor: pointer;
  transform: scale(1.25);
  background-color: ${themes.colors.gray2};
  border: 1px solid ${themes.colors.gray2};

  :checked {
    background-color: ${themes.colors.yellow};
    border: 1px solid ${themes.colors.yellow};
  }
`;
export const Switch = ({ checked, onClick }) => {
  return (
    <FormGroupStyled switch>
      <InputStyled type="switch" checked={checked} onClick={() => onClick()} />
    </FormGroupStyled>
  );
};
