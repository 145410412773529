import React, { useEffect, useState } from "react";
import Context from "../Context";
import {
  shippingStatusesTranslate,
  statusTranslated,
  statusColor,
  deliverChannels,
  statuses,
  donateStatusTranslated,
  getThaiDateAndTimeFormat,
} from "../constants";
import { Table } from "reactstrap";
import themes from "../themes";
import {
  Button,
  Flex,
  Typo,
  Div,
  ContentContainer,
  Grid,
  Badge,
  StackImages,
  Line,
} from ".";
import { shortDate, shortTime, roundDateToDate, CountDown } from "../util";
import { Collapse } from "reactstrap";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { or } from "ramda";

const deliverChannelTranslations = {
  online: "ฝากไว้กับหงษ์ทอง",
  mail: "ส่งด่วน EMS",
  offline: "มารับเองที่ สำนักงานใหญ่หงษ์ทอง",
};

export const CouponContainer = ({ order, product }) => {
  return (
    <ContentContainer
      margin="0 0 20px 0"
      minHeight="0"
      padding="32px 60px 32px 60px"
      paddingMobile="16px"
    >
      <Flex align="center" gap="8px">
        <img src="/images/logo_single.png" alt="logo_single" width="40px" />
        <Typo.Body1 color="textBlack">หงษ์ทอง ลอตเตอรี่ออนไลน์</Typo.Body1>
      </Flex>
      <Line border={`2px solid ${themes.colors.gray2}`} />
      <Grid
        columns={2}
        justify="left"
        align="flex-start"
        margin="0 0 20px 0"
        gap="20px"
      >
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          width="100%"
          justify="left"
          align="flex-start"
          gap="20px"
        >
          <Div>
            <Typo.Body5 color="gray5">หมายเลขออเดอร์</Typo.Body5>
            <Typo.Body3 margin="5px 0 0 0" color="textBlack">
              {order?.id.slice(-5).toUpperCase()}
            </Typo.Body3>
          </Div>

          {product?.name && (
            <Div>
              <Typo.Body5 color="gray5">สินค้า</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {product?.name}
              </Typo.Body3>
            </Div>
          )}
          {order?.coupon?.name && (
            <Div>
              <Typo.Body5 color="gray5">สินค้า</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.coupon?.name}
              </Typo.Body3>
            </Div>
          )}
        </Grid>
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          width="100%"
          justify="left"
          align="flex-start"
          gap="20px"
        >
          <Div>
            <Typo.Body5 color="gray5">วันที่</Typo.Body5>
            <Typo.Body3 margin="5px 0 0 0" color="textBlack">
              {getThaiDateAndTimeFormat(new Date(order?.createdAt))}
            </Typo.Body3>
          </Div>
          <Div orderTablet={-1}>
            <Badge background={statusColor[order?.status]}>
              {statusTranslated[order?.status]}
            </Badge>
          </Div>
        </Grid>
      </Grid>

      <Grid
        columns={2}
        columnsTablet={1}
        columnsIpad={1}
        columnsMobile={1}
        align="flex-start"
        gap="20px"
      >
        <Grid
          columns={2}
          width="100%"
          align="flex-start"
          justify="left"
          gap="20px"
        >
          {order?.coupon?.status && (
            <Div>
              <Typo.Body5 color="gray5" margin="0 0 5px 0">
                สถานะการใช้งาน
              </Typo.Body5>
              <Badge background={statusColor[order?.coupon?.status]}>
                {statusTranslated[order?.coupon?.status]}
              </Badge>
            </Div>
          )}
          {order?.coupon?.usedBy && (
            <Div>
              <Typo.Body5 color="gray5">หมายเลขออเดอร์ที่ถูกใช้</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.coupon?.usedBy}
              </Typo.Body3>
            </Div>
          )}
          {order?.receiver?.name && (
            <Div>
              <Typo.Body5 color="gray5">ชื่อ-นามสกุล ผู้รับ</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.receiver?.name}
              </Typo.Body3>
            </Div>
          )}
          {order?.receiver?.phone && (
            <Div>
              <Typo.Body5 color="gray5">เบอร์โทรศัพท์ผู้รับ</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.receiver?.phone}
              </Typo.Body3>
            </Div>
          )}
          {order?.receiver?.address && (
            <Div>
              <Typo.Body5 color="gray5">ที่อยู่ผู้รับ</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.receiver?.address}
              </Typo.Body3>
            </Div>
          )}
          {order?.payPoint !== undefined && (
            <Div>
              <Typo.Body5 color="gray5">จำนวนคะแนนที่หัก</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.payPoint} คะแนน
              </Typo.Body3>
            </Div>
          )}{" "}
          {
            <Div>
              <Typo.Body5 color="gray5">ราคา</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.price?.toLocaleString()} บาท
              </Typo.Body3>
            </Div>
          }
          {order?.isReward && (
            <Div>
              <Typo.Body5 color="gray5">รางวัลภารกิจ</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.missionOrder.slice(-5).toUpperCase() || "-"}
              </Typo.Body3>
            </Div>
          )}
          {order?.coupon?.expireAtDate && (
            <Div>
              <Typo.Body5 color="gray5">วันหมดอายุ</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {getThaiDateAndTimeFormat(
                  new Date(order?.coupon?.expireAtDate)
                )}
              </Typo.Body3>
            </Div>
          )}
          {
            <Div>
              <Typo.Body5 color="gray5" margin="0 0 5px 0">
                หลักฐานการชำระเงิน
              </Typo.Body5>
              {order?.slip ? (
                <img
                  onClick={() => window.open(order?.slip, "_blank")}
                  src={order?.slip}
                  alt={"slip"}
                  style={{
                    width: "150px",
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                  ไม่พบการแนบสลิป
                </Typo.Body3>
              )}
            </Div>
          }
          {order?.payPoint && (
            <Div>
              <Typo.Body5 color="gray5">จำนวนคะแนนที่หัก</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.payPoint} คะแนน
              </Typo.Body3>
            </Div>
          )}
        </Grid>
        {order.product?.image && (
          <Flex direction="column" width="100%" gap="5px">
            <Typo.Body5 color="gray5">รูปภาพสินค้า</Typo.Body5>
            <Div maxWidth="150px">
              <img
                src={order.product?.image}
                alt="order"
                style={{ width: "100%" }}
              />
            </Div>
          </Flex>
        )}
      </Grid>
    </ContentContainer>
  );
};

export const OrderContainer = ({ order = {}, showImage }) => {
  const { go, callApi } = Context();
  const [expireIn, setExpireIn] = useState(30 * 60);
  const [initCountDown, setInitCountDown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (order?.status === "pending") {
      setExpireIn(parseInt((order?.expireAt - Date.now()) / 1000));
      const countDown = () =>
        setTimeout(() => {
          setExpireIn((x) => {
            if (x >= 1) {
              return x - 1;
            } else {
              forceCanceledOrder();
              setExpireIn(0);
            }
          });
          countDown();
        }, 1000);

      if (!initCountDown) {
        setInitCountDown(true);
        countDown();
      }
      return () => clearTimeout(countDown);
    }
  }, [order?.status]);

  const forceCanceledOrder = async () => {
    const result = await callApi("orders-forceCanceledOrder", {
      orderId: order.id,
      guestId: localStorage.guestId || false,
      from: "paymentDetail line:78",
    });
  };

  const hideText = (text) => {
    return text?.replace(
      text?.substring(text?.length / 2, text?.length),
      Array(Math.floor(text?.length / 2)).join("*")
    );
  };

  return (
    <ContentContainer
      margin="0 0 20px 0"
      minHeight="0"
      padding="32px 60px 10px 60px"
      paddingMobile="16px"
    >
      <Flex align="center" gap="8px">
        <img src="/images/logo_single.png" alt="logo_single" width="40px" />
        <Typo.Body1 color="textBlack">หงษ์ทอง ลอตเตอรี่ออนไลน์</Typo.Body1>
      </Flex>
      <Line border={`2px solid ${themes.colors.gray2}`} />
      <Grid
        columns={2}
        justify="left"
        align="flex-start"
        margin="0 0 20px 0"
        gap="20px"
      >
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          width="100%"
          justify="left"
          align="flex-start"
          gap="20px"
          justifyMobile="flex-start"
        >
          <Div>
            <Typo.Body5 color="gray5">หมายเลขออเดอร์</Typo.Body5>
            <Typo.Body3 margin="5px 0 0 0" color="textBlack">
              {order?.shortId}
            </Typo.Body3>
          </Div>

          <Div>
            <Typo.Body5 color="gray5">จำนวนใบที่สั่งซื้อ</Typo.Body5>
            <Typo.Body3 margin="5px 0 0 0" color="textBlack">
              {order?.size} ใบ
            </Typo.Body3>
          </Div>
        </Grid>
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          width="100%"
          justify="left"
          align="flex-start"
          gap="20px"
          justifyMobile="flex-start"
        >
          <Div>
            <Typo.Body5 color="gray5">งวดประจำวันที่</Typo.Body5>
            <Typo.Body3 margin="5px 0 0 0" color="textBlack">
              {roundDateToDate(order?.roundDate)}
            </Typo.Body3>
          </Div>
          <Div orderTablet={-1}>
            <Badge
              background={
                order?.deliverChannel === deliverChannels.offline &&
                order?.status === statuses.PENDING
                  ? "red"
                  : statusColor[order?.status]
              }
            >
              {order?.deliverChannel === deliverChannels.offline &&
              order?.status === statuses.PENDING
                ? "กรุณาติดต่อแอดมิน"
                : statusTranslated[order?.status]}
            </Badge>
          </Div>
        </Grid>
      </Grid>
      <Collapse isOpen={isOpen}>
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          align="flex-start"
          gap="20px"
        >
          <Grid
            columns={2}
            width="100%"
            align="flex-start"
            justify="left"
            gap="20px"
            justifyMobile="flex-start"
          >
            <Div>
              <Typo.Body5 color="gray5">จำนวนเงิน</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order?.coupon && <s>{order?.totalPriceBeforeDiscount}</s>}{" "}
                {order?.totalPrice?.toLocaleString()} บาท
              </Typo.Body3>
            </Div>
            {order?.coupon && (
              <Div>
                <Typo.Body5 color="gray5">คูปองที่ใช้</Typo.Body5>
                <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                  {order?.coupon?.coupon?.name}
                </Typo.Body3>
              </Div>
            )}

            <Div>
              <Typo.Body5 color="gray5">ช่องทางการจ่ายเงิน</Typo.Body5>
              {order?.deliverChannel === deliverChannels.offline ? (
                <Typo.Body3 margin="5px 0 0 0" color="red">
                  กรุณาติดต่อแแอดมิน
                </Typo.Body3>
              ) : (
                <img
                  src={
                    order?.paymentChannel === "qr"
                      ? "/images/qr.png"
                      : "/images/banktransfer.png"
                  }
                  width="100px"
                  alt="-"
                />
              )}
            </Div>
            <Div>
              <Typo.Body5 color="gray5">วัน - เวลาที่ซื้อ</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {shortDate(order?.createdAt)}
                <br />
                เวลา {shortTime(order?.createdAt)}
              </Typo.Body3>
            </Div>

            <Div>
              <Typo.Body5 color="gray5">ทางเลือกการสั่งซื้อ</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {deliverChannelTranslations[order?.deliverChannel]}
              </Typo.Body3>
            </Div>
            {order.isGuest && (
              <Div>
                <Typo.Body5 color="gray5">ชื่อผู้ซื้อ</Typo.Body5>
                <Typo.Body6 margin="5px 0 0 0" color="textBlack">
                  {hideText(order?.firstName)} {hideText(order?.lastName)}
                </Typo.Body6>
              </Div>
            )}
            {order.isGuest && (
              <Div>
                <Typo.Body5 color="gray5">เบอร์โทรศัพท์</Typo.Body5>
                <Typo.Body6 margin="5px 0 0 0" color="textBlack">
                  {hideText(order?.phone)}
                </Typo.Body6>
              </Div>
            )}
            {order?.isBuyForSomeoneElse && (
              <Div>
                <Typo.Body5 color="gray5">ข้อมูลผู้รับ</Typo.Body5>
                <Typo.Body6 margin="5px 0 0 0" color="textBlack">
                  ชื่อ: {order?.buyFor.firstName} {order?.buyFor.lastName}
                </Typo.Body6>
                <Typo.Body6 margin="5px 0 0 0" color="textBlack">
                  เบอร์: {order?.buyFor.phone}
                </Typo.Body6>
                <Typo.Body6 margin="5px 0 0 0" color="textBlack">
                  ธนาคาร: {order?.buyFor.bank}
                </Typo.Body6>
                <Typo.Body6 margin="5px 0 0 0" color="textBlack">
                  เลขที่บัญชี: {order?.buyFor.accNo}
                </Typo.Body6>
                <Typo.Body6 margin="5px 0 0 0" color="textBlack">
                  ชื่อบัญชี: {order?.buyFor.accName}
                </Typo.Body6>
              </Div>
            )}
            {order?.deliverChannel === "mail" && (
              <>
                <Div>
                  <Typo.Body5 color="gray5">รอบวันที่จัดส่ง</Typo.Body5>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {roundDateToDate(order?.shippingDate)}
                  </Typo.Body3>
                </Div>
                <Div>
                  <Typo.Body5 color="gray5">สถานะการจัดส่ง</Typo.Body5>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {shippingStatusesTranslate[order?.shippingStatus] || "-"}
                  </Typo.Body3>
                </Div>
                <Div>
                  <Typo.Body5 color="gray5">ชื่อ-ที่อยู่จัดส่ง</Typo.Body5>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {order?.address?.addr}
                  </Typo.Body3>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {order?.address?.province}
                  </Typo.Body3>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {order?.address?.zipcode}
                  </Typo.Body3>
                </Div>
                <Div>
                  <Typo.Body5 color="gray5">เลขพัสดุ</Typo.Body5>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {order?.shippingTrack
                      ? `ไปรษณีย์ไทย ${order?.shippingTrack}`
                      : "-"}
                  </Typo.Body3>
                </Div>
              </>
            )}
            {order?.paymentChannel !== "qr" && (
              <Div>
                <Typo.Body5 color="gray5">หลักฐานการชำระเงิน</Typo.Body5>
                {order?.slips?.length > 0 ? (
                  <img
                    onClick={() =>
                      window.open(
                        order?.slips[order?.slips?.length - 1],
                        "_blank"
                      )
                    }
                    src={order?.slips[order?.slips?.length - 1]}
                    alt={"slip"}
                    style={{
                      width: "200px",
                      verticalAlign: "middle",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    ไม่พบการแนบสลิป
                  </Typo.Body3>
                )}
              </Div>
            )}
            {initCountDown && (
              <Div>
                <Typo.Body5 color="gray5">คำสั่งซื้อจะหมดอายุใน</Typo.Body5>
                <Typo.Body1 margin="5px 0 0 0" color="textBlack">
                  {CountDown({ second: expireIn, zeroRefresh: true })}
                </Typo.Body1>
              </Div>
            )}
          </Grid>
          <Flex direction="column" width="100%" gap="20px">
            <Div maxWidth="336px">
              {showImage ? (
                <StackImages
                  urls={order?.lotteries?.map((x) => [
                    x.image,
                    x.isMagicNumber,
                    x.isLabubuNumber,
                  ])}
                  numbers={order?.lotteries
                    ?.reduce((acc, lotto) => {
                      if (acc.includes(lotto)) {
                      }
                      return acc;
                    }, [])
                    ?.map((x) => [x.number])}
                />
              ) : (
                <>
                  <Table bordered>
                    <thead>
                      <tr>
                        <td>ลำดับที่</td>
                        <td>หมายเลข</td>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.lotteries.map((x, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <th>{x.number}</th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </Div>
          </Flex>
        </Grid>
      </Collapse>
      {order?.status === statuses.PENDING &&
        order?.deliverChannel === deliverChannels.offline && (
          <Button background="red" margin="25px 0 0 0">
            ติดต่อเจ้าหน้าที่โทร 02-246-6666
          </Button>
        )}
      {order?.status === statuses.PENDING &&
        order?.deliverChannel !== deliverChannels.offline && (
          <Button
            onClick={() => {
              order?.status === statuses.PENDING
                ? go("/payment/" + order?.id)
                : go("/order/" + order?.id);
            }}
            background="gradient2"
            margin="1em 0"
            padding="8px 30px"
            color="white"
            weight={700}
          >
            ดำเนินการชำระเงิน
          </Button>
        )}
      <Button
        onClick={() => setIsOpen(!isOpen)}
        background="transparent"
        hoverBg="transparent"
        hoverColor="yellow"
        margin="20px auto 0 auto"
        color="navy"
        weight={700}
      >
        {isOpen ? (
          <Flex gap="5px" align="center">
            ดูน้อยลง <IoIosArrowUp />
          </Flex>
        ) : (
          <Flex gap="5px" align="center">
            ดูทั้งหมด <IoIosArrowDown />
          </Flex>
        )}
      </Button>
    </ContentContainer>
  );
};

export const DonateOrderContainer = ({ order = {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ContentContainer
      margin="0 0 20px 0"
      minHeight="0"
      padding="32px 60px 10px 60px"
      paddingMobile="16px"
    >
      <Flex align="center" gap="8px">
        <img src="/images/logo_single.png" alt="logo_single" width="40px" />
        <Typo.Body1 color="textBlack">หงษ์ทอง ลอตเตอรี่ออนไลน์</Typo.Body1>
      </Flex>
      <Line border={`2px solid ${themes.colors.gray2}`} />
      <Grid
        columns={2}
        justify="left"
        align="flex-start"
        margin="0 0 20px 0"
        gap="20px"
      >
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          width="100%"
          justify="left"
          align="flex-start"
          gap="20px"
          justifyMobile="flex-start"
        >
          <Div>
            <Typo.Body5 color="gray5">หมายเลขออเดอร์</Typo.Body5>
            <Typo.Body3 margin="5px 0 0 0" color="textBlack">
              {order?.shortId}
            </Typo.Body3>
          </Div>

          <Div>
            <Typo.Body5 color="gray5">
              {order.isDonateWithCash ? "จำนวนเงิน" : "จำนวนคะแนน"}
            </Typo.Body5>
            {order.status === statuses.PAID ? (
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                กำลังตรวจสอบยอดบริจาค
              </Typo.Body3>
            ) : (
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {new Intl.NumberFormat().format(
                  order?.payPoint || order?.donateAmount
                )}{" "}
                {order.isDonateWithCash ? "บาท" : "คะแนน"}
              </Typo.Body3>
            )}
          </Div>
        </Grid>
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          width="100%"
          justify="left"
          align="flex-start"
          gap="20px"
          justifyMobile="flex-start"
        >
          <Div>
            <Typo.Body5 color="gray5">ช่องทางการบริจาค</Typo.Body5>
            {order.isDonateWithCash ? (
              <Typo.Body3 margin="5px 0 0 0" color="red">
                การโอนเงิน
              </Typo.Body3>
            ) : (
              <Typo.Body3 margin="5px 0 0 0" color="red">
                การหักคะแนน
              </Typo.Body3>
            )}
          </Div>
          <Div orderTablet={-1}>
            <Badge
              background={
                order?.deliverChannel === deliverChannels.offline &&
                order?.status === statuses.PENDING
                  ? "red"
                  : statusColor[order?.status]
              }
            >
              {order?.deliverChannel === deliverChannels.offline &&
              order?.status === statuses.PENDING
                ? "กรุณาติดต่อแอดมิน"
                : donateStatusTranslated[order?.status]}
            </Badge>
          </Div>
        </Grid>
      </Grid>
      <Collapse isOpen={isOpen}>
        <Grid
          columns={2}
          columnsTablet={1}
          columnsIpad={1}
          columnsMobile={1}
          align="flex-start"
          gap="20px"
        >
          <Grid
            columns={2}
            width="100%"
            align="flex-start"
            justify="left"
            gap="20px"
            justifyMobile="flex-start"
          >
            {!order.isDonateWithCash && (
              <>
                <Div>
                  <Typo.Body5 color="gray5">คะแนนก่อนบริจาค</Typo.Body5>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {new Intl.NumberFormat().format(order.pointBeforeCharge)}
                  </Typo.Body3>
                </Div>
                <Div>
                  <Typo.Body5 color="gray5">คะแนนหลังบริจาค</Typo.Body5>
                  <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                    {new Intl.NumberFormat().format(order.pointAfterCharge)}
                  </Typo.Body3>
                </Div>
              </>
            )}
            <Div>
              <Typo.Body5 color="gray5">สถานที่บริจาค</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {order.donate.name || order.donate.for?.place}
              </Typo.Body3>
            </Div>
            <Div>
              <Typo.Body5 color="gray5">วัน - เวลาที่บริจาค</Typo.Body5>
              <Typo.Body3 margin="5px 0 0 0" color="textBlack">
                {shortDate(order?.createdAt)}
                <br />
                เวลา {shortTime(order?.createdAt)}
              </Typo.Body3>
            </Div>
            {order.isDonateWithCash && (
              <Div>
                <Typo.Body5 color="gray5">หลักฐานการโอนเงิน</Typo.Body5>
                <img src={order.slip} alt="slip" style={{ width: "150px" }} />
              </Div>
            )}
          </Grid>
        </Grid>
      </Collapse>

      <Button
        onClick={() => setIsOpen(!isOpen)}
        background="transparent"
        hoverBg="transparent"
        hoverColor="yellow"
        margin="20px auto 0 auto"
        color="navy"
        weight={700}
      >
        {isOpen ? (
          <Flex gap="5px" align="center">
            ดูน้อยลง <IoIosArrowUp />
          </Flex>
        ) : (
          <Flex gap="5px" align="center">
            ดูทั้งหมด <IoIosArrowDown />
          </Flex>
        )}
      </Button>
    </ContentContainer>
  );
};
