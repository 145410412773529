import React, { useState } from "react";
import styled from "styled-components";
import style from "../constants/style";
import SingleDropdownOptionSc from "./SingleDropdownOptionSc";

const DropDownBaseHolder = styled.div`
  display: inline-block;
  margin: 5px;
  margin-bottom: 0px;
  ${(props) => !props.center && `margin-right: 15px;`}
  cursor: pointer;
  text-align: left;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border-collapse: collapse;
  vertical-align: top;
`;
const DropDownImage = styled.div`
  background-image: url("/dropDown.png");
  background-size: cover;
  position: absolute;
  right: 2px;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
const DropDownImageUp = styled.div`
  background-image: url("/dropDown.png");
  background-size: cover;
  position: absolute;
  right: 2px;
  width: 20px;
  height: 20px;
  top: 0;
  transform: rotate(180deg);
`;
const DropDownImageDown = styled.div`
  background-image: url("/dropDown.png");
  background-size: cover;
  position: absolute;
  right: 2px;
  width: 20px;
  height: 20px;
  bottom: 0;
`;
const CurrentPick = styled(SingleDropdownOptionSc)`
  padding-right: 25px;
  ${style.css.smRad}
  position: relative;
`;
const CurrentPickColor = styled(SingleDropdownOptionSc)`
  padding-right: 10px;
  ${(props) => `background-color: ${props.bgColor || "white"}`};
`;
const OptionHolder = styled.div`
  ${style.css.smRad}
  box-shadow: rgba(0,0,0,0.5) 0 5px 5px 5px;
  position: absolute;
  z-index: 9999;
`;
export const InputBase = ({
  center,
  children,
  currentPicked,
  bgColor,
  type,
  handleClick,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleActiveClick = () => {
    setIsActive((x) => !x);
  };

  return (
    <DropDownBaseHolder
      center={center}
      style={{ minWidth: "57px" }}
      onMouseLeave={() => setIsActive(false)}
    >
      <CurrentPick onClick={type !== "number" ? handleActiveClick : undefined}>
        {!bgColor && currentPicked}
        {type === "number" ? (
          <>
            <DropDownImageUp onClick={() => handleClick(1)} />
            <DropDownImageDown onClick={() => handleClick(-1)} />
          </>
        ) : (
          <DropDownImage />
        )}
        {bgColor && <CurrentPickColor bgColor={bgColor}></CurrentPickColor>}
      </CurrentPick>
      {isActive && (
        <OptionHolder onClick={() => setIsActive(false)}>
          {children}
        </OptionHolder>
      )}
    </DropDownBaseHolder>
  );
};
