import React, { useEffect, useState } from "react";
import Context from "../Context";
import style from "../constants/style";
import styled from "styled-components";
import { devices } from "../constants";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { isHideFooter, isHideNavbar } from "../constants";
const Gate = ({
  isRequireLogin = true,
  isRequireOpening = false,
  isAllowGuest = false,
  Component,
  bg,
}) => {
  const { profileState, hideModal, configs, go } = Context();
  const [isPassLoginGate, setIsPassLoginGateset] = useState(false);
  const [isPassOpeningGate, setIsPassOpeningGate] = useState(true);

  let location = useLocation();

  const currentPath = location.pathname;
  const parentPath = currentPath.split("/")[1];

  useEffect(() => {
    if (isRequireOpening && !configs.isOpen.store) {
      go("/closing");
    }
    if (isRequireLogin && !profileState.userNo) {
      if (!isAllowGuest) {
        go("/login");
      }
      // showModalLogin({ isShow: true });
    } else {
      hideModal();
    }

    setIsPassLoginGateset(
      profileState.userNo || localStorage.guestId || !isRequireLogin
    );
    setIsPassOpeningGate(!isRequireOpening || configs.isOpen.store);
  }, []);

  useEffect(() => {
    if (profileState.userNo && !profileState.activated && isRequireLogin) {
      go("/register-otp");
    }
  }, [profileState]);

  if (isPassLoginGate && isPassOpeningGate) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ padding: "0" }}
      >
        <Body
          bg={bg}
          className={`${isHideFooter(parentPath) && "hide-footer"} 
            ${isHideNavbar(parentPath) && "hide-navbar"}`}
        >
          <Component />
        </Body>
      </motion.div>
    );
  } else {
    return <Body bg={"blueGradian"}></Body>;
  }
};
export default Gate;

const Body = styled.div`
  min-height: 100dvh;
  /* min-height: 100vh; */
  width: 100%;
  padding-top: 96px;
  background: ${(props) => props.bg};
  display: flex;
  justify-content: center;

  &.hide-footer {
    min-height: calc(100dvh - 96px);
  }
  @media only screen and (${devices.tablet}) {
    padding-bottom: 117px;
    padding-top: 68px;
    &.hide-navbar {
      padding-top: 0;
    }
    &.hide-footer {
      min-height: 100vh;
      padding-bottom: 0;
    }
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    padding-bottom: 98px;
    &.hide-navbar {
      padding-top: 0;
    }
    &.hide-footer {
      min-height: 100vh;
      padding-bottom: 0;
    }
  }

  /* ${(props) => style.css[props.bg]} */
`;
