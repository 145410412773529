import React, { useState, useRef, useContext } from "react";
import styled from "styled-components";
import style from "../constants/style";
import Context from "../Context";
import { useEffect } from "react";
import { StackImages } from "./index";
import themes from "../themes";
import { Loading } from "../components";
import { FiShoppingBag, FiShoppingCart } from "react-icons/fi";
import { Typo } from "./index";
import Lottie from "lottie-react";
import lottieCart from "../constants/lottie/cart.json";
import lottieTick from "../constants/lottie/tick.json";
import lottieRemove from "../constants/lottie/remove.json";

export const ShopItem = ({
  number,
  size,
  lotteries,
  isGuest,
  isMagicNumber,
  isLabubuNumber,
}) => {
  const [status, setStatus] = useState("available");
  const navItemScRef = useRef(null);
  const [loterryWidth, setLotteryWidth] = useState(300);
  const [onHover, setOnHover] = useState(false);
  const { callApi, configs, go, toast, cart, setCart, profileState } =
    Context();

  useEffect(() => {
    setLotteryWidth(navItemScRef.current?.clientWidth || 300);
  }, [navItemScRef.current?.clientWidth]);

  const addCart = async () => {
    const isGuest = !profileState.userNo;
    const guestId = localStorage.guestId || false;
    // if (cart?.size >= 201) {
    //   return toast("จำกัดการซื้อสูงสุดไม่เกิน 200");
    // }

    // if (!localStorage.getItem("accessToken")) {
    //   return go("/login");
    // } else if (isGuest) {
    //   window.location.pathname.includes("/search") && window.location.reload();
    // }
    if (status === "inCart") {
      setStatus("loading");
      const cartResult = await callApi(
        "carts-removeCart",
        { number, guestId },
        { notShowLoading: true }
      );
      const { size, expireIn } = cartResult;
      if (cartResult !== false) {
        setStatus("available");
        setCart((prevState) => ({
          deliverChannel: prevState.deliverChannel || undefined,
          size,
          expireIn: expireIn,
        }));
      }
    } else if (status === "available") {
      setStatus("loading");
      const result = isGuest
        ? await callApi(
            "carts-addGuestCart",
            { number, guestId: guestId },
            { notShowLoading: true }
          )
        : await callApi("carts-addCart", { number }, { notShowLoading: true });

      const { size, expireIn } = result;

      if (!result.status) {
        setStatus("available");
        setOnHover(false);
        return;
      } else {
        if (result.guestId) {
          localStorage.setItem("guestId", result.guestId);
        }

        setStatus("inCart");
        setCart((prevState) => ({
          deliverChannel: prevState?.deliverChannel || undefined,
          size,
          expireIn: expireIn,
        }));
      }
    }
  };

  return (
    <LottoWrapper
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      to={"#"}
      onClick={() => addCart() && setOnHover(false)}
      ref={navItemScRef}
    >
      <LottoContainer>
        <LottoContainer>
          <StackImages
            limitHeight={true}
            status={status}
            isDisable={true}
            urls={lotteries ? lotteries.map((x) => [x.image]) : []}
          ></StackImages>
          <TagShape isMagicNumber={isMagicNumber}>
            {isMagicNumber ? (
              <TagText isMagicNumber={isMagicNumber} fs={loterryWidth}>
                <img
                  src={configs.magicNumber.image}
                  alt="magicNumber"
                  style={{ width: "70%" }}
                />
                {lotteries.length} ใบ
                <br />
                {/* {lotteries.length * 6}ล. */}
              </TagText>
            ) : isLabubuNumber ? (
              <TagText isMagicNumber={isLabubuNumber} fs={loterryWidth}>
                <img
                  src={configs.labubuNumber.image}
                  alt="magicNumber"
                  style={{ width: "90%" }}
                />
                {lotteries.length} ใบ
                <br />
                {/* {lotteries.length * 6}ล. */}
              </TagText>
            ) : (
              <TagText fs={loterryWidth}>
                {lotteries.length} ใบ
                <br />
                {lotteries.length * 6}ล.
              </TagText>
            )}
          </TagShape>
        </LottoContainer>
        <AddButton>
          <FiShoppingCart color="#FFCD00" />
          {process.env.REACT_APP_EVIRONMENT === "local" ? (
            <Typo.Body8 color="white">{`${number} (${lotteries.length})`}</Typo.Body8>
          ) : (
            <Typo.Body8 color="white">{`เพิ่มลงในตะกร้า`}</Typo.Body8>
          )}
        </AddButton>
        {status === "inCart" && (
          <CoverStatus>
            <Lottie
              animationData={lottieTick}
              style={{ width: "50px" }}
              loop={false}
            />
            <Typo.Body6 color="white">เพิ่มลงไปในตะกร้าแล้ว</Typo.Body6>
          </CoverStatus>
        )}
        {status === "loading" && (
          <CoverStatus>
            <Loading>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </Loading>
          </CoverStatus>
        )}
        {onHover && !["bought", "inCart", "loading"].includes(status) && (
          <CoverStatus>
            <Lottie animationData={lottieCart} style={{ width: "250px" }} />
          </CoverStatus>
        )}
      </LottoContainer>
    </LottoWrapper>
  );
};

const P = styled.p`
  margin: 0 0 0 5px;
`;
const LottoWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  color: black;
  background: white;
  border-radius: 15px;
`;

const LottoContainer = styled.div`
  position: relative;
`;

const CoverStatus = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${style.css.smRad}

  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  background-size: cover;
  padding-top: unset;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  left: -5px;
  top: -5px;
  color: white;
  font-weight: bold;
  font-size: 25px;
`;

const TagShape = styled.div`
  border-radius: 0 0 25px 0;
  border: 1px solid ${themes.colors.primary};
  justify-content: center;
  position: absolute;
  background: ${(props) =>
    props.isMagicNumber ? themes.colors.primary : themes.colors.primary};
  width: 20%;
  height: ${(props) => (props.isMagicNumber ? "55%" : "50%")};
  max-height: 80px;
  display: flex;
  left: -5px;
  top: -5px;
  color: ${themes.colors.navy};
  @media only screen and (max-width: 643px) {
    max-height: 50px;
  }
`;
const TagText = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => (props.isMagicNumber ? "1em 0" : "0")};
  color: ${(props) => props.isMagicNumber && themes.colors.black};
  align-items: center;
  flex-direction: column;
  font-size: ${({ fs }) => fs * 0.06}px;
  line-height: 1.2;
  font-weight: 600;
  @media only screen and (max-width: 643px) {
    font-size: 2vw;
  }
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;

  padding: 8px 8px;
  align-items: center;
  background: ${themes.colors.navy};
`;
