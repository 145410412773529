import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Context from "../Context";
import style from "../constants/style";
import themes from "../themes";
import { Flex, Typo, Div } from "./index";
import { getThaiDateAndTimeFormat } from "../constants";
export const CouponCard = ({
  data,
  disableSelect,
  isSelected,
  handleSetSelectCoupon,
  orderId,
}) => {
  return (
    <Wrapper
      isSelected={isSelected}
      onClick={() => {
        !disableSelect && handleSetSelectCoupon(orderId);
      }}
    >
      <Flex align="center">
        <Flex direction="column" width="100px">
          <Typo.Body3 color={isSelected ? "green" : "textBlack"}>
            {data.type === "discount" ? "ส่วนลดซื้อลอตเตอรี่" : `Voucher`}
          </Typo.Body3>

          <Flex margin="10px 0 0 0" align="baseline" gap="10px">
            <Typo.H3 color={isSelected ? "green" : "textBlack"}>
              {data.type === "discount"
                ? ` ${new Intl.NumberFormat().format(data.discount)}฿`
                : `${new Intl.NumberFormat().format(data.quantity)} ใบ`}
            </Typo.H3>
            {/* <Typo.Body5 color={isSelected ? "green" : "gray4"}>
              {data.minimum ? `ซื้อขั้นต่ำ ${data.minimum} ใบ` : "ไม่มีขั้นต่ำ"}
            </Typo.Body5> */}
          </Flex>
        </Flex>
        <Flex justify="center" width="100%" height="100%">
          <Div padding="0 0 0 15px">
            {isSelected ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#1EA94C" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.3868 5.93648C14.0448 6.02136 14.1073 5.96417 11.386 8.68223L8.82277 11.2425L7.69465 10.1199C6.41376 8.8453 6.43024 8.85779 5.99383 8.83056C5.08586 8.77393 4.45998 9.70917 4.86297 10.5204C4.99993 10.7961 8.04168 13.8497 8.30285 13.9736C8.64525 14.1362 9.00239 14.133 9.36565 13.9642C9.62805 13.8422 15.5725 7.90618 15.7105 7.62831C16.163 6.71712 15.3627 5.69428 14.3868 5.93648Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.75"
                  y="0.75"
                  width="18.5"
                  height="18.5"
                  rx="9.25"
                  fill="none"
                  stroke="#DDDDDD"
                  strokeWidth="1.5"
                />
              </svg>
            )}
          </Div>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 0.5rem;
  width: 100%;
  max-width: 250px;
  border: 1px solid
    ${(props) => (props.isSelected ? themes.colors.green : themes.colors.gray2)};

  background: ${(props) => (props.isSelected ? "#F1FFF5" : (0, 0, 0, 0))};
  padding: 10px 25px;
  cursor: ${(props) => (props.disableSelect ? "" : "pointer")};
  transition-duration: 1s;
`;
