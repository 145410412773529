import React, { useEffect, useState } from "react";
import Context from "../../Context";
import { useParams } from "react-router-dom";
import {
  Container,
  Typo,
  ContentContainer,
  Flex,
  DonateOrderContainer,
  Breadcrumb,
} from "../../components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import themes from "../../themes";
import { monthTh } from "../../util";

export const DonateHistory = () => {
  const { callApi, configs, profileState, setProfileState, go } = Context();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      const userResult = await callApi(
        "users-getUserInfo",
        { configs },
        { notShowLoading: true }
      );

      setProfileState(userResult.data);
      const result = await callApi("donates-getTransactionByUser", {
        isPointRedeem: true,
      });

      setOrders(result.data);
    })();
  }, []);

  return (
    <Container>
      <Breadcrumb
        data={[
          { path: "/", name: "หน้าแรก" },
          { path: "/donate", name: "บริจาค" },
          { name: "ประวัติการบริจาค" },
        ]}
      />
      <Typo.H6 color="navy" textAlign="center">
        ประวัติการบริจาค
      </Typo.H6>

      <ContentContainer
        bgcolor="gradient2"
        minHeight="10px"
        padding="16px 24px"
      >
        <Flex justify="space-between" align="center">
          <Flex direction="column" align="center" gap="8px">
            <Typo.Body2 color="white">คะแนนสะสมของคุณ</Typo.Body2>
            <Typo.Body5>
              ใช้ได้ถึงสิ้นเดือน {monthTh[profileState.pointExpireMonth || 12]}
            </Typo.Body5>
          </Flex>
          <Flex align="center" gap="10px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1593 0.443922C2.8527 1.80168 -3.27818 14.4552 2.65518 24.1873C6.88822 31.1305 15.9265 33.6553 23.16 29.9151C32.5898 25.0394 34.5623 12.4299 27.0676 4.9352C23.71 1.5776 18.8373 -0.117838 14.1593 0.443922ZM17.1762 7.87264C23.7081 8.62368 24.4731 17.6089 18.1593 19.4201C17.6254 19.5732 17.5036 19.5828 15.7686 19.6087L13.9405 19.636V21.9116V24.1873H11.7217H9.50302V15.9998V7.81224L13.0812 7.81272C15.0491 7.81296 16.8919 7.83992 17.1762 7.87264ZM13.9405 13.7112V15.7498H15.1258C17.1836 15.7498 17.9047 15.2222 17.9023 13.7185C17.9 12.2583 17.2163 11.7531 15.1749 11.703L13.9405 11.6726V13.7112Z"
                fill="#FFCD00"
              />
            </svg>
            <Typo.H2 color="yellow" margin="5px 0 0 0">
              {new Intl.NumberFormat().format(profileState.buyPoint || 0)}
            </Typo.H2>
          </Flex>
        </Flex>
      </ContentContainer>
      <div className="flex justify-between my-8">
        <Typo.Link1
          className="flex gap-2"
          color="navy"
          cursor="pointer"
          onClick={() => go("/donate")}
        >
          <IoIosArrowBack /> บริจาค
        </Typo.Link1>
        <Typo.Body2 color="textBlack">
          การบริจาค {orders?.length || 0} ครั้ง
        </Typo.Body2>
      </div>

      {orders
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((order, index) => {
          return <DonateOrderContainer order={order} key={index} />;
        })}
    </Container>
  );
};
