import React, { useState, useRef } from "react";
import styled from "styled-components";
import { SquareInput } from "./SquareInputs";

export const InputBox = ({ digits = ["", ""], setDigits, action, gap }) => {
  const [, setTs] = useState(Date.now());
  return (
    <Main>
      <Warper>
        <Numbers gap={gap}>
          {digits.map((x, i) => (
            <SquareInput
              key={i}
              index={i}
              digits={digits}
              setDigits={setDigits}
              setTs={setTs}
              action={action}
            />
          ))}
        </Numbers>
      </Warper>
    </Main>
  );
};

const Warper = styled.div`
  display: inline-block;
  margin: auto;
  border-radius: 8px;
  width: 100%;
`;
const Numbers = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  gap: ${(props) => props.gap};
`;
const Main = styled.div`
  color: rgb(50, 101, 150);
  text-align: center;
`;
