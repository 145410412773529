import React from "react";
import styled from "styled-components";

export const FlexHolder = ({ children }) => {
  return <FlexHolderSc>{children}</FlexHolderSc>;
};

const FlexHolderSc = styled.div`
  text-align: center;
  display: inline-block;
  width: 100%;
`;
