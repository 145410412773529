import React from "react";
import style from "../constants/style";
import styled from "styled-components";
const CIRCLE_R = 40;
const SPACE = 0.75;

export const ProgressBarNumber = ({ status, texts }) => {
  if (!status) return;

  const index = texts.map((x) => x).indexOf(status);
  return (
    <Main>
      {texts.map((x, i) => (
        <Blocks key={i} text={x} isCompleted={i <= index} />
      ))}
    </Main>
  );
};

const Blocks = ({ text, isCompleted }) => {
  return (
    <>
      <Block>
        <Circle isCompleted={isCompleted}>
          <Text style={{ color: "black" }}>{text}</Text>
        </Circle>
      </Block>
      <Dash />
    </>
  );
};
const Text = styled.div`
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
`;
const Dash = styled.div`
  display: inline-block;
  vertical-align: top;
  height: ${CIRCLE_R * 0.5}px;
  width: ${CIRCLE_R * SPACE}px;
  border-bottom: 2px solid ${style.colors.brightGoldText};
  :last-child {
    width: 0;
  }
`;
// height: ${CIRCLE_R * 1.5}px;
const Main = styled.div`
  text-align: center;
  background-image: url(${(props) => props.img});
  margin: 20px 0 25px 0;
`;
const Block = styled.div`
  position: relative;
  display: inline-block;
`;
const Circle = styled.div`
  position: relative;
  width: ${CIRCLE_R}px;
  height: ${CIRCLE_R}px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  background-color: white;
  background-color: ${({ isCompleted }) =>
    isCompleted && style.colors.goldText};
  border: 2px solid ${style.colors.brightGoldText};
  margin-bottom: ${CIRCLE_R * 0.05}px;
`;
