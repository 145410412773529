import React from "react";
import styled from "styled-components";
import Context from "../Context";
import themes from "../themes";
import { Container, Flex, Button } from "../components";
import { devices, fontWeight, isHideNavbar } from "../constants";
import { useLocation } from "react-router-dom";

export const Navbar = () => {
  const { profileState, go, setModalSpecial } = Context();
  let location = useLocation();

  const currentPath = location.pathname;
  const parentPath = currentPath.split("/")[1];

  const paths = [
    { parentPath: "", name: "หน้าแรก", path: "" },
    { parentPath: "check-reward", name: "ตรวจหวย", path: "check-reward" },
    { parentPath: "search", name: "ซื้อลอตเตอรี่", path: "search" },
    { parentPath: "orders", name: "กระเป๋าของฉัน", path: "orders" },
    { parentPath: "histories", name: "ประวัติการซื้อ", path: "histories" },
  ];

  const navigateWithValidate = (path) => {
    // if (!profileState.userNo) {
    //   go("/login");
    //   return;
    // }

    if (
      !profileState.hide?.includes("specialModal") &&
      profileState.userNo &&
      (!profileState.bank?.accNo ||
        !profileState.bank?.accName ||
        !profileState.bank?.name)
    ) {
      setModalSpecial(true);
    }
    go(path);
  };
  return (
    <HeaderWrapper className={`${isHideNavbar(parentPath) && "hide"}`}>
      <Flex height="100%" align="center">
        <Container>
          <Flex width="100%" justify="space-between" align="center">
            <LogoWrapper>
              <img
                src="/images/logo.png"
                alt="logo"
                style={{ width: "100%" }}
              />
            </LogoWrapper>

            <NavWrapper align="center" gap="22px">
              {paths.map((data) => {
                return (
                  <NavLink
                    onClick={() => navigateWithValidate(`/${data.path}`)}
                    className={parentPath === data.parentPath && "selected"}
                    key={data.name}
                  >
                    {data.name}
                  </NavLink>
                );
              })}

              {profileState.userNo ? (
                <ImageWrapper
                  className={parentPath === "profile" && "selected"}
                  onClick={() => go("/profile")}
                  src={
                    profileState.profileImage || "/images/profile-default.svg"
                  }
                  alt="profile"
                />
              ) : (
                <Button
                  height="36px"
                  background={parentPath === "login" ? "yellow" : "white"}
                  color={"navy"}
                  onClick={() => go("/login")}
                >
                  เข้่าสู่ระบบ
                </Button>
              )}
            </NavWrapper>
          </Flex>
        </Container>
      </Flex>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  height: 96px;
  width: 100%;
  background: ${themes.colors.gradient2};
  z-index: 2;

  box-shadow: 0px 2px 12px 0px rgba(0, 10, 27, 0.07);

  &.hide {
    position: relative;
  }
  //ipad size
  @media only screen and (${devices.tablet}) {
    height: 68px;
    &.hide {
      display: none;
    }
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    height: 60;
    &.hide {
      display: none;
    }
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  width: 178px;
  height: 60px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    width: 143px;
    height: 48px;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  }
`;

const ImageWrapper = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition-duration: 0.2s;

  :hover {
    border: 2px solid ${themes.colors.yellow};
  }

  &.selected {
    border: 2px solid ${themes.colors.yellow};
  }
  @media only screen and (${devices.tablet}) {
    width: 40px;
    height: 40px;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 32px;
    height: 32px;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

const NavLink = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: ${fontWeight.semiBold};
  color: ${themes.colors.white80};
  line-height: 20px;
  cursor: pointer;
  transition-duration: 0.2s;
  :hover {
    color: ${themes.colors.white};
  }
  &.selected {
    font-weight: ${fontWeight.bold};
    color: ${themes.colors.yellow};
  }

  @media only screen and (${devices.tablet}) {
    display: none;
  }
`;
